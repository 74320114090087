import * as React from "react";
import { useState } from "react";
import { gameWeeksWithTeams } from "../../data/gameWeeksWithTeams";
import { teamsList } from "../../data/teamsList";
import { Grid, Typography, Stack, Card, Box } from "@mui/material";
import TeamSelector from "../DropdownSelector/DropdownSelector";

export default function Calendar() {
  const upcomingGameWeeks = Object.values(gameWeeksWithTeams).filter((gw) => {
    const today = new Date();
    const gwStartDate = new Date(gw.startDate);
    return today.toISOString() < gwStartDate.toISOString();
  });

  const gameWeeks = upcomingGameWeeks.map((gw) => {
    return gw.gwNum;
  });

  const currentGameweek = upcomingGameWeeks[0];
  if (currentGameweek.isWeekendGW) {
    gameWeeks.unshift(currentGameweek.gwNum - 1);
  }

  const [selectedTeams, setSelectedTeams] = useState([]);
  const setSelectedValue = (fieldName, newValue) => {
    setSelectedTeams(newValue);
  };

  const dateFormatter = (date) => {
    const formattedDate = new Date(date);
    const weekday = formattedDate.toDateString().split(" ")[0];
    const time = formattedDate.getHours() + "h";
    return weekday + " " + formattedDate.toLocaleDateString() + " " + time;
  };

  return (
    <Stack paddingTop={2} paddingBottom={2}>
      <TeamSelector
        name="teams"
        fieldNameLabel="Choose your teams"
        defaultValue={selectedTeams}
        setChosenOptions={setSelectedValue}
        multiple={true}
        optionsLabels={teamsList}
      />
      <Box padding={3} />
      <Grid container spacing={2}>
        {!!selectedTeams.length && (
          <>
            <Grid item xs={6}>
              <Typography paddingLeft={0.7} variant="h5">MIDWEEK</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paddingLeft={0.7} variant="h5">WEEKEND</Typography>
            </Grid>
          </>
        )}

        {!!selectedTeams.length &&
          gameWeeks.map((gameWeek) => {
            return (
              <Grid item xs={6} key={gameWeek}>
                <Card sx={{ padding: "2px", backgroundColor: "lightgreen" }}>
                  <Card sx={{ backgroundColor: "black" }}>
                    <Stack paddingLeft={0.7} paddingTop={0.3} paddingBottom={0.3}>
                      <Typography color="white">GW {gameWeek}</Typography>
                      <Typography color="white">
                        {dateFormatter(gameWeeksWithTeams[gameWeek].startDate)}
                      </Typography>
                    </Stack>
                  </Card>

                  {selectedTeams.map((team, index) => {
                    if (!gameWeeksWithTeams[gameWeek]["activeTeams"]) {
                      return [];
                    }
                    if (gameWeeksWithTeams[gameWeek]["activeTeams"][team]) {
                      return (
                        <Typography paddingLeft={0.7} key={index}>
                          {team}
                        </Typography>
                      );
                    }
                    return [];
                  })}
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
}
