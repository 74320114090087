import React from "react";

import { Box, Autocomplete, TextField } from "@mui/material/";

export default function DropdownSelector({
  setChosenOptions,
  defaultValue,
  nextButtonTouched,
  required,
  name,
  fieldNameLabel,
  optionsLabels,
  multiple,
  disabled,
}){
  const optionsList = optionsLabels.map((label) => {
    return { label: label };
  });
  const handleOptionSelect = (event, value) => {
    if (value === null) {
      setChosenOptions(name, multiple ? [] : "");
    } else {
      setChosenOptions(
        name,
        multiple ? value.map((option) => option.label) : value.label
      );
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      onChange={handleOptionSelect}
      multiple={multiple}
      id={name}
      sx={{
        width: "100%",
      }}
      options={optionsList}
      autoHighlight
      value={
        multiple
          ? defaultValue
            ? defaultValue.map((value) => {
                return { label: value };
              })
            : []
          : defaultValue
          ? { label: defaultValue }
          : null
      }
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          name={name}
        >
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          required={required}
          error={required && nextButtonTouched && !defaultValue}
          helperText={
            required &&
            nextButtonTouched &&
            !defaultValue &&
            `${fieldNameLabel} cannot be empty`
          }
          sx={{
            "& .MuiOutlinedInput-input": {
              marginLeft: "",
            },
            "& .MuiInputLabel-shrink": {
              color: "black",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              backgroundColor: "white",
              height: "",
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
            width: "100%",
            borderRadius: "25px",
            marginTop: "",
          }}
          {...params}
          label={fieldNameLabel}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};
