export const gameWeeksWithTeams = {
  "355": {
    "gwNum": 355,
    "startDate": "Fri Mar 17 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Mar 21 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Valladolid": true,
      "Athletic Club": true,
      "Almería": true,
      "Cádiz": true,
      "Rayo Vallecano": true,
      "Girona": true,
      "Espanyol": true,
      "Celta Vigo": true,
      "Atlético Madrid": true,
      "Valencia": true,
      "Real Betis": true,
      "Mallorca": true,
      "Real Sociedad": true,
      "Elche": true,
      "Osasuna": true,
      "Villarreal": true,
      "Getafe": true,
      "Sevilla": true,
      "Barcelona": true,
      "Real Madrid": true,
      "Montréal": true,
      "Philadelphia": true,
      "Atlanta United": true,
      "Portland": true,
      "New England": true,
      "Nashville": true,
      "Orlando City": true,
      "Charlotte": true,
      "Toronto": true,
      "Inter Miami": true,
      "NY Red Bulls": true,
      "Columbus": true,
      "New York City": true,
      "D.C. United": true,
      "FC Dallas": true,
      "Sporting KC": true,
      "Houston Dynamo": true,
      "Austin": true,
      "St. Louis": true,
      "San Jose": true,
      "Chicago": true,
      "Cincinnati": true,
      "Colorado": true,
      "Minnesota": true,
      "LA Galaxy": true,
      "Vancouver": true,
      "Nottm Forest": true,
      "Newcastle": true,
      "Brentford": true,
      "Leicester City": true,
      "Southampton": true,
      "Tottenham": true,
      "Aston Villa": true,
      "Bournemouth": true,
      "Wolves": true,
      "Leeds United": true,
      "Chelsea": true,
      "Everton": true,
      "Arsenal": true,
      "Crystal Palace": true,
      "Santa Clara": true,
      "Rio Ave": true,
      "Portimonense": true,
      "Vizela": true,
      "Arouca": true,
      "Pacos Ferreira": true,
      "Benfica": true,
      "Vitória SC": true,
      "Estoril Praia": true,
      "Chaves": true,
      "Casa Pia": true,
      "Marítimo": true,
      "Braga": true,
      "Porto": true,
      "Boavista": true,
      "Famalicão": true
    }
  },
  "356": {
    "gwNum": 356,
    "startDate": "Tue Mar 21 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Mar 24 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false
  },
  "357": {
    "gwNum": 357,
    "startDate": "Fri Mar 24 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Mar 28 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Portland": true,
      "LA Galaxy": true,
      "Charlotte": true,
      "NY Red Bulls": true,
      "Philadelphia": true,
      "Orlando City": true,
      "D.C. United": true,
      "New England": true,
      "Columbus": true,
      "Atlanta United": true,
      "Inter Miami": true,
      "Chicago": true,
      "Nashville": true,
      "Cincinnati": true,
      "Sporting KC": true,
      "Seattle Sounders": true,
      "Austin": true,
      "Colorado": true,
      "Houston Dynamo": true,
      "New York City": true,
      "Minnesota": true,
      "Vancouver": true,
      "Real Salt Lake": true,
      "St. Louis": true,
      "LAFC": true,
      "FC Dallas": true,
      "San Jose": true,
      "Toronto": true
    }
  },
  "358": {
    "gwNum": 358,
    "startDate": "Tue Mar 28 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Mar 31 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "359": {
    "gwNum": 359,
    "startDate": "Fri Mar 31 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Apr 04 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Mallorca": true,
      "Osasuna": true,
      "Girona": true,
      "Espanyol": true,
      "Athletic Club": true,
      "Getafe": true,
      "Cádiz": true,
      "Sevilla": true,
      "Elche": true,
      "Barcelona": true,
      "Celta Vigo": true,
      "Almería": true,
      "Real Madrid": true,
      "Valladolid": true,
      "Villarreal": true,
      "Real Sociedad": true,
      "Atlético Madrid": true,
      "Real Betis": true,
      "Valencia": true,
      "Rayo Vallecano": true,
      "Toronto": true,
      "Charlotte": true,
      "LA Galaxy": true,
      "Seattle Sounders": true,
      "New England": true,
      "New York City": true,
      "Philadelphia": true,
      "Sporting KC": true,
      "Orlando City": true,
      "Nashville": true,
      "Columbus": true,
      "Real Salt Lake": true,
      "Cincinnati": true,
      "Inter Miami": true,
      "Atlanta United": true,
      "NY Red Bulls": true,
      "FC Dallas": true,
      "Portland": true,
      "St. Louis": true,
      "Minnesota": true,
      "Chicago": true,
      "D.C. United": true,
      "Colorado": true,
      "LAFC": true,
      "Vancouver": true,
      "Montréal": true,
      "San Jose": true,
      "Houston Dynamo": true,
      "Man City": true,
      "Liverpool": true,
      "Bournemouth": true,
      "Fulham": true,
      "Nottm Forest": true,
      "Wolves": true,
      "Crystal Palace": true,
      "Leicester City": true,
      "Arsenal": true,
      "Leeds United": true,
      "Brighton": true,
      "Brentford": true,
      "Chelsea": true,
      "Aston Villa": true,
      "West Ham": true,
      "Southampton": true,
      "Newcastle": true,
      "Man United": true,
      "Everton": true,
      "Tottenham": true,
      "Porto": true,
      "Portimonense": true,
      "Sporting": true,
      "Santa Clara": true,
      "Famalicão": true,
      "Arouca": true,
      "Chaves": true,
      "Braga": true,
      "Marítimo": true,
      "Boavista": true,
      "Vizela": true,
      "Casa Pia": true,
      "Rio Ave": true,
      "Benfica": true,
      "Estoril Praia": true,
      "Gil Vicente": true,
      "Vitória SC": true,
      "Pacos Ferreira": true
    }
  },
  "360": {
    "gwNum": 360,
    "startDate": "Tue Apr 04 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Apr 07 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Leeds United": true,
      "Nottm Forest": true,
      "Bournemouth": true,
      "Brighton": true,
      "Leicester City": true,
      "Aston Villa": true,
      "Chelsea": true,
      "Liverpool": true,
      "West Ham": true,
      "Newcastle": true,
      "Man United": true,
      "Brentford": true,
      "Gil Vicente": true,
      "Sporting": true
    }
  },
  "361": {
    "gwNum": 361,
    "startDate": "Fri Apr 07 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Apr 11 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Rosenborg": true,
      "Viking": true,
      "Tromso": true,
      "Molde": true,
      "Stabæk": true,
      "Odd": true,
      "Aalesund": true,
      "Vålerenga": true,
      "HamKam": true,
      "Sandefjord": true,
      "Sarpsborg": true,
      "Bodø/Glimt": true,
      "Lillestrøm": true,
      "Strømsgodset": true,
      "Brann": true,
      "Haugesund": true,
      "Sevilla": true,
      "Celta Vigo": true,
      "Osasuna": true,
      "Elche": true,
      "Espanyol": true,
      "Athletic Club": true,
      "Real Sociedad": true,
      "Getafe": true,
      "Real Madrid": true,
      "Villarreal": true,
      "Valladolid": true,
      "Mallorca": true,
      "Real Betis": true,
      "Cádiz": true,
      "Almería": true,
      "Valencia": true,
      "Rayo Vallecano": true,
      "Atlético Madrid": true,
      "Barcelona": true,
      "Girona": true,
      "D.C. United": true,
      "Columbus": true,
      "Inter Miami": true,
      "FC Dallas": true,
      "New York City": true,
      "Atlanta United": true,
      "Cincinnati": true,
      "Philadelphia": true,
      "LAFC": true,
      "Austin": true,
      "New England": true,
      "Montréal": true,
      "NY Red Bulls": true,
      "San Jose": true,
      "Sporting KC": true,
      "Colorado": true,
      "Houston Dynamo": true,
      "LA Galaxy": true,
      "Nashville": true,
      "Toronto": true,
      "Chicago": true,
      "Minnesota": true,
      "Real Salt Lake": true,
      "Charlotte": true,
      "Vancouver": true,
      "Portland": true,
      "Seattle Sounders": true,
      "St. Louis": true,
      "Man United": true,
      "Everton": true,
      "Aston Villa": true,
      "Nottm Forest": true,
      "Brentford": true,
      "Newcastle": true,
      "Fulham": true,
      "West Ham": true,
      "Wolves": true,
      "Chelsea": true,
      "Tottenham": true,
      "Brighton": true,
      "Leicester City": true,
      "Bournemouth": true,
      "Leeds United": true,
      "Crystal Palace": true,
      "Southampton": true,
      "Man City": true,
      "Liverpool": true,
      "Arsenal": true,
      "Benfica": true,
      "Porto": true,
      "Santa Clara": true,
      "Vizela": true,
      "Boavista": true,
      "Vitória SC": true,
      "Gil Vicente": true,
      "Chaves": true,
      "Pacos Ferreira": true,
      "Famalicão": true,
      "Casa Pia": true,
      "Sporting": true,
      "Portimonense": true,
      "Rio Ave": true,
      "Arouca": true,
      "Marítimo": true,
      "Braga": true,
      "Estoril Praia": true
    }
  },
  "362": {
    "gwNum": 362,
    "startDate": "Tue Apr 11 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Apr 14 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "363": {
    "gwNum": 363,
    "startDate": "Fri Apr 14 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Apr 18 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Viking": true,
      "Lillestrøm": true,
      "Haugesund": true,
      "HamKam": true,
      "Bodø/Glimt": true,
      "Stabæk": true,
      "Strømsgodset": true,
      "Aalesund": true,
      "Molde": true,
      "Rosenborg": true,
      "Odd": true,
      "Brann": true,
      "Sandefjord": true,
      "Tromso": true,
      "Vålerenga": true,
      "Sarpsborg": true,
      "Villarreal": true,
      "Valladolid": true,
      "Valencia": true,
      "Sevilla": true,
      "Getafe": true,
      "Barcelona": true,
      "Celta Vigo": true,
      "Mallorca": true,
      "Cádiz": true,
      "Real Madrid": true,
      "Girona": true,
      "Elche": true,
      "Real Betis": true,
      "Espanyol": true,
      "Atlético Madrid": true,
      "Almería": true,
      "Rayo Vallecano": true,
      "Osasuna": true,
      "Athletic Club": true,
      "Real Sociedad": true,
      "Toronto": true,
      "Atlanta United": true,
      "Montréal": true,
      "D.C. United": true,
      "New York City": true,
      "Nashville": true,
      "Columbus": true,
      "New England": true,
      "NY Red Bulls": true,
      "Houston Dynamo": true,
      "Charlotte": true,
      "Colorado": true,
      "Chicago": true,
      "Philadelphia": true,
      "Minnesota": true,
      "Orlando City": true,
      "FC Dallas": true,
      "Real Salt Lake": true,
      "St. Louis": true,
      "Cincinnati": true,
      "Austin": true,
      "Vancouver": true,
      "Portland": true,
      "Seattle Sounders": true,
      "San Jose": true,
      "Sporting KC": true,
      "LA Galaxy": true,
      "LAFC": true,
      "Aston Villa": true,
      "Newcastle": true,
      "Southampton": true,
      "Crystal Palace": true,
      "Everton": true,
      "Fulham": true,
      "Tottenham": true,
      "Bournemouth": true,
      "Wolves": true,
      "Brentford": true,
      "Chelsea": true,
      "Brighton": true,
      "Nottm Forest": true,
      "Man United": true,
      "West Ham": true,
      "Arsenal": true,
      "Man City": true,
      "Leicester City": true,
      "Leeds United": true,
      "Liverpool": true,
      "Marítimo": true,
      "Pacos Ferreira": true,
      "Sporting": true,
      "Arouca": true,
      "Famalicão": true,
      "Vitória SC": true,
      "Braga": true,
      "Gil Vicente": true,
      "Estoril Praia": true,
      "Portimonense": true,
      "Rio Ave": true,
      "Casa Pia": true,
      "Vizela": true,
      "Boavista": true,
      "Porto": true,
      "Santa Clara": true,
      "Chaves": true,
      "Benfica": true
    }
  },
  "364": {
    "gwNum": 364,
    "startDate": "Tue Apr 18 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Apr 21 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "365": {
    "gwNum": 365,
    "startDate": "Fri Apr 21 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Apr 25 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Brann": true,
      "Vålerenga": true,
      "Tromso": true,
      "Viking": true,
      "Rosenborg": true,
      "Sandefjord": true,
      "Stabæk": true,
      "Haugesund": true,
      "Sarpsborg": true,
      "Odd": true,
      "Aalesund": true,
      "Bodø/Glimt": true,
      "HamKam": true,
      "Strømsgodset": true,
      "Lillestrøm": true,
      "Molde": true,
      "Elche": true,
      "Valencia": true,
      "Real Sociedad": true,
      "Rayo Vallecano": true,
      "Valladolid": true,
      "Girona": true,
      "Almería": true,
      "Athletic Club": true,
      "Espanyol": true,
      "Cádiz": true,
      "Barcelona": true,
      "Atlético Madrid": true,
      "Sevilla": true,
      "Villarreal": true,
      "Real Madrid": true,
      "Celta Vigo": true,
      "Osasuna": true,
      "Real Betis": true,
      "Mallorca": true,
      "Getafe": true,
      "Philadelphia": true,
      "Toronto": true,
      "Cincinnati": true,
      "Portland": true,
      "Orlando City": true,
      "D.C. United": true,
      "New York City": true,
      "FC Dallas": true,
      "Charlotte": true,
      "Columbus": true,
      "New England": true,
      "Sporting KC": true,
      "Montréal": true,
      "NY Red Bulls": true,
      "Nashville": true,
      "LAFC": true,
      "Houston Dynamo": true,
      "Inter Miami": true,
      "Real Salt Lake": true,
      "San Jose": true,
      "Colorado": true,
      "St. Louis": true,
      "Seattle Sounders": true,
      "Minnesota": true,
      "LA Galaxy": true,
      "Austin": true,
      "Atlanta United": true,
      "Chicago": true,
      "Arsenal": true,
      "Southampton": true,
      "Fulham": true,
      "Leeds United": true,
      "Crystal Palace": true,
      "Everton": true,
      "Liverpool": true,
      "Nottm Forest": true,
      "Bournemouth": true,
      "West Ham": true,
      "Brentford": true,
      "Aston Villa": true,
      "Leicester City": true,
      "Wolves": true,
      "Man United": true,
      "Chelsea": true,
      "Newcastle": true,
      "Tottenham": true,
      "Brighton": true,
      "Man City": true,
      "Benfica": true,
      "Estoril Praia": true,
      "Famalicão": true,
      "Marítimo": true,
      "Boavista": true,
      "Rio Ave": true,
      "Pacos Ferreira": true,
      "Porto": true,
      "Portimonense": true,
      "Gil Vicente": true,
      "Santa Clara": true,
      "Chaves": true,
      "Vitória SC": true,
      "Sporting": true,
      "Arouca": true,
      "Vizela": true,
      "Casa Pia": true,
      "Braga": true
    }
  },
  "366": {
    "gwNum": 366,
    "startDate": "Tue Apr 25 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Apr 28 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Athletic Club": true,
      "Sevilla": true,
      "Rayo Vallecano": true,
      "Barcelona": true,
      "Villarreal": true,
      "Espanyol": true,
      "Celta Vigo": true,
      "Elche": true,
      "Real Betis": true,
      "Real Sociedad": true,
      "Girona": true,
      "Real Madrid": true,
      "Valencia": true,
      "Valladolid": true,
      "Getafe": true,
      "Almería": true,
      "Cádiz": true,
      "Osasuna": true,
      "Atlético Madrid": true,
      "Mallorca": true,
      "Wolves": true,
      "Crystal Palace": true,
      "Southampton": true,
      "Bournemouth": true,
      "Aston Villa": true,
      "Fulham": true,
      "Leeds United": true,
      "Leicester City": true,
      "Nottm Forest": true,
      "Brighton": true,
      "Chelsea": true,
      "Brentford": true,
      "West Ham": true,
      "Liverpool": true,
      "Man City": true,
      "Arsenal": true,
      "Everton": true,
      "Newcastle": true,
      "Tottenham": true,
      "Man United": true
    }
  },
  "367": {
    "gwNum": 367,
    "startDate": "Fri Apr 28 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue May 02 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Bodø/Glimt": true,
      "Brann": true,
      "Viking": true,
      "HamKam": true,
      "Sandefjord": true,
      "Aalesund": true,
      "Haugesund": true,
      "Sarpsborg": true,
      "Strømsgodset": true,
      "Tromso": true,
      "Molde": true,
      "Stabæk": true,
      "Odd": true,
      "Rosenborg": true,
      "Vålerenga": true,
      "Lillestrøm": true,
      "Espanyol": true,
      "Getafe": true,
      "Valladolid": true,
      "Atlético Madrid": true,
      "Elche": true,
      "Rayo Vallecano": true,
      "Villarreal": true,
      "Celta Vigo": true,
      "Cádiz": true,
      "Valencia": true,
      "Sevilla": true,
      "Girona": true,
      "Real Madrid": true,
      "Almería": true,
      "Mallorca": true,
      "Athletic Club": true,
      "Osasuna": true,
      "Real Sociedad": true,
      "Barcelona": true,
      "Real Betis": true,
      "Nashville": true,
      "Atlanta United": true,
      "Columbus": true,
      "Inter Miami": true,
      "Orlando City": true,
      "LA Galaxy": true,
      "New England": true,
      "Cincinnati": true,
      "D.C. United": true,
      "Charlotte": true,
      "Toronto": true,
      "New York City": true,
      "Chicago": true,
      "NY Red Bulls": true,
      "St. Louis": true,
      "Portland": true,
      "Austin": true,
      "San Jose": true,
      "Sporting KC": true,
      "Montréal": true,
      "Real Salt Lake": true,
      "Seattle Sounders": true,
      "LAFC": true,
      "Houston Dynamo": true,
      "Vancouver": true,
      "Colorado": true,
      "Minnesota": true,
      "FC Dallas": true,
      "Crystal Palace": true,
      "West Ham": true,
      "Brighton": true,
      "Wolves": true,
      "Brentford": true,
      "Nottm Forest": true,
      "Bournemouth": true,
      "Leeds United": true,
      "Arsenal": true,
      "Chelsea": true,
      "Man United": true,
      "Aston Villa": true,
      "Fulham": true,
      "Man City": true,
      "Newcastle": true,
      "Southampton": true,
      "Liverpool": true,
      "Tottenham": true,
      "Leicester City": true,
      "Everton": true,
      "Porto": true,
      "Boavista": true,
      "Chaves": true,
      "Casa Pia": true,
      "Vizela": true,
      "Pacos Ferreira": true,
      "Rio Ave": true,
      "Arouca": true,
      "Sporting": true,
      "Famalicão": true,
      "Marítimo": true,
      "Vitória SC": true,
      "Braga": true,
      "Portimonense": true,
      "Estoril Praia": true,
      "Santa Clara": true,
      "Gil Vicente": true,
      "Benfica": true
    }
  },
  "368": {
    "gwNum": 368,
    "startDate": "Tue May 02 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri May 05 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Molde": true,
      "Aalesund": true,
      "Bodø/Glimt": true,
      "Odd": true,
      "Rosenborg": true,
      "Brann": true,
      "Barcelona": true,
      "Osasuna": true,
      "Atlético Madrid": true,
      "Cádiz": true,
      "Rayo Vallecano": true,
      "Valladolid": true,
      "Valencia": true,
      "Villarreal": true,
      "Girona": true,
      "Mallorca": true,
      "Athletic Club": true,
      "Real Betis": true,
      "Sevilla": true,
      "Espanyol": true,
      "Getafe": true,
      "Celta Vigo": true,
      "Almería": true,
      "Elche": true,
      "Real Sociedad": true,
      "Real Madrid": true
    }
  },
  "369": {
    "gwNum": 369,
    "startDate": "Fri May 05 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue May 09 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Rosenborg": true,
      "Vålerenga": true,
      "Tromso": true,
      "Odd": true,
      "Viking": true,
      "Strømsgodset": true,
      "HamKam": true,
      "Molde": true,
      "Aalesund": true,
      "Haugesund": true,
      "Stabæk": true,
      "Sarpsborg": true,
      "Lillestrøm": true,
      "Bodø/Glimt": true,
      "Brann": true,
      "Sandefjord": true,
      "Toronto": true,
      "New England": true,
      "Cincinnati": true,
      "D.C. United": true,
      "San Jose": true,
      "LAFC": true,
      "Inter Miami": true,
      "Atlanta United": true,
      "NY Red Bulls": true,
      "Philadelphia": true,
      "Montréal": true,
      "Orlando City": true,
      "Charlotte": true,
      "New York City": true,
      "Houston Dynamo": true,
      "Real Salt Lake": true,
      "FC Dallas": true,
      "St. Louis": true,
      "Nashville": true,
      "Chicago": true,
      "Portland": true,
      "Austin": true,
      "LA Galaxy": true,
      "Colorado": true,
      "Vancouver": true,
      "Minnesota": true,
      "Seattle Sounders": true,
      "Sporting KC": true,
      "West Ham": true,
      "Man United": true,
      "Bournemouth": true,
      "Chelsea": true,
      "Man City": true,
      "Leeds United": true,
      "Nottm Forest": true,
      "Southampton": true,
      "Newcastle": true,
      "Arsenal": true,
      "Fulham": true,
      "Leicester City": true,
      "Liverpool": true,
      "Brentford": true,
      "Brighton": true,
      "Everton": true,
      "Wolves": true,
      "Aston Villa": true,
      "Tottenham": true,
      "Crystal Palace": true,
      "Benfica": true,
      "Braga": true,
      "Boavista": true,
      "Estoril Praia": true,
      "Pacos Ferreira": true,
      "Sporting": true,
      "Marítimo": true,
      "Rio Ave": true,
      "Casa Pia": true,
      "Portimonense": true,
      "Arouca": true,
      "Porto": true,
      "Santa Clara": true,
      "Gil Vicente": true,
      "Famalicão": true,
      "Chaves": true,
      "Vitória SC": true,
      "Vizela": true
    }
  },
  "370": {
    "gwNum": 370,
    "startDate": "Tue May 09 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri May 12 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Coritiba": true,
      "Vasco da Gama": true,
      "Botafogo": true,
      "Corinthians": true,
      "Fortaleza": true,
      "São Paulo": true,
      "FC Tokyo": true,
      "Kawasaki": true
    }
  },
  "371": {
    "gwNum": 371,
    "startDate": "Fri May 12 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue May 16 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Bahia": true,
      "Flamengo": true,
      "Fluminense": true,
      "Cuiabá": true,
      "Palmeiras": true,
      "Bragantino": true,
      "Atlético Mineiro": true,
      "Internacional": true,
      "Grêmio": true,
      "Fortaleza": true,
      "Vasco da Gama": true,
      "Santos": true,
      "Corinthians": true,
      "São Paulo": true,
      "Athletico-PR": true,
      "Coritiba": true,
      "Goiás": true,
      "Botafogo": true,
      "América-MG": true,
      "Cruzeiro": true,
      "Odd": true,
      "Lillestrøm": true,
      "Stabæk": true,
      "Aalesund": true,
      "Sarpsborg": true,
      "Brann": true,
      "HamKam": true,
      "Tromso": true,
      "Bodø/Glimt": true,
      "Rosenborg": true,
      "Molde": true,
      "Strømsgodset": true,
      "Sandefjord": true,
      "Viking": true,
      "Haugesund": true,
      "Vålerenga": true,
      "Twente": true,
      "NEC": true,
      "FC Volendam": true,
      "Sparta Rotterdam": true,
      "Heerenveen": true,
      "Excelsior": true,
      "Utrecht": true,
      "RKC Waalwijk": true,
      "Vitesse": true,
      "Cambuur": true,
      "Groningen": true,
      "Ajax": true,
      "Feyenoord": true,
      "Go Ahead Eagles": true,
      "PSV": true,
      "Fortuna Sittard": true,
      "AZ Alkmaar": true,
      "FC Emmen": true,
      "Vissel Kobe": true,
      "Sanfrecce": true,
      "Shonan Bellmare": true,
      "Consadole Sapporo": true,
      "Kashiwa Reysol": true,
      "Yokohama FC": true,
      "Avispa Fukuoka": true,
      "Sagan Tosu": true,
      "Kashima Antlers": true,
      "Nagoya": true,
      "Kyoto Sanga": true,
      "Cerezo Osaka": true,
      "Niigata": true,
      "Yokohama FM": true,
      "Urawa Reds": true,
      "Gamba Osaka": true,
      "Pohang Steelers": true,
      "Daejeon Hana": true,
      "Gwangju": true,
      "Daegu": true,
      "Gangwon": true,
      "Suwon Bluewings": true,
      "Ulsan": true,
      "FC Seoul": true,
      "Incheon": true,
      "Jeonbuk": true,
      "Suwon FC": true,
      "Jeju": true,
      "Osasuna": true,
      "Almería": true,
      "Elche": true,
      "Atlético Madrid": true,
      "Villarreal": true,
      "Athletic Club": true,
      "Real Betis": true,
      "Rayo Vallecano": true,
      "Celta Vigo": true,
      "Valencia": true,
      "Valladolid": true,
      "Sevilla": true,
      "Espanyol": true,
      "Barcelona": true,
      "Mallorca": true,
      "Cádiz": true,
      "Real Madrid": true,
      "Getafe": true,
      "Real Sociedad": true,
      "Girona": true,
      "Lens": true,
      "Reims": true,
      "Strasbourg": true,
      "Nice": true,
      "PSG": true,
      "Ajaccio": true,
      "Clermont Foot": true,
      "Lyon": true,
      "Toulouse": true,
      "Nantes": true,
      "Rennes": true,
      "Troyes": true,
      "Montpellier": true,
      "Lorient": true,
      "Brest": true,
      "Auxerre": true,
      "Monaco": true,
      "LOSC": true,
      "Marseille": true,
      "Angers": true,
      "Chicago": true,
      "St. Louis": true,
      "Montréal": true,
      "Toronto": true,
      "Atlanta United": true,
      "Charlotte": true,
      "D.C. United": true,
      "Nashville": true,
      "Columbus": true,
      "Orlando City": true,
      "NY Red Bulls": true,
      "New York City": true,
      "Inter Miami": true,
      "New England": true,
      "Sporting KC": true,
      "Minnesota": true,
      "Austin": true,
      "FC Dallas": true,
      "Houston Dynamo": true,
      "Seattle Sounders": true,
      "Real Salt Lake": true,
      "LAFC": true,
      "Colorado": true,
      "Philadelphia": true,
      "Portland": true,
      "Vancouver": true,
      "LA Galaxy": true,
      "San Jose": true,
      "Everton": true,
      "Man City": true,
      "Southampton": true,
      "Fulham": true,
      "Brentford": true,
      "West Ham": true,
      "Arsenal": true,
      "Brighton": true,
      "Chelsea": true,
      "Nottm Forest": true,
      "Leicester City": true,
      "Liverpool": true,
      "Aston Villa": true,
      "Tottenham": true,
      "Man United": true,
      "Wolves": true,
      "Crystal Palace": true,
      "Bournemouth": true,
      "Leeds United": true,
      "Newcastle": true,
      "Chaves": true,
      "Pacos Ferreira": true,
      "Rio Ave": true,
      "Vitória SC": true,
      "Porto": true,
      "Casa Pia": true,
      "Portimonense": true,
      "Benfica": true,
      "Gil Vicente": true,
      "Boavista": true,
      "Vizela": true,
      "Famalicão": true,
      "Sporting": true,
      "Marítimo": true,
      "Braga": true,
      "Santa Clara": true,
      "Estoril Praia": true,
      "Arouca": true,
      "Huracán": true,
      "Godoy Cruz": true,
      "Atlético Tucumán": true,
      "Argentinos Juniors": true,
      "Independiente": true,
      "Tigre": true,
      "Banfield": true,
      "San Lorenzo": true,
      "Newell's": true,
      "Arsenal Sarandi": true,
      "Instituto ACC": true,
      "Colón": true,
      "Defensa y Justicia": true,
      "Estudiantes": true,
      "Platense": true,
      "Racing Club": true,
      "Boca Juniors": true,
      "Belgrano": true,
      "Talleres Córdoba": true,
      "River Plate": true,
      "Barracas Central": true,
      "Central Córdoba": true,
      "Gimnasia": true,
      "Lanús": true,
      "Vélez Sársfield": true,
      "Rosario Central": true,
      "Unión": true,
      "Sarmiento": true,
      "Lazio": true,
      "Lecce": true,
      "Salernitana": true,
      "Atalanta": true,
      "Spezia": true,
      "Milan": true,
      "Inter": true,
      "Sassuolo": true,
      "Verona": true,
      "Torino": true,
      "Monza": true,
      "Napoli": true,
      "Fiorentina": true,
      "Udinese": true,
      "Bologna": true,
      "Roma": true,
      "Juventus": true,
      "Cremonese": true,
      "Sampdoria": true,
      "Empoli": true
    }
  },
  "372": {
    "gwNum": 372,
    "startDate": "Tue May 16 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri May 19 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Viking": true,
      "Odd": true,
      "Lillestrøm": true,
      "Sarpsborg": true,
      "Strømsgodset": true,
      "Sandefjord": true,
      "Aalesund": true,
      "Molde": true,
      "Vålerenga": true,
      "HamKam": true,
      "Rosenborg": true,
      "Haugesund": true,
      "Brann": true,
      "Stabæk": true,
      "Tromso": true,
      "Bodø/Glimt": true,
      "Consadole Sapporo": true,
      "Kyoto Sanga": true,
      "Atlanta United": true,
      "Colorado": true,
      "Philadelphia": true,
      "D.C. United": true,
      "Toronto": true,
      "NY Red Bulls": true,
      "Orlando City": true,
      "New York City": true,
      "Columbus": true,
      "LA Galaxy": true,
      "Cincinnati": true,
      "Montréal": true,
      "Charlotte": true,
      "Chicago": true,
      "Minnesota": true,
      "Houston Dynamo": true,
      "FC Dallas": true,
      "Vancouver": true,
      "Nashville": true,
      "Inter Miami": true,
      "Real Salt Lake": true,
      "Portland": true,
      "LAFC": true,
      "Sporting KC": true,
      "Seattle Sounders": true,
      "Austin": true,
      "Arsenal Sarandi": true,
      "Independiente": true
    }
  },
  "373": {
    "gwNum": 373,
    "startDate": "Fri May 19 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue May 23 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Bahia": true,
      "Goiás": true,
      "Bragantino": true,
      "Athletico-PR": true,
      "São Paulo": true,
      "Vasco da Gama": true,
      "Coritiba": true,
      "Atlético Mineiro": true,
      "América-MG": true,
      "Fortaleza": true,
      "Botafogo": true,
      "Fluminense": true,
      "Santos": true,
      "Palmeiras": true,
      "Flamengo": true,
      "Corinthians": true,
      "Grêmio": true,
      "Internacional": true,
      "Cruzeiro": true,
      "Cuiabá": true,
      "Ajax": true,
      "Utrecht": true,
      "Go Ahead Eagles": true,
      "FC Volendam": true,
      "Sparta Rotterdam": true,
      "Cambuur": true,
      "RKC Waalwijk": true,
      "Twente": true,
      "NEC": true,
      "AZ Alkmaar": true,
      "Vitesse": true,
      "Groningen": true,
      "PSV": true,
      "Heerenveen": true,
      "FC Emmen": true,
      "Feyenoord": true,
      "Excelsior": true,
      "Fortuna Sittard": true,
      "Avispa Fukuoka": true,
      "Urawa Reds": true,
      "Shonan Bellmare": true,
      "Cerezo Osaka": true,
      "Kashima Antlers": true,
      "FC Tokyo": true,
      "Nagoya": true,
      "Sanfrecce": true,
      "Kashiwa Reysol": true,
      "Vissel Kobe": true,
      "Yokohama FC": true,
      "Kawasaki": true,
      "Sagan Tosu": true,
      "Niigata": true,
      "Gamba Osaka": true,
      "Yokohama FM": true,
      "Incheon": true,
      "Gwangju": true,
      "FC Seoul": true,
      "Jeju": true,
      "Daejeon Hana": true,
      "Daegu": true,
      "Gangwon": true,
      "Pohang Steelers": true,
      "Suwon Bluewings": true,
      "Ulsan": true,
      "Jeonbuk": true,
      "Suwon FC": true,
      "Getafe": true,
      "Elche": true,
      "Girona": true,
      "Villarreal": true,
      "Almería": true,
      "Mallorca": true,
      "Valencia": true,
      "Real Madrid": true,
      "Sevilla": true,
      "Real Betis": true,
      "Cádiz": true,
      "Valladolid": true,
      "Barcelona": true,
      "Real Sociedad": true,
      "Athletic Club": true,
      "Celta Vigo": true,
      "Rayo Vallecano": true,
      "Espanyol": true,
      "Atlético Madrid": true,
      "Osasuna": true,
      "Lyon": true,
      "Monaco": true,
      "Nantes": true,
      "Montpellier": true,
      "LOSC": true,
      "Marseille": true,
      "Ajaccio": true,
      "Rennes": true,
      "Nice": true,
      "Toulouse": true,
      "Reims": true,
      "Angers": true,
      "Troyes": true,
      "Strasbourg": true,
      "Brest": true,
      "Clermont Foot": true,
      "Lorient": true,
      "Lens": true,
      "Auxerre": true,
      "PSG": true,
      "NY Red Bulls": true,
      "Montréal": true,
      "Inter Miami": true,
      "Orlando City": true,
      "Philadelphia": true,
      "New England": true,
      "D.C. United": true,
      "LA Galaxy": true,
      "Cincinnati": true,
      "Columbus": true,
      "Charlotte": true,
      "Nashville": true,
      "Austin": true,
      "Toronto": true,
      "Chicago": true,
      "Atlanta United": true,
      "FC Dallas": true,
      "Houston Dynamo": true,
      "Colorado": true,
      "Real Salt Lake": true,
      "St. Louis": true,
      "Sporting KC": true,
      "LAFC": true,
      "San Jose": true,
      "Portland": true,
      "Minnesota": true,
      "Vancouver": true,
      "Seattle Sounders": true,
      "West Ham": true,
      "Leeds United": true,
      "Liverpool": true,
      "Aston Villa": true,
      "Brighton": true,
      "Southampton": true,
      "Tottenham": true,
      "Brentford": true,
      "Wolves": true,
      "Everton": true,
      "Nottm Forest": true,
      "Arsenal": true,
      "Newcastle": true,
      "Leicester City": true,
      "Bournemouth": true,
      "Man United": true,
      "Man City": true,
      "Chelsea": true,
      "Fulham": true,
      "Crystal Palace": true,
      "Arouca": true,
      "Chaves": true,
      "Santa Clara": true,
      "Portimonense": true,
      "Marítimo": true,
      "Vizela": true,
      "Sporting": true,
      "Benfica": true,
      "Famalicão": true,
      "Porto": true,
      "Vitória SC": true,
      "Gil Vicente": true,
      "Casa Pia": true,
      "Estoril Praia": true,
      "Boavista": true,
      "Braga": true,
      "Pacos Ferreira": true,
      "Rio Ave": true,
      "Godoy Cruz": true,
      "Gimnasia": true,
      "Rosario Central": true,
      "Defensa y Justicia": true,
      "Estudiantes": true,
      "Banfield": true,
      "Central Córdoba": true,
      "Unión": true,
      "Racing Club": true,
      "Vélez Sársfield": true,
      "Argentinos Juniors": true,
      "Boca Juniors": true,
      "San Lorenzo": true,
      "Instituto ACC": true,
      "Lanús": true,
      "Newell's": true,
      "Tigre": true,
      "Atlético Tucumán": true,
      "Belgrano": true,
      "Talleres Córdoba": true,
      "Sarmiento": true,
      "Huracán": true,
      "Colón": true,
      "Barracas Central": true,
      "River Plate": true,
      "Platense": true,
      "Sassuolo": true,
      "Monza": true,
      "Cremonese": true,
      "Bologna": true,
      "Atalanta": true,
      "Verona": true,
      "Milan": true,
      "Sampdoria": true,
      "Lecce": true,
      "Spezia": true,
      "Torino": true,
      "Fiorentina": true,
      "Napoli": true,
      "Inter": true,
      "Udinese": true,
      "Lazio": true,
      "Roma": true,
      "Salernitana": true,
      "Empoli": true,
      "Juventus": true
    }
  },
  "374": {
    "gwNum": 374,
    "startDate": "Tue May 23 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri May 26 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Elche": true,
      "Sevilla": true,
      "Real Betis": true,
      "Getafe": true,
      "Villarreal": true,
      "Cádiz": true,
      "Real Sociedad": true,
      "Almería": true,
      "Osasuna": true,
      "Athletic Club": true,
      "Mallorca": true,
      "Valencia": true,
      "Celta Vigo": true,
      "Girona": true,
      "Real Madrid": true,
      "Rayo Vallecano": true,
      "Espanyol": true,
      "Atlético Madrid": true,
      "Valladolid": true,
      "Barcelona": true
    }
  },
  "375": {
    "gwNum": 375,
    "startDate": "Fri May 26 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue May 30 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Athletico-PR": true,
      "Grêmio": true,
      "Fortaleza": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "Cruzeiro": true,
      "Cuiabá": true,
      "Coritiba": true,
      "São Paulo": true,
      "Goiás": true,
      "Corinthians": true,
      "Fluminense": true,
      "Internacional": true,
      "Bahia": true,
      "Atlético Mineiro": true,
      "Palmeiras": true,
      "Bragantino": true,
      "Santos": true,
      "Botafogo": true,
      "América-MG": true,
      "Odd": true,
      "Strømsgodset": true,
      "Molde": true,
      "Sandefjord": true,
      "Haugesund": true,
      "Tromso": true,
      "Bodø/Glimt": true,
      "Viking": true,
      "Brann": true,
      "Rosenborg": true,
      "Sarpsborg": true,
      "Aalesund": true,
      "Stabæk": true,
      "Vålerenga": true,
      "HamKam": true,
      "Lillestrøm": true,
      "Twente": true,
      "Ajax": true,
      "Feyenoord": true,
      "Vitesse": true,
      "Fortuna Sittard": true,
      "NEC": true,
      "Cambuur": true,
      "RKC Waalwijk": true,
      "AZ Alkmaar": true,
      "PSV": true,
      "FC Volendam": true,
      "Excelsior": true,
      "Groningen": true,
      "Sparta Rotterdam": true,
      "Heerenveen": true,
      "Go Ahead Eagles": true,
      "Utrecht": true,
      "FC Emmen": true,
      "Consadole Sapporo": true,
      "Nagoya": true,
      "Vissel Kobe": true,
      "FC Tokyo": true,
      "Sanfrecce": true,
      "Shonan Bellmare": true,
      "Sagan Tosu": true,
      "Kashima Antlers": true,
      "Kyoto Sanga": true,
      "Urawa Reds": true,
      "Cerezo Osaka": true,
      "Yokohama FC": true,
      "Niigata": true,
      "Gamba Osaka": true,
      "Yokohama FM": true,
      "Avispa Fukuoka": true,
      "Kawasaki": true,
      "Kashiwa Reysol": true,
      "Jeju": true,
      "Suwon Bluewings": true,
      "Daegu": true,
      "Incheon": true,
      "FC Seoul": true,
      "Gangwon": true,
      "Suwon FC": true,
      "Gwangju": true,
      "Ulsan": true,
      "Daejeon Hana": true,
      "Pohang Steelers": true,
      "Jeonbuk": true,
      "Valencia": true,
      "Espanyol": true,
      "Cádiz": true,
      "Celta Vigo": true,
      "Athletic Club": true,
      "Elche": true,
      "Girona": true,
      "Real Betis": true,
      "Getafe": true,
      "Osasuna": true,
      "Barcelona": true,
      "Mallorca": true,
      "Atlético Madrid": true,
      "Real Sociedad": true,
      "Almería": true,
      "Valladolid": true,
      "Rayo Vallecano": true,
      "Villarreal": true,
      "Sevilla": true,
      "Real Madrid": true,
      "Strasbourg": true,
      "PSG": true,
      "Lyon": true,
      "Reims": true,
      "Montpellier": true,
      "Nice": true,
      "Rennes": true,
      "Monaco": true,
      "Toulouse": true,
      "Auxerre": true,
      "Marseille": true,
      "Brest": true,
      "Lens": true,
      "Ajaccio": true,
      "Angers": true,
      "Troyes": true,
      "LOSC": true,
      "Nantes": true,
      "Clermont Foot": true,
      "Lorient": true,
      "Montréal": true,
      "Inter Miami": true,
      "New England": true,
      "Chicago": true,
      "New York City": true,
      "Philadelphia": true,
      "Orlando City": true,
      "Atlanta United": true,
      "Toronto": true,
      "D.C. United": true,
      "St. Louis": true,
      "Vancouver": true,
      "Houston Dynamo": true,
      "Austin": true,
      "Minnesota": true,
      "Real Salt Lake": true,
      "Colorado": true,
      "Cincinnati": true,
      "Seattle Sounders": true,
      "NY Red Bulls": true,
      "San Jose": true,
      "FC Dallas": true,
      "LA Galaxy": true,
      "Charlotte": true,
      "Sporting KC": true,
      "Portland": true,
      "Nashville": true,
      "Columbus": true,
      "Aston Villa": true,
      "Brighton": true,
      "Everton": true,
      "Bournemouth": true,
      "Leeds United": true,
      "Tottenham": true,
      "Brentford": true,
      "Man City": true,
      "Man United": true,
      "Fulham": true,
      "Chelsea": true,
      "Newcastle": true,
      "Leicester City": true,
      "West Ham": true,
      "Arsenal": true,
      "Wolves": true,
      "Southampton": true,
      "Liverpool": true,
      "Crystal Palace": true,
      "Nottm Forest": true,
      "Estoril Praia": true,
      "Marítimo": true,
      "Vizela": true,
      "Sporting": true,
      "Rio Ave": true,
      "Famalicão": true,
      "Braga": true,
      "Pacos Ferreira": true,
      "Gil Vicente": true,
      "Casa Pia": true,
      "Chaves": true,
      "Boavista": true,
      "Portimonense": true,
      "Arouca": true,
      "Benfica": true,
      "Santa Clara": true,
      "Porto": true,
      "Vitória SC": true,
      "Atlético Tucumán": true,
      "Arsenal Sarandi": true,
      "Gimnasia": true,
      "Sarmiento": true,
      "Platense": true,
      "Belgrano": true,
      "Independiente": true,
      "Lanús": true,
      "Barracas Central": true,
      "San Lorenzo": true,
      "Defensa y Justicia": true,
      "Racing Club": true,
      "Talleres Córdoba": true,
      "Argentinos Juniors": true,
      "Boca Juniors": true,
      "Tigre": true,
      "Newell's": true,
      "Godoy Cruz": true,
      "Colón": true,
      "Central Córdoba": true,
      "Huracán": true,
      "Unión": true,
      "Banfield": true,
      "Rosario Central": true,
      "Instituto ACC": true,
      "Estudiantes": true,
      "Vélez Sársfield": true,
      "River Plate": true,
      "Bologna": true,
      "Napoli": true,
      "Fiorentina": true,
      "Roma": true,
      "Sampdoria": true,
      "Sassuolo": true,
      "Inter": true,
      "Atalanta": true,
      "Salernitana": true,
      "Udinese": true,
      "Lazio": true,
      "Cremonese": true,
      "Juventus": true,
      "Milan": true,
      "Spezia": true,
      "Torino": true,
      "Verona": true,
      "Empoli": true,
      "Monza": true,
      "Lecce": true
    }
  },
  "376": {
    "gwNum": 376,
    "startDate": "Tue May 30 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jun 02 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Urawa Reds": true,
      "Sanfrecce": true,
      "Columbus": true,
      "Colorado": true,
      "Atlanta United": true,
      "New England": true,
      "New York City": true,
      "Cincinnati": true,
      "Philadelphia": true,
      "Charlotte": true,
      "D.C. United": true,
      "Montréal": true,
      "Toronto": true,
      "Chicago": true,
      "Inter Miami": true,
      "NY Red Bulls": true,
      "Sporting KC": true,
      "FC Dallas": true,
      "Austin": true,
      "Minnesota": true,
      "Real Salt Lake": true,
      "LA Galaxy": true,
      "LAFC": true,
      "St. Louis": true,
      "Vancouver": true,
      "Houston Dynamo": true,
      "Seattle Sounders": true,
      "San Jose": true
    }
  },
  "377": {
    "gwNum": 377,
    "startDate": "Fri Jun 02 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jun 06 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Fortaleza": true,
      "Bahia": true,
      "Athletico-PR": true,
      "Botafogo": true,
      "Cruzeiro": true,
      "Atlético Mineiro": true,
      "América-MG": true,
      "Corinthians": true,
      "Santos": true,
      "Internacional": true,
      "Fluminense": true,
      "Bragantino": true,
      "Grêmio": true,
      "São Paulo": true,
      "Goiás": true,
      "Cuiabá": true,
      "Palmeiras": true,
      "Coritiba": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "Lillestrøm": true,
      "Stabæk": true,
      "Vålerenga": true,
      "Bodø/Glimt": true,
      "Tromso": true,
      "Sarpsborg": true,
      "Sandefjord": true,
      "Odd": true,
      "Aalesund": true,
      "Brann": true,
      "Strømsgodset": true,
      "Haugesund": true,
      "Rosenborg": true,
      "HamKam": true,
      "Viking": true,
      "Molde": true,
      "Vissel Kobe": true,
      "Kawasaki": true,
      "Shonan Bellmare": true,
      "Niigata": true,
      "FC Tokyo": true,
      "Yokohama FM": true,
      "Nagoya": true,
      "Cerezo Osaka": true,
      "Avispa Fukuoka": true,
      "Gamba Osaka": true,
      "Yokohama FC": true,
      "Sagan Tosu": true,
      "Kashiwa Reysol": true,
      "Consadole Sapporo": true,
      "Sanfrecce": true,
      "Kyoto Sanga": true,
      "Urawa Reds": true,
      "Kashima Antlers": true,
      "Jeonbuk": true,
      "Ulsan": true,
      "Suwon Bluewings": true,
      "Suwon FC": true,
      "Jeju": true,
      "Gangwon": true,
      "Gwangju": true,
      "Pohang Steelers": true,
      "Daejeon Hana": true,
      "Incheon": true,
      "Daegu": true,
      "FC Seoul": true,
      "Mallorca": true,
      "Rayo Vallecano": true,
      "Real Betis": true,
      "Valencia": true,
      "Celta Vigo": true,
      "Barcelona": true,
      "Real Sociedad": true,
      "Sevilla": true,
      "Villarreal": true,
      "Atlético Madrid": true,
      "Real Madrid": true,
      "Athletic Club": true,
      "Espanyol": true,
      "Almería": true,
      "Elche": true,
      "Cádiz": true,
      "Valladolid": true,
      "Getafe": true,
      "Osasuna": true,
      "Girona": true,
      "Reims": true,
      "Montpellier": true,
      "Nice": true,
      "Lyon": true,
      "Nantes": true,
      "Angers": true,
      "PSG": true,
      "Clermont Foot": true,
      "Ajaccio": true,
      "Marseille": true,
      "Troyes": true,
      "LOSC": true,
      "Lorient": true,
      "Strasbourg": true,
      "Auxerre": true,
      "Lens": true,
      "Brest": true,
      "Rennes": true,
      "Monaco": true,
      "Toulouse": true,
      "New York City": true,
      "New England": true,
      "Seattle Sounders": true,
      "Portland": true,
      "Inter Miami": true,
      "D.C. United": true,
      "Columbus": true,
      "Charlotte": true,
      "NY Red Bulls": true,
      "Orlando City": true,
      "Philadelphia": true,
      "Montréal": true,
      "Cincinnati": true,
      "Chicago": true,
      "Austin": true,
      "Real Salt Lake": true,
      "Minnesota": true,
      "Toronto": true,
      "FC Dallas": true,
      "Nashville": true,
      "St. Louis": true,
      "Houston Dynamo": true,
      "Colorado": true,
      "San Jose": true,
      "Vancouver": true,
      "Sporting KC": true,
      "LAFC": true,
      "Atlanta United": true,
      "Belgrano": true,
      "Vélez Sársfield": true,
      "Rosario Central": true,
      "Instituto ACC": true,
      "Unión": true,
      "Gimnasia": true,
      "Lanús": true,
      "Atlético Tucumán": true,
      "Racing Club": true,
      "Banfield": true,
      "Sarmiento": true,
      "Newell's": true,
      "River Plate": true,
      "Defensa y Justicia": true,
      "Tigre": true,
      "Talleres Córdoba": true,
      "Godoy Cruz": true,
      "Independiente": true,
      "Estudiantes": true,
      "Barracas Central": true,
      "Central Córdoba": true,
      "Huracán": true,
      "Argentinos Juniors": true,
      "Platense": true,
      "Arsenal Sarandi": true,
      "Boca Juniors": true,
      "San Lorenzo": true,
      "Colón": true,
      "Atalanta": true,
      "Monza": true,
      "Sassuolo": true,
      "Fiorentina": true,
      "Udinese": true,
      "Juventus": true,
      "Lecce": true,
      "Bologna": true,
      "Napoli": true,
      "Sampdoria": true,
      "Milan": true,
      "Verona": true,
      "Torino": true,
      "Inter": true,
      "Cremonese": true,
      "Salernitana": true,
      "Empoli": true,
      "Lazio": true,
      "Roma": true,
      "Spezia": true
    }
  },
  "378": {
    "gwNum": 378,
    "startDate": "Tue Jun 06 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jun 09 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Gwangju": true,
      "Suwon Bluewings": true,
      "Jeonbuk": true,
      "Daegu": true,
      "Incheon": true,
      "FC Seoul": true,
      "Gangwon": true,
      "Daejeon Hana": true
    }
  },
  "379": {
    "gwNum": 379,
    "startDate": "Fri Jun 09 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jun 13 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "São Paulo": true,
      "Palmeiras": true,
      "Corinthians": true,
      "Cuiabá": true,
      "Coritiba": true,
      "Santos": true,
      "Botafogo": true,
      "Fortaleza": true,
      "Atlético Mineiro": true,
      "Bragantino": true,
      "Bahia": true,
      "Cruzeiro": true,
      "América-MG": true,
      "Athletico-PR": true,
      "Internacional": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "Grêmio": true,
      "Goiás": true,
      "Fluminense": true,
      "Vålerenga": true,
      "Strømsgodset": true,
      "Bodø/Glimt": true,
      "HamKam": true,
      "Haugesund": true,
      "Sandefjord": true,
      "Stabæk": true,
      "Rosenborg": true,
      "Aalesund": true,
      "Tromso": true,
      "Odd": true,
      "Molde": true,
      "Sarpsborg": true,
      "Viking": true,
      "Brann": true,
      "Lillestrøm": true,
      "Yokohama FM": true,
      "Kashiwa Reysol": true,
      "Sagan Tosu": true,
      "Consadole Sapporo": true,
      "Cerezo Osaka": true,
      "Vissel Kobe": true,
      "Niigata": true,
      "Kyoto Sanga": true,
      "Nagoya": true,
      "Avispa Fukuoka": true,
      "Gamba Osaka": true,
      "FC Tokyo": true,
      "Kashima Antlers": true,
      "Shonan Bellmare": true,
      "Yokohama FC": true,
      "Urawa Reds": true,
      "Kawasaki": true,
      "Sanfrecce": true,
      "Daegu": true,
      "Suwon FC": true,
      "Ulsan": true,
      "Jeju": true,
      "Daejeon Hana": true,
      "Gwangju": true,
      "Gangwon": true,
      "Jeonbuk": true,
      "FC Seoul": true,
      "Pohang Steelers": true,
      "Suwon Bluewings": true,
      "Incheon": true,
      "Toronto": true,
      "Nashville": true,
      "Atlanta United": true,
      "D.C. United": true,
      "Orlando City": true,
      "Colorado": true,
      "Montréal": true,
      "Minnesota": true,
      "Charlotte": true,
      "Seattle Sounders": true,
      "New England": true,
      "Inter Miami": true,
      "Houston Dynamo": true,
      "LAFC": true,
      "Sporting KC": true,
      "Austin": true,
      "Chicago": true,
      "Columbus": true,
      "Real Salt Lake": true,
      "New York City": true,
      "San Jose": true,
      "Philadelphia": true,
      "Vancouver": true,
      "Cincinnati": true,
      "St. Louis": true,
      "LA Galaxy": true,
      "Portland": true,
      "FC Dallas": true,
      "Instituto ACC": true,
      "Racing Club": true,
      "Vélez Sársfield": true,
      "Argentinos Juniors": true,
      "Platense": true,
      "Tigre": true,
      "Barracas Central": true,
      "Rosario Central": true,
      "Gimnasia": true,
      "Huracán": true,
      "Newell's": true,
      "Unión": true,
      "Colón": true,
      "Estudiantes": true,
      "San Lorenzo": true,
      "Central Córdoba": true,
      "Defensa y Justicia": true,
      "Belgrano": true,
      "Independiente": true,
      "Sarmiento": true,
      "Talleres Córdoba": true,
      "Arsenal Sarandi": true,
      "Atlético Tucumán": true,
      "Godoy Cruz": true,
      "Banfield": true,
      "River Plate": true,
      "Boca Juniors": true,
      "Lanús": true
    }
  },
  "380": {
    "gwNum": 380,
    "startDate": "Tue Jun 13 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jun 16 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "381": {
    "gwNum": 381,
    "startDate": "Fri Jun 16 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jun 20 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "New York City": true,
      "Columbus": true,
      "New England": true,
      "Orlando City": true,
      "D.C. United": true,
      "Real Salt Lake": true,
      "Sporting KC": true,
      "LAFC": true,
      "Nashville": true,
      "St. Louis": true,
      "San Jose": true,
      "Portland": true
    }
  },
  "382": {
    "gwNum": 382,
    "startDate": "Tue Jun 20 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jun 23 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Fluminense": true,
      "Atlético Mineiro": true,
      "Cuiabá": true,
      "Botafogo": true,
      "Cruzeiro": true,
      "Fortaleza": true,
      "Bahia": true,
      "Palmeiras": true,
      "Grêmio": true,
      "América-MG": true,
      "São Paulo": true,
      "Athletico-PR": true,
      "Vasco da Gama": true,
      "Goiás": true,
      "Coritiba": true,
      "Internacional": true,
      "Santos": true,
      "Corinthians": true,
      "Bragantino": true,
      "Flamengo": true,
      "Atlanta United": true,
      "New York City": true,
      "NY Red Bulls": true,
      "Charlotte": true,
      "Orlando City": true,
      "Philadelphia": true,
      "Montréal": true,
      "Nashville": true,
      "Cincinnati": true,
      "Toronto": true,
      "Houston Dynamo": true,
      "San Jose": true,
      "Austin": true,
      "FC Dallas": true,
      "St. Louis": true,
      "Real Salt Lake": true,
      "Colorado": true,
      "Vancouver": true,
      "Portland": true,
      "Chicago": true,
      "LAFC": true,
      "Seattle Sounders": true,
      "LA Galaxy": true,
      "Sporting KC": true,
      "Godoy Cruz": true,
      "Boca Juniors": true,
      "Arsenal Sarandi": true,
      "Platense": true,
      "Belgrano": true,
      "Banfield": true,
      "Unión": true,
      "Independiente": true,
      "Racing Club": true,
      "Barracas Central": true,
      "Sarmiento": true,
      "Atlético Tucumán": true,
      "River Plate": true,
      "Instituto ACC": true,
      "Huracán": true,
      "Newell's": true,
      "Rosario Central": true,
      "Colón": true,
      "Estudiantes": true,
      "San Lorenzo": true,
      "Central Córdoba": true,
      "Gimnasia": true,
      "Argentinos Juniors": true,
      "Defensa y Justicia": true,
      "Lanús": true,
      "Talleres Córdoba": true,
      "Tigre": true,
      "Vélez Sársfield": true
    }
  },
  "383": {
    "gwNum": 383,
    "startDate": "Fri Jun 23 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jun 27 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Palmeiras": true,
      "Botafogo": true,
      "Athletico-PR": true,
      "Corinthians": true,
      "Cruzeiro": true,
      "São Paulo": true,
      "América-MG": true,
      "Internacional": true,
      "Santos": true,
      "Flamengo": true,
      "Fluminense": true,
      "Bahia": true,
      "Bragantino": true,
      "Goiás": true,
      "Grêmio": true,
      "Coritiba": true,
      "Vasco da Gama": true,
      "Cuiabá": true,
      "Fortaleza": true,
      "Atlético Mineiro": true,
      "Viking": true,
      "Brann": true,
      "Lillestrøm": true,
      "Aalesund": true,
      "HamKam": true,
      "Odd": true,
      "Sandefjord": true,
      "Vålerenga": true,
      "Rosenborg": true,
      "Sarpsborg": true,
      "Molde": true,
      "Haugesund": true,
      "Tromso": true,
      "Stabæk": true,
      "Strømsgodset": true,
      "Bodø/Glimt": true,
      "Consadole Sapporo": true,
      "Cerezo Osaka": true,
      "Kashiwa Reysol": true,
      "Niigata": true,
      "Shonan Bellmare": true,
      "Sagan Tosu": true,
      "Sanfrecce": true,
      "Yokohama FM": true,
      "Kyoto Sanga": true,
      "Yokohama FC": true,
      "FC Tokyo": true,
      "Nagoya": true,
      "Gamba Osaka": true,
      "Kashima Antlers": true,
      "Urawa Reds": true,
      "Kawasaki": true,
      "Avispa Fukuoka": true,
      "Vissel Kobe": true,
      "Suwon Bluewings": true,
      "FC Seoul": true,
      "Jeju": true,
      "Daejeon Hana": true,
      "Gwangju": true,
      "Jeonbuk": true,
      "Ulsan": true,
      "Daegu": true,
      "Incheon": true,
      "Pohang Steelers": true,
      "Suwon FC": true,
      "Gangwon": true,
      "New England": true,
      "Toronto": true,
      "Charlotte": true,
      "Montréal": true,
      "NY Red Bulls": true,
      "Atlanta United": true,
      "Columbus": true,
      "Nashville": true,
      "D.C. United": true,
      "Cincinnati": true,
      "Philadelphia": true,
      "Inter Miami": true,
      "Sporting KC": true,
      "Chicago": true,
      "Austin": true,
      "Houston Dynamo": true,
      "Real Salt Lake": true,
      "Minnesota": true,
      "Colorado": true,
      "LA Galaxy": true,
      "LAFC": true,
      "Vancouver": true,
      "Seattle Sounders": true,
      "Orlando City": true,
      "San Jose": true,
      "St. Louis": true,
      "Portland": true,
      "New York City": true
    }
  },
  "384": {
    "gwNum": 384,
    "startDate": "Tue Jun 27 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jun 30 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Urawa Reds": true,
      "Shonan Bellmare": true,
      "Cerezo Osaka": true,
      "Avispa Fukuoka": true
    }
  },
  "385": {
    "gwNum": 385,
    "startDate": "Fri Jun 30 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jul 04 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Botafogo": true,
      "Vasco da Gama": true,
      "Goiás": true,
      "Coritiba": true,
      "Internacional": true,
      "Cruzeiro": true,
      "Atlético Mineiro": true,
      "América-MG": true,
      "Cuiabá": true,
      "Santos": true,
      "Athletico-PR": true,
      "Palmeiras": true,
      "Flamengo": true,
      "Fortaleza": true,
      "Corinthians": true,
      "Bragantino": true,
      "São Paulo": true,
      "Fluminense": true,
      "Bahia": true,
      "Grêmio": true,
      "Vålerenga": true,
      "Viking": true,
      "Lillestrøm": true,
      "Tromso": true,
      "Sarpsborg": true,
      "Sandefjord": true,
      "Brann": true,
      "HamKam": true,
      "Aalesund": true,
      "Rosenborg": true,
      "Haugesund": true,
      "Odd": true,
      "Bodø/Glimt": true,
      "Molde": true,
      "Stabæk": true,
      "Strømsgodset": true,
      "Nagoya": true,
      "Kawasaki": true,
      "Kashima Antlers": true,
      "Kyoto Sanga": true,
      "Niigata": true,
      "Sanfrecce": true,
      "Yokohama FC": true,
      "Gamba Osaka": true,
      "Sagan Tosu": true,
      "Urawa Reds": true,
      "FC Tokyo": true,
      "Kashiwa Reysol": true,
      "Vissel Kobe": true,
      "Consadole Sapporo": true,
      "Yokohama FM": true,
      "Shonan Bellmare": true,
      "Jeonbuk": true,
      "Jeju": true,
      "Daegu": true,
      "Suwon Bluewings": true,
      "FC Seoul": true,
      "Daejeon Hana": true,
      "Gwangju": true,
      "Ulsan": true,
      "Pohang Steelers": true,
      "Suwon FC": true,
      "Incheon": true,
      "Gangwon": true,
      "Toronto": true,
      "Real Salt Lake": true,
      "Orlando City": true,
      "Chicago": true,
      "Montréal": true,
      "New York City": true,
      "Columbus": true,
      "NY Red Bulls": true,
      "Inter Miami": true,
      "Austin": true,
      "Cincinnati": true,
      "New England": true,
      "Nashville": true,
      "D.C. United": true,
      "St. Louis": true,
      "Colorado": true,
      "FC Dallas": true,
      "LAFC": true,
      "Minnesota": true,
      "Portland": true,
      "Sporting KC": true,
      "Vancouver": true,
      "Seattle Sounders": true,
      "Houston Dynamo": true,
      "San Jose": true,
      "LA Galaxy": true,
      "Atlanta United": true,
      "Philadelphia": true,
      "San Lorenzo": true,
      "Rosario Central": true,
      "Colón": true,
      "Racing Club": true,
      "Platense": true,
      "Lanús": true,
      "Boca Juniors": true,
      "Sarmiento": true,
      "Atlético Tucumán": true,
      "Unión": true,
      "Barracas Central": true,
      "River Plate": true,
      "Newell's": true,
      "Gimnasia": true,
      "Vélez Sársfield": true,
      "Arsenal Sarandi": true,
      "Instituto ACC": true,
      "Belgrano": true,
      "Banfield": true,
      "Argentinos Juniors": true,
      "Independiente": true,
      "Huracán": true,
      "Defensa y Justicia": true,
      "Tigre": true,
      "Estudiantes": true,
      "Central Córdoba": true,
      "Talleres Córdoba": true,
      "Godoy Cruz": true
    }
  },
  "386": {
    "gwNum": 386,
    "startDate": "Tue Jul 04 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jul 07 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Niigata": true,
      "Vissel Kobe": true,
      "Gangwon": true,
      "Gwangju": true,
      "Jeju": true,
      "Daegu": true,
      "Inter Miami": true,
      "Columbus": true,
      "Orlando City": true,
      "Toronto": true,
      "FC Dallas": true,
      "D.C. United": true,
      "Colorado": true,
      "Portland": true,
      "LA Galaxy": true,
      "LAFC": true,
      "New York City": true,
      "Charlotte": true,
      "Sarmiento": true,
      "Talleres Córdoba": true,
      "Godoy Cruz": true,
      "Platense": true,
      "Racing Club": true,
      "San Lorenzo": true,
      "Gimnasia": true,
      "Independiente": true,
      "Rosario Central": true,
      "Estudiantes": true,
      "Unión": true,
      "Boca Juniors": true,
      "Argentinos Juniors": true,
      "Instituto ACC": true,
      "Huracán": true,
      "Atlético Tucumán": true,
      "Tigre": true,
      "Banfield": true,
      "Lanús": true,
      "Vélez Sársfield": true,
      "Belgrano": true,
      "Barracas Central": true,
      "Arsenal Sarandi": true,
      "Defensa y Justicia": true,
      "Central Córdoba": true,
      "Newell's": true,
      "River Plate": true,
      "Colón": true
    }
  },
  "387": {
    "gwNum": 387,
    "startDate": "Fri Jul 07 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jul 11 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Palmeiras": true,
      "Flamengo": true,
      "Fluminense": true,
      "Internacional": true,
      "Atlético Mineiro": true,
      "Corinthians": true,
      "Cuiabá": true,
      "Bahia": true,
      "Santos": true,
      "Goiás": true,
      "Fortaleza": true,
      "Athletico-PR": true,
      "Coritiba": true,
      "América-MG": true,
      "Bragantino": true,
      "São Paulo": true,
      "Grêmio": true,
      "Botafogo": true,
      "Vasco da Gama": true,
      "Cruzeiro": true,
      "HamKam": true,
      "Aalesund": true,
      "Viking": true,
      "Haugesund": true,
      "Tromso": true,
      "Vålerenga": true,
      "Strømsgodset": true,
      "Sarpsborg": true,
      "Rosenborg": true,
      "Lillestrøm": true,
      "Sandefjord": true,
      "Stabæk": true,
      "Odd": true,
      "Bodø/Glimt": true,
      "Molde": true,
      "Brann": true,
      "Nagoya": true,
      "Yokohama FM": true,
      "Sanfrecce": true,
      "Kashima Antlers": true,
      "Avispa Fukuoka": true,
      "Consadole Sapporo": true,
      "Urawa Reds": true,
      "FC Tokyo": true,
      "Kashiwa Reysol": true,
      "Shonan Bellmare": true,
      "Gamba Osaka": true,
      "Kyoto Sanga": true,
      "Kawasaki": true,
      "Yokohama FC": true,
      "Sagan Tosu": true,
      "Cerezo Osaka": true,
      "Pohang Steelers": true,
      "Ulsan": true,
      "Suwon FC": true,
      "Incheon": true,
      "Jeonbuk": true,
      "FC Seoul": true,
      "Daejeon Hana": true,
      "Suwon Bluewings": true,
      "Daegu": true,
      "Gangwon": true,
      "Jeju": true,
      "Gwangju": true,
      "D.C. United": true,
      "Inter Miami": true,
      "Montréal": true,
      "Atlanta United": true,
      "Columbus": true,
      "New York City": true,
      "Toronto": true,
      "St. Louis": true,
      "NY Red Bulls": true,
      "New England": true,
      "Charlotte": true,
      "Cincinnati": true,
      "Houston Dynamo": true,
      "Sporting KC": true,
      "Chicago": true,
      "Nashville": true,
      "Minnesota": true,
      "Austin": true,
      "Real Salt Lake": true,
      "Orlando City": true,
      "Colorado": true,
      "FC Dallas": true,
      "Vancouver": true,
      "Seattle Sounders": true,
      "LA Galaxy": true,
      "Philadelphia": true,
      "LAFC": true,
      "San Jose": true,
      "Boca Juniors": true,
      "Huracán": true,
      "Talleres Córdoba": true,
      "Unión": true,
      "Platense": true,
      "Sarmiento": true,
      "Rosario Central": true,
      "Central Córdoba": true,
      "Estudiantes": true,
      "Racing Club": true,
      "Banfield": true,
      "Arsenal Sarandi": true,
      "Vélez Sársfield": true,
      "Godoy Cruz": true,
      "San Lorenzo": true,
      "River Plate": true,
      "Independiente": true,
      "Newell's": true,
      "Colón": true,
      "Belgrano": true,
      "Defensa y Justicia": true,
      "Lanús": true,
      "Atlético Tucumán": true,
      "Gimnasia": true,
      "Instituto ACC": true,
      "Tigre": true,
      "Barracas Central": true,
      "Argentinos Juniors": true
    }
  },
  "388": {
    "gwNum": 388,
    "startDate": "Tue Jul 11 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jul 14 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Ulsan": true,
      "Incheon": true,
      "FC Seoul": true,
      "Suwon FC": true,
      "Suwon Bluewings": true,
      "Pohang Steelers": true,
      "Daejeon Hana": true,
      "Jeonbuk": true,
      "NY Red Bulls": true,
      "Cincinnati": true,
      "New England": true,
      "Atlanta United": true,
      "Sporting KC": true,
      "Real Salt Lake": true,
      "Nashville": true,
      "Philadelphia": true,
      "Houston Dynamo": true,
      "Minnesota": true,
      "Chicago": true,
      "Montréal": true,
      "San Jose": true,
      "Seattle Sounders": true,
      "Vancouver": true,
      "Austin": true
    }
  },
  "389": {
    "gwNum": 389,
    "startDate": "Fri Jul 14 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jul 18 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Athletico-PR": true,
      "Bahia": true,
      "Corinthians": true,
      "Grêmio": true,
      "Botafogo": true,
      "Bragantino": true,
      "Goiás": true,
      "Atlético Mineiro": true,
      "Fortaleza": true,
      "Cuiabá": true,
      "Internacional": true,
      "Palmeiras": true,
      "Cruzeiro": true,
      "Coritiba": true,
      "Fluminense": true,
      "Flamengo": true,
      "São Paulo": true,
      "Santos": true,
      "América-MG": true,
      "Vasco da Gama": true,
      "Vålerenga": true,
      "Molde": true,
      "Bodø/Glimt": true,
      "Haugesund": true,
      "Rosenborg": true,
      "Tromso": true,
      "Aalesund": true,
      "Odd": true,
      "Sarpsborg": true,
      "HamKam": true,
      "Lillestrøm": true,
      "Sandefjord": true,
      "Stabæk": true,
      "Viking": true,
      "Brann": true,
      "Strømsgodset": true,
      "Consadole Sapporo": true,
      "Niigata": true,
      "Yokohama FM": true,
      "Kawasaki": true,
      "Sanfrecce": true,
      "Yokohama FC": true,
      "Gamba Osaka": true,
      "Kashiwa Reysol": true,
      "Cerezo Osaka": true,
      "Urawa Reds": true,
      "Kyoto Sanga": true,
      "Nagoya": true,
      "Vissel Kobe": true,
      "Sagan Tosu": true,
      "Shonan Bellmare": true,
      "Avispa Fukuoka": true,
      "FC Tokyo": true,
      "Kashima Antlers": true,
      "Suwon Bluewings": true,
      "Ulsan": true,
      "Gangwon": true,
      "FC Seoul": true,
      "Gwangju": true,
      "Daegu": true,
      "Jeonbuk": true,
      "Suwon FC": true,
      "Pohang Steelers": true,
      "Jeju": true,
      "Incheon": true,
      "Daejeon Hana": true,
      "Montréal": true,
      "Charlotte": true,
      "Cincinnati": true,
      "Nashville": true,
      "Philadelphia": true,
      "New York City": true,
      "Atlanta United": true,
      "Orlando City": true,
      "New England": true,
      "D.C. United": true,
      "Chicago": true,
      "Toronto": true,
      "Austin": true,
      "Sporting KC": true,
      "St. Louis": true,
      "Inter Miami": true,
      "Minnesota": true,
      "LAFC": true,
      "Colorado": true,
      "Houston Dynamo": true,
      "Real Salt Lake": true,
      "NY Red Bulls": true,
      "Portland": true,
      "Columbus": true,
      "Seattle Sounders": true,
      "FC Dallas": true,
      "Vancouver": true,
      "LA Galaxy": true,
      "Racing Club": true,
      "Rosario Central": true,
      "Unión": true,
      "Platense": true,
      "Gimnasia": true,
      "Boca Juniors": true,
      "Sarmiento": true,
      "Vélez Sársfield": true,
      "Argentinos Juniors": true,
      "Colón": true,
      "Huracán": true,
      "Talleres Córdoba": true,
      "Lanús": true,
      "Banfield": true,
      "River Plate": true,
      "Estudiantes": true,
      "Tigre": true,
      "Barracas Central": true,
      "Godoy Cruz": true,
      "Defensa y Justicia": true,
      "Belgrano": true,
      "San Lorenzo": true,
      "Arsenal Sarandi": true,
      "Instituto ACC": true,
      "Newell's": true,
      "Atlético Tucumán": true,
      "Central Córdoba": true,
      "Independiente": true
    }
  },
  "390": {
    "gwNum": 390,
    "startDate": "Tue Jul 18 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jul 21 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Pohang Steelers": true,
      "Jeonbuk": true,
      "Ulsan": true,
      "Jeju": true
    }
  },
  "391": {
    "gwNum": 391,
    "startDate": "Fri Jul 21 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Jul 25 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Bragantino": true,
      "Internacional": true,
      "Palmeiras": true,
      "Fortaleza": true,
      "Vasco da Gama": true,
      "Athletico-PR": true,
      "Flamengo": true,
      "América-MG": true,
      "Cuiabá": true,
      "São Paulo": true,
      "Santos": true,
      "Botafogo": true,
      "Grêmio": true,
      "Atlético Mineiro": true,
      "Bahia": true,
      "Corinthians": true,
      "Cruzeiro": true,
      "Goiás": true,
      "Coritiba": true,
      "Fluminense": true,
      "Molde": true,
      "Sarpsborg": true,
      "Tromso": true,
      "Brann": true,
      "HamKam": true,
      "Stabæk": true,
      "Haugesund": true,
      "Lillestrøm": true,
      "Sandefjord": true,
      "Bodø/Glimt": true,
      "Odd": true,
      "Vålerenga": true,
      "Strømsgodset": true,
      "Rosenborg": true,
      "Viking": true,
      "Aalesund": true,
      "Suwon FC": true,
      "Gwangju": true,
      "Gangwon": true,
      "Suwon Bluewings": true,
      "FC Seoul": true,
      "Incheon": true,
      "Daejeon Hana": true,
      "Daegu": true,
      "Valladolid": true,
      "Leganes": true,
      "Boca Juniors": true,
      "Newell's": true,
      "Colón": true,
      "Tigre": true,
      "Vélez Sársfield": true,
      "Unión": true,
      "Rosario Central": true,
      "River Plate": true,
      "Atlético Tucumán": true,
      "Independiente": true,
      "Racing Club": true,
      "Central Córdoba": true,
      "Instituto ACC": true,
      "Lanús": true,
      "Talleres Córdoba": true,
      "Gimnasia": true,
      "Defensa y Justicia": true,
      "Sarmiento": true,
      "Barracas Central": true,
      "Arsenal Sarandi": true,
      "San Lorenzo": true,
      "Argentinos Juniors": true,
      "Banfield": true,
      "Godoy Cruz": true,
      "Estudiantes": true,
      "Belgrano": true,
      "Platense": true,
      "Huracán": true
    }
  },
  "392": {
    "gwNum": 392,
    "startDate": "Tue Jul 25 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Jul 28 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "393": {
    "gwNum": 393,
    "startDate": "Fri Jul 28 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Aug 01 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Union Saint-Gilloise": true,
      "Anderlecht": true,
      "Eupen": true,
      "Westerlo": true,
      "Charleroi": true,
      "Oud-Heverlee Leuven": true,
      "RWDM": true,
      "Genk": true,
      "Antwerp": true,
      "Cercle Brugge": true,
      "Gent": true,
      "Kortrijk": true,
      "Club Brugge": true,
      "Mechelen": true,
      "STVV": true,
      "Standard": true,
      "América-MG": true,
      "Palmeiras": true,
      "Botafogo": true,
      "Coritiba": true,
      "Atlético Mineiro": true,
      "Flamengo": true,
      "Fluminense": true,
      "Santos": true,
      "Athletico-PR": true,
      "Cruzeiro": true,
      "Goiás": true,
      "Grêmio": true,
      "Corinthians": true,
      "Vasco da Gama": true,
      "Fortaleza": true,
      "Bragantino": true,
      "Internacional": true,
      "Cuiabá": true,
      "São Paulo": true,
      "Bahia": true,
      "Lillestrøm": true,
      "HamKam": true,
      "Brann": true,
      "Viking": true,
      "Vålerenga": true,
      "Sandefjord": true,
      "Aalesund": true,
      "Strømsgodset": true,
      "Stabæk": true,
      "Molde": true,
      "Rosenborg": true,
      "Odd": true,
      "Bodø/Glimt": true,
      "Tromso": true,
      "Sarpsborg": true,
      "Haugesund": true,
      "Mirandés": true,
      "Valladolid": true,
      "Argentinos Juniors": true,
      "Estudiantes": true,
      "Tigre": true,
      "San Lorenzo": true,
      "Lanús": true,
      "Barracas Central": true,
      "Newell's": true,
      "Talleres Córdoba": true,
      "Huracán": true,
      "Vélez Sársfield": true,
      "Belgrano": true,
      "Rosario Central": true,
      "Central Córdoba": true,
      "Atlético Tucumán": true,
      "Unión": true,
      "Defensa y Justicia": true,
      "Arsenal Sarandi": true,
      "Colón": true,
      "River Plate": true,
      "Racing Club": true,
      "Independiente": true,
      "Boca Juniors": true,
      "Godoy Cruz": true,
      "Instituto ACC": true,
      "Gimnasia": true,
      "Platense": true,
      "Sarmiento": true,
      "Banfield": true
    }
  },
  "394": {
    "gwNum": 394,
    "startDate": "Tue Aug 01 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Aug 04 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Goiás": true,
      "Fortaleza": true,
      "Vasco da Gama": true,
      "Grêmio": true,
      "Cuiabá": true,
      "Flamengo": true,
      "Coritiba": true,
      "Bragantino": true,
      "Bahia": true,
      "América-MG": true,
      "Fluminense": true,
      "Palmeiras": true,
      "Cruzeiro": true,
      "Botafogo": true,
      "Santos": true,
      "Athletico-PR": true,
      "Internacional": true,
      "Corinthians": true,
      "São Paulo": true,
      "Atlético Mineiro": true,
      "FC Seoul": true,
      "Pohang Steelers": true,
      "Gwangju": true,
      "Daejeon Hana": true
    }
  },
  "395": {
    "gwNum": 395,
    "startDate": "Fri Aug 04 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Aug 08 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Standard": true,
      "Union Saint-Gilloise": true,
      "Genk": true,
      "Eupen": true,
      "Cercle Brugge": true,
      "Charleroi": true,
      "Oud-Heverlee Leuven": true,
      "RWDM": true,
      "Anderlecht": true,
      "Antwerp": true,
      "Mechelen": true,
      "Gent": true,
      "Westerlo": true,
      "Club Brugge": true,
      "Kortrijk": true,
      "STVV": true,
      "América-MG": true,
      "Goiás": true,
      "Atlético Mineiro": true,
      "Bahia": true,
      "Corinthians": true,
      "Coritiba": true,
      "Bragantino": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "São Paulo": true,
      "Botafogo": true,
      "Internacional": true,
      "Grêmio": true,
      "Fluminense": true,
      "Fortaleza": true,
      "Santos": true,
      "Athletico-PR": true,
      "Cuiabá": true,
      "Palmeiras": true,
      "Cruzeiro": true,
      "Sandefjord": true,
      "Sarpsborg": true,
      "Strømsgodset": true,
      "Vålerenga": true,
      "Tromso": true,
      "Lillestrøm": true,
      "HamKam": true,
      "Brann": true,
      "Odd": true,
      "Stabæk": true,
      "Haugesund": true,
      "Rosenborg": true,
      "Viking": true,
      "Bodø/Glimt": true,
      "Nagoya": true,
      "Niigata": true,
      "Shonan Bellmare": true,
      "Sanfrecce": true,
      "Kashima Antlers": true,
      "Consadole Sapporo": true,
      "Yokohama FC": true,
      "Vissel Kobe": true,
      "Kawasaki": true,
      "Gamba Osaka": true,
      "Sagan Tosu": true,
      "Avispa Fukuoka": true,
      "Kyoto Sanga": true,
      "Kashiwa Reysol": true,
      "Urawa Reds": true,
      "Yokohama FM": true,
      "Cerezo Osaka": true,
      "FC Tokyo": true,
      "Suwon Bluewings": true,
      "Suwon FC": true,
      "Daegu": true,
      "Ulsan": true,
      "Jeonbuk": true,
      "Incheon": true,
      "Jeju": true,
      "Gangwon": true
    }
  },
  "396": {
    "gwNum": 396,
    "startDate": "Tue Aug 08 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Aug 11 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "397": {
    "gwNum": 397,
    "startDate": "Fri Aug 11 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Aug 15 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Antwerp": true,
      "Kortrijk": true,
      "RWDM": true,
      "Mechelen": true,
      "Union Saint-Gilloise": true,
      "Oud-Heverlee Leuven": true,
      "Cercle Brugge": true,
      "Genk": true,
      "STVV": true,
      "Anderlecht": true,
      "Eupen": true,
      "Club Brugge": true,
      "Charleroi": true,
      "Standard": true,
      "Westerlo": true,
      "Gent": true,
      "São Paulo": true,
      "Botafogo": true,
      "Vasco da Gama": true,
      "Atlético Mineiro": true,
      "Goiás": true,
      "Athletico-PR": true,
      "Coritiba": true,
      "Flamengo": true,
      "Bahia": true,
      "Bragantino": true,
      "Cuiabá": true,
      "Palmeiras": true,
      "Fluminense": true,
      "América-MG": true,
      "Cruzeiro": true,
      "Corinthians": true,
      "Santos": true,
      "Grêmio": true,
      "Internacional": true,
      "Fortaleza": true,
      "Sarpsborg": true,
      "Strømsgodset": true,
      "Stabæk": true,
      "Tromso": true,
      "Aalesund": true,
      "HamKam": true,
      "Vålerenga": true,
      "Haugesund": true,
      "Sandefjord": true,
      "Molde": true,
      "Lillestrøm": true,
      "Viking": true,
      "FC Volendam": true,
      "Vitesse": true,
      "PSV": true,
      "Utrecht": true,
      "Heerenveen": true,
      "RKC Waalwijk": true,
      "Ajax": true,
      "Heracles": true,
      "PEC Zwolle": true,
      "Sparta Rotterdam": true,
      "NEC": true,
      "Excelsior": true,
      "Feyenoord": true,
      "Fortuna Sittard": true,
      "AZ Alkmaar": true,
      "Go Ahead Eagles": true,
      "Almere": true,
      "Twente": true,
      "Consadole Sapporo": true,
      "Sagan Tosu": true,
      "Niigata": true,
      "Shonan Bellmare": true,
      "Avispa Fukuoka": true,
      "Yokohama FC": true,
      "Kawasaki": true,
      "Vissel Kobe": true,
      "Kashiwa Reysol": true,
      "Cerezo Osaka": true,
      "FC Tokyo": true,
      "Kyoto Sanga": true,
      "Yokohama FM": true,
      "Gamba Osaka": true,
      "Sanfrecce": true,
      "Urawa Reds": true,
      "Nagoya": true,
      "Kashima Antlers": true,
      "Jeonbuk": true,
      "Suwon Bluewings": true,
      "Gangwon": true,
      "Ulsan": true,
      "Jeju": true,
      "Suwon FC": true,
      "Daejeon Hana": true,
      "FC Seoul": true,
      "Incheon": true,
      "Daegu": true,
      "Pohang Steelers": true,
      "Gwangju": true,
      "Amorebieta": true,
      "Levante": true,
      "Valladolid": true,
      "Sporting Gijón": true,
      "Racing": true,
      "Eibar": true,
      "Zaragoza": true,
      "Villarreal B": true,
      "Elche": true,
      "Racing Ferrol": true,
      "Burgos": true,
      "Huesca": true,
      "Albacete": true,
      "Espanyol": true,
      "FC Cartagena": true,
      "Eldense": true,
      "Leganes": true,
      "Andorra": true,
      "Mirandés": true,
      "Alcorcón": true,
      "Tenerife": true,
      "Oviedo": true,
      "Almería": true,
      "Rayo Vallecano": true,
      "Sevilla": true,
      "Valencia": true,
      "Real Sociedad": true,
      "Girona": true,
      "Las Palmas": true,
      "Mallorca": true,
      "Athletic Club": true,
      "Real Madrid": true,
      "Celta Vigo": true,
      "Osasuna": true,
      "Villarreal": true,
      "Real Betis": true,
      "Getafe": true,
      "Barcelona": true,
      "Cádiz": true,
      "Alavés": true,
      "Atlético Madrid": true,
      "Granada": true,
      "Braga": true,
      "Famalicão": true,
      "Rio Ave": true,
      "Chaves": true,
      "Boavista": true,
      "Benfica": true,
      "Arouca": true,
      "Estoril Praia": true,
      "Farense": true,
      "Casa Pia": true,
      "Moreirense": true,
      "Porto": true,
      "Gil Vicente": true,
      "Portimonense": true,
      "Estrela Amadora": true,
      "Vitória SC": true,
      "Sporting": true,
      "Vizela": true,
      "Nantes": true,
      "Toulouse": true,
      "Clermont Foot": true,
      "Monaco": true,
      "Rennes": true,
      "Metz": true,
      "Marseille": true,
      "Reims": true,
      "Strasbourg": true,
      "Lyon": true,
      "Montpellier": true,
      "Le Havre": true,
      "PSG": true,
      "Lorient": true,
      "Brest": true,
      "Lens": true,
      "Nice": true,
      "LOSC": true,
      "Burnley": true,
      "Man City": true,
      "Arsenal": true,
      "Nottm Forest": true,
      "Sheffield United": true,
      "Crystal Palace": true,
      "Bournemouth": true,
      "West Ham": true,
      "Brighton": true,
      "Luton Town": true,
      "Everton": true,
      "Fulham": true,
      "Newcastle": true,
      "Aston Villa": true,
      "Brentford": true,
      "Tottenham": true,
      "Chelsea": true,
      "Liverpool": true,
      "Man United": true,
      "Wolves": true
    }
  },
  "398": {
    "gwNum": 398,
    "startDate": "Tue Aug 15 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Aug 18 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Avispa Fukuoka": true,
      "Niigata": true,
      "Suwon Bluewings": true,
      "Jeju": true,
      "Incheon": true,
      "Gwangju": true
    }
  },
  "399": {
    "gwNum": 399,
    "startDate": "Fri Aug 18 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Aug 22 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Oud-Heverlee Leuven": true,
      "Antwerp": true,
      "Standard": true,
      "Cercle Brugge": true,
      "Genk": true,
      "Charleroi": true,
      "Mechelen": true,
      "Union Saint-Gilloise": true,
      "Anderlecht": true,
      "Westerlo": true,
      "Gent": true,
      "STVV": true,
      "Club Brugge": true,
      "RWDM": true,
      "Kortrijk": true,
      "Eupen": true,
      "Werder": true,
      "Bayern": true,
      "Wolfsburg": true,
      "Heidenheim": true,
      "Hoffenheim": true,
      "SC Freiburg": true,
      "Augsburg": true,
      "Mönchengladbach": true,
      "VfB Stuttgart": true,
      "VfL Bochum": true,
      "Leverkusen": true,
      "RB Leipzig": true,
      "Dortmund": true,
      "Köln": true,
      "Union Berlin": true,
      "Mainz": true,
      "Eintracht Frankfurt": true,
      "Darmstadt": true,
      "Palmeiras": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "Internacional": true,
      "Bragantino": true,
      "Cuiabá": true,
      "Grêmio": true,
      "Cruzeiro": true,
      "Botafogo": true,
      "Bahia": true,
      "Atlético Mineiro": true,
      "Santos": true,
      "Fortaleza": true,
      "Coritiba": true,
      "Corinthians": true,
      "Goiás": true,
      "Athletico-PR": true,
      "Fluminense": true,
      "América-MG": true,
      "São Paulo": true,
      "Tromso": true,
      "Sandefjord": true,
      "HamKam": true,
      "Rosenborg": true,
      "Strømsgodset": true,
      "Lillestrøm": true,
      "Molde": true,
      "Vålerenga": true,
      "Viking": true,
      "Stabæk": true,
      "Odd": true,
      "Sarpsborg": true,
      "Brann": true,
      "Aalesund": true,
      "Haugesund": true,
      "Bodø/Glimt": true,
      "Heracles": true,
      "NEC": true,
      "Excelsior": true,
      "Ajax": true,
      "Vitesse": true,
      "PSV": true,
      "Fortuna Sittard": true,
      "Almere": true,
      "Go Ahead Eagles": true,
      "FC Volendam": true,
      "Utrecht": true,
      "Heerenveen": true,
      "Twente": true,
      "PEC Zwolle": true,
      "Sparta Rotterdam": true,
      "Feyenoord": true,
      "RKC Waalwijk": true,
      "AZ Alkmaar": true,
      "Kashima Antlers": true,
      "Sagan Tosu": true,
      "Vissel Kobe": true,
      "Kashiwa Reysol": true,
      "Gamba Osaka": true,
      "Shonan Bellmare": true,
      "Sanfrecce": true,
      "Kawasaki": true,
      "Kyoto Sanga": true,
      "Consadole Sapporo": true,
      "Urawa Reds": true,
      "Nagoya": true,
      "Yokohama FM": true,
      "FC Tokyo": true,
      "Yokohama FC": true,
      "Cerezo Osaka": true,
      "Gangwon": true,
      "Suwon FC": true,
      "Ulsan": true,
      "Jeonbuk": true,
      "FC Seoul": true,
      "Daegu": true,
      "Pohang Steelers": true,
      "Daejeon Hana": true,
      "Andorra": true,
      "FC Cartagena": true,
      "Zaragoza": true,
      "Valladolid": true,
      "Eibar": true,
      "Elche": true,
      "Espanyol": true,
      "Racing": true,
      "Levante": true,
      "Burgos": true,
      "Alcorcón": true,
      "Leganes": true,
      "Oviedo": true,
      "Racing Ferrol": true,
      "Sporting Gijón": true,
      "Mirandés": true,
      "Albacete": true,
      "Amorebieta": true,
      "Villarreal B": true,
      "Eldense": true,
      "Huesca": true,
      "Tenerife": true,
      "Mallorca": true,
      "Villarreal": true,
      "Valencia": true,
      "Las Palmas": true,
      "Real Sociedad": true,
      "Celta Vigo": true,
      "Almería": true,
      "Real Madrid": true,
      "Osasuna": true,
      "Athletic Club": true,
      "Girona": true,
      "Getafe": true,
      "Barcelona": true,
      "Cádiz": true,
      "Real Betis": true,
      "Atlético Madrid": true,
      "Alavés": true,
      "Sevilla": true,
      "Granada": true,
      "Rayo Vallecano": true,
      "Portimonense": true,
      "Boavista": true,
      "Estoril Praia": true,
      "Rio Ave": true,
      "Benfica": true,
      "Estrela Amadora": true,
      "Vizela": true,
      "Arouca": true,
      "Vitória SC": true,
      "Gil Vicente": true,
      "Porto": true,
      "Farense": true,
      "Famalicão": true,
      "Moreirense": true,
      "Chaves": true,
      "Braga": true,
      "Casa Pia": true,
      "Sporting": true,
      "Toulouse": true,
      "PSG": true,
      "Lyon": true,
      "Montpellier": true,
      "Reims": true,
      "Clermont Foot": true,
      "Lens": true,
      "Rennes": true,
      "Lorient": true,
      "Nice": true,
      "Monaco": true,
      "Strasbourg": true,
      "LOSC": true,
      "Nantes": true,
      "Metz": true,
      "Marseille": true,
      "Le Havre": true,
      "Brest": true,
      "Columbus": true,
      "Cincinnati": true,
      "Philadelphia": true,
      "FC Dallas": true,
      "NY Red Bulls": true,
      "D.C. United": true,
      "New York City": true,
      "Minnesota": true,
      "Toronto": true,
      "Montréal": true,
      "Inter Miami": true,
      "Charlotte": true,
      "Houston Dynamo": true,
      "Portland": true,
      "Nashville": true,
      "New England": true,
      "Chicago": true,
      "Orlando City": true,
      "St. Louis": true,
      "Austin": true,
      "Vancouver": true,
      "San Jose": true,
      "Seattle Sounders": true,
      "Atlanta United": true,
      "LA Galaxy": true,
      "Real Salt Lake": true,
      "LAFC": true,
      "Colorado": true,
      "Nottm Forest": true,
      "Sheffield United": true,
      "Liverpool": true,
      "Bournemouth": true,
      "Wolves": true,
      "Brighton": true,
      "Fulham": true,
      "Brentford": true,
      "Tottenham": true,
      "Man United": true,
      "Man City": true,
      "Newcastle": true,
      "Aston Villa": true,
      "Everton": true,
      "West Ham": true,
      "Chelsea": true,
      "Crystal Palace": true,
      "Arsenal": true,
      "Rosario Central": true,
      "Atlético Tucumán": true,
      "Unión": true,
      "Racing Club": true,
      "Independiente": true,
      "Colón": true,
      "Huracán": true,
      "Banfield": true,
      "Defensa y Justicia": true,
      "Godoy Cruz": true,
      "Vélez Sársfield": true,
      "Barracas Central": true,
      "Sarmiento": true,
      "Tigre": true,
      "Boca Juniors": true,
      "Platense": true,
      "Argentinos Juniors": true,
      "River Plate": true,
      "Belgrano": true,
      "Estudiantes": true,
      "Lanús": true,
      "San Lorenzo": true,
      "Gimnasia": true,
      "Talleres Córdoba": true,
      "Instituto ACC": true,
      "Arsenal Sarandi": true,
      "Central Córdoba": true,
      "Newell's": true,
      "Frosinone": true,
      "Napoli": true,
      "Empoli": true,
      "Verona": true,
      "Genoa": true,
      "Fiorentina": true,
      "Inter": true,
      "Monza": true,
      "Sassuolo": true,
      "Atalanta": true,
      "Roma": true,
      "Salernitana": true,
      "Lecce": true,
      "Lazio": true,
      "Udinese": true,
      "Juventus": true,
      "Torino": true,
      "Cagliari": true,
      "Bologna": true,
      "Milan": true
    }
  },
  "400": {
    "gwNum": 400,
    "startDate": "Tue Aug 22 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Aug 25 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Shonan Bellmare": true,
      "Urawa Reds": true,
      "Suwon FC": true,
      "Incheon": true,
      "Jeonbuk": true,
      "Daejeon Hana": true
    }
  },
  "401": {
    "gwNum": 401,
    "startDate": "Fri Aug 25 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Aug 29 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Westerlo": true,
      "Mechelen": true,
      "Eupen": true,
      "Oud-Heverlee Leuven": true,
      "Kortrijk": true,
      "Standard": true,
      "Club Brugge": true,
      "Genk": true,
      "STVV": true,
      "Cercle Brugge": true,
      "Anderlecht": true,
      "Charleroi": true,
      "RB Leipzig": true,
      "VfB Stuttgart": true,
      "Heidenheim": true,
      "Hoffenheim": true,
      "Köln": true,
      "Wolfsburg": true,
      "Darmstadt": true,
      "Union Berlin": true,
      "VfL Bochum": true,
      "Dortmund": true,
      "SC Freiburg": true,
      "Werder": true,
      "Mönchengladbach": true,
      "Leverkusen": true,
      "Mainz": true,
      "Eintracht Frankfurt": true,
      "Bayern": true,
      "Augsburg": true,
      "Botafogo": true,
      "Flamengo": true,
      "Bahia": true,
      "Vasco da Gama": true,
      "Goiás": true,
      "Internacional": true,
      "Grêmio": true,
      "Cuiabá": true,
      "Cruzeiro": true,
      "Bragantino": true,
      "Corinthians": true,
      "Palmeiras": true,
      "São Paulo": true,
      "Coritiba": true,
      "Athletico-PR": true,
      "Atlético Mineiro": true,
      "América-MG": true,
      "Santos": true,
      "Fluminense": true,
      "Fortaleza": true,
      "Rosenborg": true,
      "Aalesund": true,
      "Lillestrøm": true,
      "Brann": true,
      "Bodø/Glimt": true,
      "Strømsgodset": true,
      "Sandefjord": true,
      "Haugesund": true,
      "Stabæk": true,
      "HamKam": true,
      "Sarpsborg": true,
      "Tromso": true,
      "Vålerenga": true,
      "Odd": true,
      "Molde": true,
      "Viking": true,
      "NEC": true,
      "RKC Waalwijk": true,
      "PSV": true,
      "Go Ahead Eagles": true,
      "Excelsior": true,
      "Fortuna Sittard": true,
      "PEC Zwolle": true,
      "Utrecht": true,
      "Feyenoord": true,
      "Almere": true,
      "Heerenveen": true,
      "Sparta Rotterdam": true,
      "Kashima Antlers": true,
      "Niigata": true,
      "Yokohama FC": true,
      "Yokohama FM": true,
      "Cerezo Osaka": true,
      "Nagoya": true,
      "Kyoto Sanga": true,
      "Avispa Fukuoka": true,
      "Kashiwa Reysol": true,
      "Sanfrecce": true,
      "FC Tokyo": true,
      "Vissel Kobe": true,
      "Sagan Tosu": true,
      "Gamba Osaka": true,
      "Kawasaki": true,
      "Consadole Sapporo": true,
      "Daegu": true,
      "Jeju": true,
      "Gangwon": true,
      "Pohang Steelers": true,
      "FC Seoul": true,
      "Ulsan": true,
      "Gwangju": true,
      "Suwon Bluewings": true,
      "Eldense": true,
      "Eibar": true,
      "Mirandés": true,
      "Espanyol": true,
      "Elche": true,
      "Villarreal B": true,
      "Racing Ferrol": true,
      "Sporting Gijón": true,
      "Leganes": true,
      "Albacete": true,
      "Amorebieta": true,
      "Andorra": true,
      "Burgos": true,
      "Oviedo": true,
      "Racing": true,
      "Huesca": true,
      "Valladolid": true,
      "Alcorcón": true,
      "Tenerife": true,
      "Zaragoza": true,
      "FC Cartagena": true,
      "Levante": true,
      "Cádiz": true,
      "Almería": true,
      "Rayo Vallecano": true,
      "Atlético Madrid": true,
      "Celta Vigo": true,
      "Real Madrid": true,
      "Athletic Club": true,
      "Real Betis": true,
      "Getafe": true,
      "Alavés": true,
      "Sevilla": true,
      "Girona": true,
      "Las Palmas": true,
      "Real Sociedad": true,
      "Granada": true,
      "Mallorca": true,
      "Villarreal": true,
      "Barcelona": true,
      "Valencia": true,
      "Osasuna": true,
      "Moreirense": true,
      "Braga": true,
      "Sporting": true,
      "Famalicão": true,
      "Vitória SC": true,
      "Vizela": true,
      "Gil Vicente": true,
      "Benfica": true,
      "Rio Ave": true,
      "Porto": true,
      "Farense": true,
      "Chaves": true,
      "Boavista": true,
      "Casa Pia": true,
      "Estrela Amadora": true,
      "Estoril Praia": true,
      "Arouca": true,
      "Portimonense": true,
      "Rennes": true,
      "Le Havre": true,
      "Nantes": true,
      "Monaco": true,
      "Nice": true,
      "Lyon": true,
      "Clermont Foot": true,
      "Metz": true,
      "PSG": true,
      "Lens": true,
      "Strasbourg": true,
      "Toulouse": true,
      "Marseille": true,
      "Brest": true,
      "Montpellier": true,
      "Reims": true,
      "Lorient": true,
      "LOSC": true,
      "NY Red Bulls": true,
      "Inter Miami": true,
      "Charlotte": true,
      "LAFC": true,
      "Cincinnati": true,
      "New York City": true,
      "Atlanta United": true,
      "Nashville": true,
      "Orlando City": true,
      "St. Louis": true,
      "D.C. United": true,
      "Philadelphia": true,
      "Columbus": true,
      "Toronto": true,
      "Montréal": true,
      "New England": true,
      "FC Dallas": true,
      "Austin": true,
      "Sporting KC": true,
      "San Jose": true,
      "Real Salt Lake": true,
      "Houston Dynamo": true,
      "Portland": true,
      "Vancouver": true,
      "LA Galaxy": true,
      "Chicago": true,
      "Minnesota": true,
      "Seattle Sounders": true,
      "Chelsea": true,
      "Luton Town": true,
      "Bournemouth": true,
      "Tottenham": true,
      "Brentford": true,
      "Crystal Palace": true,
      "Arsenal": true,
      "Fulham": true,
      "Man United": true,
      "Nottm Forest": true,
      "Everton": true,
      "Wolves": true,
      "Brighton": true,
      "West Ham": true,
      "Sheffield United": true,
      "Man City": true,
      "Burnley": true,
      "Aston Villa": true,
      "Newcastle": true,
      "Liverpool": true,
      "River Plate": true,
      "Barracas Central": true,
      "Tigre": true,
      "Racing Club": true,
      "Banfield": true,
      "Rosario Central": true,
      "Colón": true,
      "Gimnasia": true,
      "San Lorenzo": true,
      "Belgrano": true,
      "Newell's": true,
      "Lanús": true,
      "Sarmiento": true,
      "Boca Juniors": true,
      "Godoy Cruz": true,
      "Central Córdoba": true,
      "Platense": true,
      "Defensa y Justicia": true,
      "Independiente": true,
      "Vélez Sársfield": true,
      "Estudiantes": true,
      "Unión": true,
      "Arsenal Sarandi": true,
      "Argentinos Juniors": true,
      "Atlético Tucumán": true,
      "Instituto ACC": true,
      "Talleres Córdoba": true,
      "Huracán": true,
      "Frosinone": true,
      "Atalanta": true,
      "Monza": true,
      "Empoli": true,
      "Verona": true,
      "Roma": true,
      "Milan": true,
      "Torino": true,
      "Juventus": true,
      "Bologna": true,
      "Fiorentina": true,
      "Lecce": true,
      "Lazio": true,
      "Genoa": true,
      "Napoli": true,
      "Sassuolo": true,
      "Salernitana": true,
      "Udinese": true,
      "Cagliari": true,
      "Inter": true
    }
  },
  "402": {
    "gwNum": 402,
    "startDate": "Tue Aug 29 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Sep 01 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Daejeon Hana": true,
      "Suwon FC": true,
      "Daegu": true,
      "Gangwon": true,
      "New England": true,
      "NY Red Bulls": true,
      "Toronto": true,
      "Philadelphia": true,
      "Atlanta United": true,
      "Cincinnati": true,
      "New York City": true,
      "Montréal": true,
      "Inter Miami": true,
      "Nashville": true,
      "Charlotte": true,
      "Orlando City": true,
      "Minnesota": true,
      "Colorado": true,
      "Chicago": true,
      "Vancouver": true,
      "Houston Dynamo": true,
      "Columbus": true,
      "St. Louis": true,
      "FC Dallas": true,
      "Austin": true,
      "Seattle Sounders": true,
      "San Jose": true,
      "LA Galaxy": true,
      "Portland": true,
      "Real Salt Lake": true
    }
  },
  "403": {
    "gwNum": 403,
    "startDate": "Fri Sep 01 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Sep 05 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Oud-Heverlee Leuven": true,
      "Kortrijk": true,
      "Cercle Brugge": true,
      "Westerlo": true,
      "Mechelen": true,
      "Eupen": true,
      "Standard": true,
      "RWDM": true,
      "Gent": true,
      "Club Brugge": true,
      "Union Saint-Gilloise": true,
      "Antwerp": true,
      "Genk": true,
      "Anderlecht": true,
      "Charleroi": true,
      "STVV": true,
      "Dortmund": true,
      "Heidenheim": true,
      "Augsburg": true,
      "VfL Bochum": true,
      "Leverkusen": true,
      "Darmstadt": true,
      "Hoffenheim": true,
      "Wolfsburg": true,
      "Werder": true,
      "Mainz": true,
      "VfB Stuttgart": true,
      "SC Freiburg": true,
      "Mönchengladbach": true,
      "Bayern": true,
      "Eintracht Frankfurt": true,
      "Köln": true,
      "Union Berlin": true,
      "RB Leipzig": true,
      "Flamengo": true,
      "Athletico-PR": true,
      "Santos": true,
      "Cruzeiro": true,
      "Internacional": true,
      "São Paulo": true,
      "Vasco da Gama": true,
      "Fluminense": true,
      "Palmeiras": true,
      "Goiás": true,
      "Bragantino": true,
      "Grêmio": true,
      "Fortaleza": true,
      "Corinthians": true,
      "Cuiabá": true,
      "América-MG": true,
      "Coritiba": true,
      "Bahia": true,
      "Atlético Mineiro": true,
      "Botafogo": true,
      "Strømsgodset": true,
      "Stabæk": true,
      "Brann": true,
      "Sarpsborg": true,
      "Odd": true,
      "Sandefjord": true,
      "Viking": true,
      "Vålerenga": true,
      "Tromso": true,
      "Rosenborg": true,
      "HamKam": true,
      "Bodø/Glimt": true,
      "Aalesund": true,
      "Lillestrøm": true,
      "Haugesund": true,
      "Molde": true,
      "Sparta Rotterdam": true,
      "NEC": true,
      "Almere": true,
      "PEC Zwolle": true,
      "Go Ahead Eagles": true,
      "Heerenveen": true,
      "RKC Waalwijk": true,
      "PSV": true,
      "Heracles": true,
      "Excelsior": true,
      "Utrecht": true,
      "Feyenoord": true,
      "FC Volendam": true,
      "Twente": true,
      "Fortuna Sittard": true,
      "Ajax": true,
      "Vitesse": true,
      "AZ Alkmaar": true,
      "Cerezo Osaka": true,
      "Kawasaki": true,
      "Kashiwa Reysol": true,
      "Yokohama FM": true,
      "Sagan Tosu": true,
      "Sanfrecce": true,
      "FC Tokyo": true,
      "Avispa Fukuoka": true,
      "Nagoya": true,
      "Yokohama FC": true,
      "Niigata": true,
      "Urawa Reds": true,
      "Shonan Bellmare": true,
      "Kashima Antlers": true,
      "Vissel Kobe": true,
      "Kyoto Sanga": true,
      "Consadole Sapporo": true,
      "Gamba Osaka": true,
      "Suwon Bluewings": true,
      "FC Seoul": true,
      "Incheon": true,
      "Pohang Steelers": true,
      "Ulsan": true,
      "Gwangju": true,
      "Jeju": true,
      "Jeonbuk": true,
      "Huesca": true,
      "Mirandés": true,
      "Elche": true,
      "Racing": true,
      "Alcorcón": true,
      "Racing Ferrol": true,
      "Andorra": true,
      "Tenerife": true,
      "Villarreal B": true,
      "FC Cartagena": true,
      "Eibar": true,
      "Leganes": true,
      "Levante": true,
      "Oviedo": true,
      "Albacete": true,
      "Valladolid": true,
      "Zaragoza": true,
      "Eldense": true,
      "Sporting Gijón": true,
      "Burgos": true,
      "Espanyol": true,
      "Amorebieta": true,
      "Mallorca": true,
      "Athletic Club": true,
      "Real Betis": true,
      "Rayo Vallecano": true,
      "Osasuna": true,
      "Barcelona": true,
      "Girona": true,
      "Las Palmas": true,
      "Almería": true,
      "Celta Vigo": true,
      "Real Madrid": true,
      "Getafe": true,
      "Real Sociedad": true,
      "Granada": true,
      "Atlético Madrid": true,
      "Sevilla": true,
      "Alavés": true,
      "Valencia": true,
      "Cádiz": true,
      "Villarreal": true,
      "Porto": true,
      "Arouca": true,
      "Braga": true,
      "Sporting": true,
      "Casa Pia": true,
      "Rio Ave": true,
      "Benfica": true,
      "Vitória SC": true,
      "Famalicão": true,
      "Farense": true,
      "Vizela": true,
      "Gil Vicente": true,
      "Chaves": true,
      "Moreirense": true,
      "Estoril Praia": true,
      "Boavista": true,
      "Portimonense": true,
      "Estrela Amadora": true,
      "Nice": true,
      "Strasbourg": true,
      "Le Havre": true,
      "Lorient": true,
      "Monaco": true,
      "Lens": true,
      "Brest": true,
      "Rennes": true,
      "Lyon": true,
      "PSG": true,
      "Toulouse": true,
      "Clermont Foot": true,
      "Metz": true,
      "Reims": true,
      "LOSC": true,
      "Montpellier": true,
      "Nantes": true,
      "Marseille": true,
      "New York City": true,
      "Vancouver": true,
      "Cincinnati": true,
      "Orlando City": true,
      "D.C. United": true,
      "Chicago": true,
      "New England": true,
      "Austin": true,
      "Montréal": true,
      "Columbus": true,
      "Sporting KC": true,
      "St. Louis": true,
      "Nashville": true,
      "Charlotte": true,
      "FC Dallas": true,
      "Atlanta United": true,
      "Real Salt Lake": true,
      "Colorado": true,
      "San Jose": true,
      "Minnesota": true,
      "Seattle Sounders": true,
      "Portland": true,
      "LA Galaxy": true,
      "Houston Dynamo": true,
      "Philadelphia": true,
      "NY Red Bulls": true,
      "LAFC": true,
      "Inter Miami": true,
      "Luton Town": true,
      "West Ham": true,
      "Sheffield United": true,
      "Everton": true,
      "Man City": true,
      "Fulham": true,
      "Chelsea": true,
      "Nottm Forest": true,
      "Burnley": true,
      "Tottenham": true,
      "Brentford": true,
      "Bournemouth": true,
      "Brighton": true,
      "Newcastle": true,
      "Crystal Palace": true,
      "Wolves": true,
      "Liverpool": true,
      "Aston Villa": true,
      "Arsenal": true,
      "Man United": true,
      "Instituto ACC": true,
      "Banfield": true,
      "Unión": true,
      "San Lorenzo": true,
      "Defensa y Justicia": true,
      "Sarmiento": true,
      "Lanús": true,
      "Godoy Cruz": true,
      "Rosario Central": true,
      "Talleres Córdoba": true,
      "Argentinos Juniors": true,
      "Atlético Tucumán": true,
      "Gimnasia": true,
      "Independiente": true,
      "Central Córdoba": true,
      "Platense": true,
      "Barracas Central": true,
      "Arsenal Sarandi": true,
      "Boca Juniors": true,
      "Tigre": true,
      "Belgrano": true,
      "Newell's": true,
      "Racing Club": true,
      "Estudiantes": true,
      "Vélez Sársfield": true,
      "River Plate": true,
      "Huracán": true,
      "Colón": true,
      "Sassuolo": true,
      "Verona": true,
      "Roma": true,
      "Milan": true,
      "Udinese": true,
      "Frosinone": true,
      "Bologna": true,
      "Cagliari": true,
      "Napoli": true,
      "Lazio": true,
      "Atalanta": true,
      "Monza": true,
      "Torino": true,
      "Genoa": true,
      "Inter": true,
      "Fiorentina": true,
      "Empoli": true,
      "Juventus": true,
      "Lecce": true,
      "Salernitana": true
    }
  },
  "404": {
    "gwNum": 404,
    "startDate": "Tue Sep 05 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Sep 08 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "405": {
    "gwNum": 405,
    "startDate": "Fri Sep 08 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Sep 12 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Tenerife": true,
      "Albacete": true,
      "Mirandés": true,
      "Andorra": true,
      "Valladolid": true,
      "Elche": true,
      "FC Cartagena": true,
      "Zaragoza": true,
      "Racing Ferrol": true,
      "Villarreal B": true,
      "Oviedo": true,
      "Sporting Gijón": true,
      "Racing": true,
      "Amorebieta": true,
      "Eldense": true,
      "Alcorcón": true,
      "Burgos": true,
      "Eibar": true,
      "Levante": true,
      "Espanyol": true,
      "Leganes": true,
      "Huesca": true,
      "Inter Miami": true,
      "Sporting KC": true,
      "D.C. United": true,
      "San Jose": true,
      "Minnesota": true,
      "New England": true,
      "Portland": true,
      "LAFC": true,
      "LA Galaxy": true,
      "St. Louis": true,
      "San Lorenzo": true,
      "Racing Club": true,
      "Banfield": true,
      "Argentinos Juniors": true,
      "Platense": true,
      "Lanús": true,
      "Gimnasia": true,
      "Vélez Sársfield": true,
      "Boca Juniors": true,
      "Defensa y Justicia": true,
      "Newell's": true,
      "Unión": true,
      "Godoy Cruz": true,
      "Belgrano": true,
      "Colón": true,
      "Rosario Central": true,
      "Arsenal Sarandi": true,
      "River Plate": true,
      "Atlético Tucumán": true,
      "Barracas Central": true,
      "Sarmiento": true,
      "Central Córdoba": true,
      "Independiente": true,
      "Huracán": true,
      "Talleres Córdoba": true,
      "Instituto ACC": true,
      "Tigre": true,
      "Estudiantes": true
    }
  },
  "406": {
    "gwNum": 406,
    "startDate": "Tue Sep 12 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Sep 15 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "América-MG": true,
      "Bragantino": true,
      "Athletico-PR": true,
      "Internacional": true,
      "Bahia": true,
      "Santos": true,
      "Fluminense": true,
      "Cruzeiro": true,
      "Goiás": true,
      "Flamengo": true,
      "São Paulo": true,
      "Fortaleza": true,
      "Corinthians": true,
      "Botafogo": true,
      "Atlético Mineiro": true,
      "Cuiabá": true,
      "Grêmio": true,
      "Palmeiras": true,
      "Vasco da Gama": true,
      "Coritiba": true
    }
  },
  "407": {
    "gwNum": 407,
    "startDate": "Fri Sep 15 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Sep 19 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Westerlo": true,
      "Antwerp": true,
      "RWDM": true,
      "Cercle Brugge": true,
      "Union Saint-Gilloise": true,
      "Genk": true,
      "Club Brugge": true,
      "Charleroi": true,
      "Eupen": true,
      "Standard": true,
      "Kortrijk": true,
      "Anderlecht": true,
      "Oud-Heverlee Leuven": true,
      "Gent": true,
      "STVV": true,
      "Mechelen": true,
      "Bayern": true,
      "Leverkusen": true,
      "SC Freiburg": true,
      "Dortmund": true,
      "Wolfsburg": true,
      "Union Berlin": true,
      "Mainz": true,
      "VfB Stuttgart": true,
      "RB Leipzig": true,
      "Augsburg": true,
      "Köln": true,
      "Hoffenheim": true,
      "VfL Bochum": true,
      "Eintracht Frankfurt": true,
      "Heidenheim": true,
      "Werder": true,
      "Darmstadt": true,
      "Mönchengladbach": true,
      "Sandefjord": true,
      "Strømsgodset": true,
      "Sarpsborg": true,
      "Lillestrøm": true,
      "Rosenborg": true,
      "Bodø/Glimt": true,
      "Haugesund": true,
      "Viking": true,
      "Stabæk": true,
      "Brann": true,
      "Molde": true,
      "Odd": true,
      "Vålerenga": true,
      "Aalesund": true,
      "Tromso": true,
      "HamKam": true,
      "Feyenoord": true,
      "Heerenveen": true,
      "Vitesse": true,
      "RKC Waalwijk": true,
      "PSV": true,
      "NEC": true,
      "Fortuna Sittard": true,
      "FC Volendam": true,
      "Heracles": true,
      "Utrecht": true,
      "PEC Zwolle": true,
      "Go Ahead Eagles": true,
      "Excelsior": true,
      "Almere": true,
      "Twente": true,
      "Ajax": true,
      "AZ Alkmaar": true,
      "Sparta Rotterdam": true,
      "Consadole Sapporo": true,
      "Shonan Bellmare": true,
      "Urawa Reds": true,
      "Kyoto Sanga": true,
      "Kawasaki": true,
      "FC Tokyo": true,
      "Yokohama FC": true,
      "Kashiwa Reysol": true,
      "Kashima Antlers": true,
      "Cerezo Osaka": true,
      "Sanfrecce": true,
      "Vissel Kobe": true,
      "Avispa Fukuoka": true,
      "Nagoya": true,
      "Yokohama FM": true,
      "Sagan Tosu": true,
      "Gamba Osaka": true,
      "Niigata": true,
      "Jeonbuk": true,
      "Gangwon": true,
      "Incheon": true,
      "Jeju": true,
      "Pohang Steelers": true,
      "Suwon FC": true,
      "Ulsan": true,
      "Daejeon Hana": true,
      "FC Seoul": true,
      "Gwangju": true,
      "Suwon Bluewings": true,
      "Daegu": true,
      "Amorebieta": true,
      "Mirandés": true,
      "Sporting Gijón": true,
      "Tenerife": true,
      "Valladolid": true,
      "FC Cartagena": true,
      "Andorra": true,
      "Oviedo": true,
      "Alcorcón": true,
      "Levante": true,
      "Huesca": true,
      "Villarreal B": true,
      "Espanyol": true,
      "Eldense": true,
      "Elche": true,
      "Leganes": true,
      "Albacete": true,
      "Burgos": true,
      "Zaragoza": true,
      "Racing": true,
      "Eibar": true,
      "Racing Ferrol": true,
      "Valencia": true,
      "Atlético Madrid": true,
      "Sevilla": true,
      "Las Palmas": true,
      "Barcelona": true,
      "Real Betis": true,
      "Celta Vigo": true,
      "Mallorca": true,
      "Getafe": true,
      "Osasuna": true,
      "Villarreal": true,
      "Almería": true,
      "Granada": true,
      "Girona": true,
      "Rayo Vallecano": true,
      "Alavés": true,
      "Athletic Club": true,
      "Cádiz": true,
      "Real Madrid": true,
      "Real Sociedad": true,
      "Sporting": true,
      "Moreirense": true,
      "Boavista": true,
      "Chaves": true,
      "Gil Vicente": true,
      "Estoril Praia": true,
      "Farense": true,
      "Braga": true,
      "Arouca": true,
      "Casa Pia": true,
      "Rio Ave": true,
      "Famalicão": true,
      "Estrela Amadora": true,
      "Porto": true,
      "Vitória SC": true,
      "Portimonense": true,
      "Vizela": true,
      "Benfica": true,
      "PSG": true,
      "Nice": true,
      "Lens": true,
      "Metz": true,
      "Marseille": true,
      "Toulouse": true,
      "Lyon": true,
      "Le Havre": true,
      "Rennes": true,
      "LOSC": true,
      "Reims": true,
      "Brest": true,
      "Clermont Foot": true,
      "Nantes": true,
      "Strasbourg": true,
      "Montpellier": true,
      "Lorient": true,
      "Monaco": true,
      "New York City": true,
      "NY Red Bulls": true,
      "Charlotte": true,
      "D.C. United": true,
      "Atlanta United": true,
      "Inter Miami": true,
      "Toronto": true,
      "Vancouver": true,
      "Orlando City": true,
      "Columbus": true,
      "Montréal": true,
      "Chicago": true,
      "Philadelphia": true,
      "Cincinnati": true,
      "Houston Dynamo": true,
      "St. Louis": true,
      "FC Dallas": true,
      "Seattle Sounders": true,
      "Minnesota": true,
      "Sporting KC": true,
      "Colorado": true,
      "New England": true,
      "San Jose": true,
      "Real Salt Lake": true,
      "LAFC": true,
      "LA Galaxy": true,
      "Austin": true,
      "Portland": true,
      "Wolves": true,
      "Liverpool": true,
      "Fulham": true,
      "Luton Town": true,
      "Tottenham": true,
      "Sheffield United": true,
      "West Ham": true,
      "Man City": true,
      "Man United": true,
      "Brighton": true,
      "Aston Villa": true,
      "Crystal Palace": true,
      "Everton": true,
      "Arsenal": true,
      "Bournemouth": true,
      "Chelsea": true,
      "Newcastle": true,
      "Brentford": true,
      "Nottm Forest": true,
      "Burnley": true,
      "Vélez Sársfield": true,
      "Arsenal Sarandi": true,
      "Lanús": true,
      "Sarmiento": true,
      "Defensa y Justicia": true,
      "Tigre": true,
      "Belgrano": true,
      "Platense": true,
      "Argentinos Juniors": true,
      "Talleres Córdoba": true,
      "Huracán": true,
      "Gimnasia": true,
      "Central Córdoba": true,
      "Boca Juniors": true,
      "Estudiantes": true,
      "San Lorenzo": true,
      "River Plate": true,
      "Atlético Tucumán": true,
      "Unión": true,
      "Godoy Cruz": true,
      "Instituto ACC": true,
      "Colón": true,
      "Racing Club": true,
      "Newell's": true,
      "Barracas Central": true,
      "Banfield": true,
      "Rosario Central": true,
      "Independiente": true,
      "Juventus": true,
      "Lazio": true,
      "Inter": true,
      "Milan": true,
      "Genoa": true,
      "Napoli": true,
      "Cagliari": true,
      "Udinese": true,
      "Monza": true,
      "Lecce": true,
      "Frosinone": true,
      "Sassuolo": true,
      "Fiorentina": true,
      "Atalanta": true,
      "Roma": true,
      "Empoli": true,
      "Salernitana": true,
      "Torino": true,
      "Verona": true,
      "Bologna": true
    }
  },
  "408": {
    "gwNum": 408,
    "startDate": "Tue Sep 19 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Sep 22 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "São Paulo": true,
      "Corinthians": true,
      "Cruzeiro": true,
      "América-MG": true,
      "Cuiabá": true,
      "Fluminense": true,
      "Coritiba": true,
      "Athletico-PR": true,
      "Santos": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "Bahia": true,
      "Botafogo": true,
      "Goiás": true,
      "Bragantino": true,
      "Palmeiras": true,
      "Fortaleza": true,
      "Grêmio": true,
      "Internacional": true,
      "Atlético Mineiro": true,
      "Charlotte": true,
      "Philadelphia": true,
      "D.C. United": true,
      "Atlanta United": true,
      "NY Red Bulls": true,
      "Austin": true,
      "Inter Miami": true,
      "Toronto": true,
      "Montréal": true,
      "Cincinnati": true,
      "New York City": true,
      "Orlando City": true,
      "Columbus": true,
      "Chicago": true,
      "Houston Dynamo": true,
      "Vancouver": true,
      "St. Louis": true,
      "LAFC": true,
      "Sporting KC": true,
      "Nashville": true,
      "Real Salt Lake": true,
      "FC Dallas": true,
      "Colorado": true,
      "Seattle Sounders": true,
      "LA Galaxy": true,
      "Minnesota": true,
      "Portland": true,
      "San Jose": true
    }
  },
  "409": {
    "gwNum": 409,
    "startDate": "Fri Sep 22 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Sep 26 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Anderlecht": true,
      "Club Brugge": true,
      "Mechelen": true,
      "Oud-Heverlee Leuven": true,
      "Genk": true,
      "STVV": true,
      "Standard": true,
      "Westerlo": true,
      "Cercle Brugge": true,
      "Union Saint-Gilloise": true,
      "Gent": true,
      "Eupen": true,
      "Charleroi": true,
      "Kortrijk": true,
      "Antwerp": true,
      "RWDM": true,
      "VfB Stuttgart": true,
      "Darmstadt": true,
      "Augsburg": true,
      "Mainz": true,
      "Bayern": true,
      "VfL Bochum": true,
      "Mönchengladbach": true,
      "RB Leipzig": true,
      "Dortmund": true,
      "Wolfsburg": true,
      "Union Berlin": true,
      "Hoffenheim": true,
      "Werder": true,
      "Köln": true,
      "Leverkusen": true,
      "Heidenheim": true,
      "Eintracht Frankfurt": true,
      "SC Freiburg": true,
      "Strømsgodset": true,
      "Molde": true,
      "Lillestrøm": true,
      "Rosenborg": true,
      "Bodø/Glimt": true,
      "Vålerenga": true,
      "Viking": true,
      "Sandefjord": true,
      "Odd": true,
      "Haugesund": true,
      "Aalesund": true,
      "Stabæk": true,
      "HamKam": true,
      "Sarpsborg": true,
      "Brann": true,
      "Tromso": true,
      "Go Ahead Eagles": true,
      "Fortuna Sittard": true,
      "FC Volendam": true,
      "Heracles": true,
      "NEC": true,
      "Utrecht": true,
      "Almere": true,
      "PSV": true,
      "Heerenveen": true,
      "Excelsior": true,
      "Sparta Rotterdam": true,
      "Vitesse": true,
      "Ajax": true,
      "Feyenoord": true,
      "PEC Zwolle": true,
      "AZ Alkmaar": true,
      "RKC Waalwijk": true,
      "Twente": true,
      "Kyoto Sanga": true,
      "Sanfrecce": true,
      "Niigata": true,
      "Yokohama FC": true,
      "Kashiwa Reysol": true,
      "Avispa Fukuoka": true,
      "Nagoya": true,
      "Consadole Sapporo": true,
      "Gamba Osaka": true,
      "Urawa Reds": true,
      "Shonan Bellmare": true,
      "Kawasaki": true,
      "FC Tokyo": true,
      "Sagan Tosu": true,
      "Vissel Kobe": true,
      "Cerezo Osaka": true,
      "Kashima Antlers": true,
      "Yokohama FM": true,
      "Daejeon Hana": true,
      "Suwon Bluewings": true,
      "Jeju": true,
      "FC Seoul": true,
      "Suwon FC": true,
      "Ulsan": true,
      "Gwangju": true,
      "Jeonbuk": true,
      "Gangwon": true,
      "Incheon": true,
      "Daegu": true,
      "Pohang Steelers": true,
      "Levante": true,
      "Eldense": true,
      "Andorra": true,
      "Sporting Gijón": true,
      "Mirandés": true,
      "Leganes": true,
      "Racing Ferrol": true,
      "Zaragoza": true,
      "Alcorcón": true,
      "Huesca": true,
      "FC Cartagena": true,
      "Eibar": true,
      "Oviedo": true,
      "Valladolid": true,
      "Burgos": true,
      "Elche": true,
      "Villarreal B": true,
      "Amorebieta": true,
      "Racing": true,
      "Albacete": true,
      "Tenerife": true,
      "Espanyol": true,
      "Alavés": true,
      "Athletic Club": true,
      "Girona": true,
      "Mallorca": true,
      "Almería": true,
      "Valencia": true,
      "Rayo Vallecano": true,
      "Villarreal": true,
      "Atlético Madrid": true,
      "Real Madrid": true,
      "Real Sociedad": true,
      "Getafe": true,
      "Osasuna": true,
      "Sevilla": true,
      "Barcelona": true,
      "Celta Vigo": true,
      "Real Betis": true,
      "Cádiz": true,
      "Las Palmas": true,
      "Granada": true,
      "Portimonense": true,
      "Benfica": true,
      "Casa Pia": true,
      "Vitória SC": true,
      "Moreirense": true,
      "Farense": true,
      "Braga": true,
      "Boavista": true,
      "Sporting": true,
      "Rio Ave": true,
      "Chaves": true,
      "Estrela Amadora": true,
      "Estoril Praia": true,
      "Vizela": true,
      "Famalicão": true,
      "Arouca": true,
      "Porto": true,
      "Gil Vicente": true,
      "LOSC": true,
      "Reims": true,
      "Brest": true,
      "Lyon": true,
      "Montpellier": true,
      "Rennes": true,
      "Nantes": true,
      "Lorient": true,
      "Monaco": true,
      "Nice": true,
      "Metz": true,
      "Strasbourg": true,
      "PSG": true,
      "Marseille": true,
      "Lens": true,
      "Toulouse": true,
      "Le Havre": true,
      "Clermont Foot": true,
      "D.C. United": true,
      "NY Red Bulls": true,
      "Philadelphia": true,
      "LAFC": true,
      "New York City": true,
      "Toronto": true,
      "Atlanta United": true,
      "Montréal": true,
      "Cincinnati": true,
      "Charlotte": true,
      "Chicago": true,
      "New England": true,
      "Minnesota": true,
      "St. Louis": true,
      "FC Dallas": true,
      "Columbus": true,
      "Sporting KC": true,
      "Houston Dynamo": true,
      "Real Salt Lake": true,
      "Vancouver": true,
      "San Jose": true,
      "Nashville": true,
      "Portland": true,
      "Colorado": true,
      "Orlando City": true,
      "Inter Miami": true,
      "Austin": true,
      "LA Galaxy": true,
      "Chelsea": true,
      "Aston Villa": true,
      "Man City": true,
      "Nottm Forest": true,
      "Crystal Palace": true,
      "Fulham": true,
      "Luton Town": true,
      "Wolves": true,
      "Brentford": true,
      "Everton": true,
      "Burnley": true,
      "Man United": true,
      "Arsenal": true,
      "Tottenham": true,
      "Liverpool": true,
      "West Ham": true,
      "Brighton": true,
      "Bournemouth": true,
      "Sheffield United": true,
      "Newcastle": true,
      "Banfield": true,
      "River Plate": true,
      "Platense": true,
      "Unión": true,
      "Talleres Córdoba": true,
      "Barracas Central": true,
      "Newell's": true,
      "Estudiantes": true,
      "Huracán": true,
      "Vélez Sársfield": true,
      "Independiente": true,
      "Instituto ACC": true,
      "Tigre": true,
      "San Lorenzo": true,
      "Atlético Tucumán": true,
      "Arsenal Sarandi": true,
      "Defensa y Justicia": true,
      "Central Córdoba": true,
      "Godoy Cruz": true,
      "Racing Club": true,
      "Colón": true,
      "Argentinos Juniors": true,
      "Sarmiento": true,
      "Belgrano": true,
      "Gimnasia": true,
      "Rosario Central": true,
      "Boca Juniors": true,
      "Lanús": true,
      "Lazio": true,
      "Monza": true,
      "Torino": true,
      "Roma": true,
      "Atalanta": true,
      "Cagliari": true,
      "Empoli": true,
      "Inter": true,
      "Lecce": true,
      "Genoa": true,
      "Salernitana": true,
      "Frosinone": true,
      "Udinese": true,
      "Fiorentina": true,
      "Sassuolo": true,
      "Juventus": true,
      "Bologna": true,
      "Napoli": true,
      "Milan": true,
      "Verona": true
    }
  },
  "410": {
    "gwNum": 410,
    "startDate": "Tue Sep 26 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Sep 29 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Twente": true,
      "Vitesse": true,
      "Ajax": true,
      "FC Volendam": true,
      "AZ Alkmaar": true,
      "Heracles": true,
      "Athletic Club": true,
      "Getafe": true,
      "Osasuna": true,
      "Atlético Madrid": true,
      "Sevilla": true,
      "Almería": true,
      "Celta Vigo": true,
      "Alavés": true,
      "Valencia": true,
      "Real Sociedad": true,
      "Granada": true,
      "Real Betis": true,
      "Real Madrid": true,
      "Las Palmas": true,
      "Mallorca": true,
      "Barcelona": true,
      "Cádiz": true,
      "Rayo Vallecano": true,
      "Villarreal": true,
      "Girona": true,
      "Napoli": true,
      "Udinese": true,
      "Inter": true,
      "Sassuolo": true,
      "Genoa": true,
      "Roma": true,
      "Cagliari": true,
      "Milan": true,
      "Frosinone": true,
      "Fiorentina": true,
      "Lazio": true,
      "Torino": true,
      "Monza": true,
      "Bologna": true,
      "Empoli": true,
      "Salernitana": true,
      "Juventus": true,
      "Lecce": true,
      "Verona": true,
      "Atalanta": true
    }
  },
  "411": {
    "gwNum": 411,
    "startDate": "Fri Sep 29 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Oct 03 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Genk": true,
      "Westerlo": true,
      "Union Saint-Gilloise": true,
      "Charleroi": true,
      "Club Brugge": true,
      "STVV": true,
      "Oud-Heverlee Leuven": true,
      "Standard": true,
      "RWDM": true,
      "Gent": true,
      "Eupen": true,
      "Anderlecht": true,
      "Mechelen": true,
      "Antwerp": true,
      "Kortrijk": true,
      "Cercle Brugge": true,
      "Hoffenheim": true,
      "Dortmund": true,
      "Köln": true,
      "VfB Stuttgart": true,
      "Heidenheim": true,
      "Union Berlin": true,
      "Wolfsburg": true,
      "Eintracht Frankfurt": true,
      "VfL Bochum": true,
      "Mönchengladbach": true,
      "Mainz": true,
      "Leverkusen": true,
      "RB Leipzig": true,
      "Bayern": true,
      "Darmstadt": true,
      "Werder": true,
      "SC Freiburg": true,
      "Augsburg": true,
      "Internacional": true,
      "Grêmio": true,
      "Vasco da Gama": true,
      "São Paulo": true,
      "Athletico-PR": true,
      "Bragantino": true,
      "Atlético Mineiro": true,
      "Coritiba": true,
      "Palmeiras": true,
      "Santos": true,
      "Goiás": true,
      "Bahia": true,
      "Fluminense": true,
      "Botafogo": true,
      "Cuiabá": true,
      "Cruzeiro": true,
      "Fortaleza": true,
      "América-MG": true,
      "Corinthians": true,
      "Flamengo": true,
      "Feyenoord": true,
      "Go Ahead Eagles": true,
      "Utrecht": true,
      "Almere": true,
      "PSV": true,
      "FC Volendam": true,
      "Twente": true,
      "Heerenveen": true,
      "RKC Waalwijk": true,
      "Ajax": true,
      "NEC": true,
      "Vitesse": true,
      "Heracles": true,
      "PEC Zwolle": true,
      "Excelsior": true,
      "Sparta Rotterdam": true,
      "AZ Alkmaar": true,
      "Fortuna Sittard": true,
      "FC Tokyo": true,
      "Gamba Osaka": true,
      "Urawa Reds": true,
      "Yokohama FC": true,
      "Avispa Fukuoka": true,
      "Kashima Antlers": true,
      "Yokohama FM": true,
      "Vissel Kobe": true,
      "Sanfrecce": true,
      "Nagoya": true,
      "Kawasaki": true,
      "Niigata": true,
      "Consadole Sapporo": true,
      "Kashiwa Reysol": true,
      "Cerezo Osaka": true,
      "Shonan Bellmare": true,
      "Sagan Tosu": true,
      "Kyoto Sanga": true,
      "Suwon FC": true,
      "FC Seoul": true,
      "Pohang Steelers": true,
      "Ulsan": true,
      "Jeonbuk": true,
      "Daegu": true,
      "Incheon": true,
      "Suwon Bluewings": true,
      "Gangwon": true,
      "Daejeon Hana": true,
      "Jeju": true,
      "Gwangju": true,
      "Espanyol": true,
      "Racing Ferrol": true,
      "Eldense": true,
      "Oviedo": true,
      "Zaragoza": true,
      "Mirandés": true,
      "Villarreal B": true,
      "Alcorcón": true,
      "Valladolid": true,
      "Burgos": true,
      "Leganes": true,
      "Racing": true,
      "Elche": true,
      "Levante": true,
      "Albacete": true,
      "Andorra": true,
      "Eibar": true,
      "Tenerife": true,
      "Huesca": true,
      "Sporting Gijón": true,
      "Amorebieta": true,
      "FC Cartagena": true,
      "Real Betis": true,
      "Valencia": true,
      "Getafe": true,
      "Villarreal": true,
      "Las Palmas": true,
      "Celta Vigo": true,
      "Real Sociedad": true,
      "Athletic Club": true,
      "Atlético Madrid": true,
      "Cádiz": true,
      "Barcelona": true,
      "Sevilla": true,
      "Rayo Vallecano": true,
      "Mallorca": true,
      "Alavés": true,
      "Osasuna": true,
      "Almería": true,
      "Granada": true,
      "Girona": true,
      "Real Madrid": true,
      "Vitória SC": true,
      "Estoril Praia": true,
      "Farense": true,
      "Sporting": true,
      "Estrela Amadora": true,
      "Braga": true,
      "Boavista": true,
      "Famalicão": true,
      "Benfica": true,
      "Porto": true,
      "Gil Vicente": true,
      "Casa Pia": true,
      "Rio Ave": true,
      "Moreirense": true,
      "Vizela": true,
      "Portimonense": true,
      "Arouca": true,
      "Chaves": true,
      "Reims": true,
      "Lyon": true,
      "Le Havre": true,
      "LOSC": true,
      "Toulouse": true,
      "Metz": true,
      "Lorient": true,
      "Montpellier": true,
      "Monaco": true,
      "Marseille": true,
      "Clermont Foot": true,
      "PSG": true,
      "Nice": true,
      "Brest": true,
      "Strasbourg": true,
      "Lens": true,
      "Rennes": true,
      "Nantes": true,
      "Inter Miami": true,
      "New York City": true,
      "NY Red Bulls": true,
      "Chicago": true,
      "New England": true,
      "Charlotte": true,
      "Orlando City": true,
      "Montréal": true,
      "Toronto": true,
      "Cincinnati": true,
      "Columbus": true,
      "Philadelphia": true,
      "Nashville": true,
      "Seattle Sounders": true,
      "St. Louis": true,
      "Sporting KC": true,
      "Minnesota": true,
      "San Jose": true,
      "Houston Dynamo": true,
      "FC Dallas": true,
      "Colorado": true,
      "Austin": true,
      "LA Galaxy": true,
      "Portland": true,
      "Vancouver": true,
      "D.C. United": true,
      "LAFC": true,
      "Real Salt Lake": true,
      "Aston Villa": true,
      "Brighton": true,
      "Man United": true,
      "Crystal Palace": true,
      "Newcastle": true,
      "Burnley": true,
      "Wolves": true,
      "Man City": true,
      "Bournemouth": true,
      "Arsenal": true,
      "West Ham": true,
      "Sheffield United": true,
      "Everton": true,
      "Luton Town": true,
      "Tottenham": true,
      "Liverpool": true,
      "Nottm Forest": true,
      "Brentford": true,
      "Fulham": true,
      "Chelsea": true,
      "Boca Juniors": true,
      "River Plate": true,
      "Rosario Central": true,
      "Newell's": true,
      "Racing Club": true,
      "Independiente": true,
      "Banfield": true,
      "Lanús": true,
      "Atlético Tucumán": true,
      "Central Córdoba": true,
      "Platense": true,
      "Argentinos Juniors": true,
      "Talleres Córdoba": true,
      "Belgrano": true,
      "Colón": true,
      "Unión": true,
      "Estudiantes": true,
      "Gimnasia": true,
      "Defensa y Justicia": true,
      "Arsenal Sarandi": true,
      "Instituto ACC": true,
      "Godoy Cruz": true,
      "Vélez Sársfield": true,
      "Tigre": true,
      "San Lorenzo": true,
      "Huracán": true,
      "Barracas Central": true,
      "Sarmiento": true,
      "Sassuolo": true,
      "Monza": true,
      "Milan": true,
      "Lazio": true,
      "Udinese": true,
      "Genoa": true,
      "Bologna": true,
      "Empoli": true,
      "Salernitana": true,
      "Inter": true,
      "Atalanta": true,
      "Juventus": true,
      "Torino": true,
      "Verona": true,
      "Fiorentina": true,
      "Cagliari": true,
      "Roma": true,
      "Frosinone": true,
      "Lecce": true,
      "Napoli": true
    }
  },
  "412": {
    "gwNum": 412,
    "startDate": "Tue Oct 03 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Oct 06 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Oviedo": true,
      "Huesca": true,
      "Sporting Gijón": true,
      "Elche": true,
      "Eldense": true,
      "Valladolid": true,
      "Mirandés": true,
      "Eibar": true,
      "Andorra": true,
      "Zaragoza": true,
      "Levante": true,
      "Villarreal B": true,
      "FC Cartagena": true,
      "Espanyol": true,
      "Alcorcón": true,
      "Albacete": true,
      "Burgos": true,
      "Leganes": true,
      "Racing Ferrol": true,
      "Amorebieta": true,
      "Tenerife": true,
      "Racing": true,
      "New England": true,
      "Columbus": true,
      "Montréal": true,
      "Houston Dynamo": true,
      "Charlotte": true,
      "Toronto": true,
      "Philadelphia": true,
      "Atlanta United": true,
      "Cincinnati": true,
      "NY Red Bulls": true,
      "Austin": true,
      "D.C. United": true,
      "Nashville": true,
      "Orlando City": true,
      "Chicago": true,
      "Inter Miami": true,
      "FC Dallas": true,
      "Colorado": true,
      "Seattle Sounders": true,
      "LA Galaxy": true,
      "Vancouver": true,
      "St. Louis": true,
      "LAFC": true,
      "Minnesota": true
    }
  },
  "413": {
    "gwNum": 413,
    "startDate": "Fri Oct 06 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Oct 10 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Anderlecht": true,
      "Mechelen": true,
      "Antwerp": true,
      "Eupen": true,
      "Charleroi": true,
      "RWDM": true,
      "STVV": true,
      "Union Saint-Gilloise": true,
      "Standard": true,
      "Club Brugge": true,
      "Cercle Brugge": true,
      "Oud-Heverlee Leuven": true,
      "Gent": true,
      "Genk": true,
      "Westerlo": true,
      "Kortrijk": true,
      "Mönchengladbach": true,
      "Mainz": true,
      "RB Leipzig": true,
      "VfL Bochum": true,
      "VfB Stuttgart": true,
      "Wolfsburg": true,
      "Dortmund": true,
      "Union Berlin": true,
      "Augsburg": true,
      "Darmstadt": true,
      "Werder": true,
      "Hoffenheim": true,
      "Leverkusen": true,
      "Köln": true,
      "Bayern": true,
      "SC Freiburg": true,
      "Eintracht Frankfurt": true,
      "Heidenheim": true,
      "Goiás": true,
      "São Paulo": true,
      "Palmeiras": true,
      "Atlético Mineiro": true,
      "Fluminense": true,
      "Corinthians": true,
      "Bahia": true,
      "Internacional": true,
      "Cruzeiro": true,
      "Flamengo": true,
      "Vasco da Gama": true,
      "Fortaleza": true,
      "Grêmio": true,
      "Athletico-PR": true,
      "América-MG": true,
      "Botafogo": true,
      "Santos": true,
      "Bragantino": true,
      "Coritiba": true,
      "Cuiabá": true,
      "Sarpsborg": true,
      "Rosenborg": true,
      "Molde": true,
      "Bodø/Glimt": true,
      "Haugesund": true,
      "Strømsgodset": true,
      "Tromso": true,
      "Aalesund": true,
      "Sandefjord": true,
      "HamKam": true,
      "Stabæk": true,
      "Lillestrøm": true,
      "Vålerenga": true,
      "Brann": true,
      "Odd": true,
      "Viking": true,
      "FC Volendam": true,
      "Utrecht": true,
      "Heerenveen": true,
      "NEC": true,
      "Almere": true,
      "RKC Waalwijk": true,
      "Go Ahead Eagles": true,
      "Heracles": true,
      "Vitesse": true,
      "Excelsior": true,
      "PEC Zwolle": true,
      "Feyenoord": true,
      "Ajax": true,
      "AZ Alkmaar": true,
      "Fortuna Sittard": true,
      "Twente": true,
      "Sparta Rotterdam": true,
      "PSV": true,
      "Daejeon Hana": true,
      "Jeju": true,
      "Ulsan": true,
      "Incheon": true,
      "FC Seoul": true,
      "Jeonbuk": true,
      "Daegu": true,
      "Suwon FC": true,
      "Gwangju": true,
      "Gangwon": true,
      "Suwon Bluewings": true,
      "Pohang Steelers": true,
      "Albacete": true,
      "Levante": true,
      "Valladolid": true,
      "Mirandés": true,
      "Racing": true,
      "Sporting Gijón": true,
      "Villarreal B": true,
      "Espanyol": true,
      "Zaragoza": true,
      "Alcorcón": true,
      "Amorebieta": true,
      "Eibar": true,
      "Elche": true,
      "Andorra": true,
      "Leganes": true,
      "Oviedo": true,
      "Racing Ferrol": true,
      "FC Cartagena": true,
      "Tenerife": true,
      "Burgos": true,
      "Huesca": true,
      "Eldense": true,
      "Granada": true,
      "Barcelona": true,
      "Cádiz": true,
      "Girona": true,
      "Athletic Club": true,
      "Almería": true,
      "Alavés": true,
      "Real Betis": true,
      "Atlético Madrid": true,
      "Real Sociedad": true,
      "Celta Vigo": true,
      "Getafe": true,
      "Villarreal": true,
      "Las Palmas": true,
      "Real Madrid": true,
      "Osasuna": true,
      "Mallorca": true,
      "Valencia": true,
      "Sevilla": true,
      "Rayo Vallecano": true,
      "Sporting": true,
      "Arouca": true,
      "Chaves": true,
      "Gil Vicente": true,
      "Braga": true,
      "Rio Ave": true,
      "Farense": true,
      "Vizela": true,
      "Casa Pia": true,
      "Estrela Amadora": true,
      "Estoril Praia": true,
      "Benfica": true,
      "Moreirense": true,
      "Boavista": true,
      "Porto": true,
      "Portimonense": true,
      "Famalicão": true,
      "Vitória SC": true,
      "Lyon": true,
      "Lorient": true,
      "Marseille": true,
      "Le Havre": true,
      "Reims": true,
      "Monaco": true,
      "Lens": true,
      "LOSC": true,
      "Rennes": true,
      "PSG": true,
      "Brest": true,
      "Toulouse": true,
      "Montpellier": true,
      "Clermont Foot": true,
      "Metz": true,
      "Nice": true,
      "Strasbourg": true,
      "Nantes": true,
      "Montréal": true,
      "Portland": true,
      "Inter Miami": true,
      "Cincinnati": true,
      "Orlando City": true,
      "New England": true,
      "Atlanta United": true,
      "Columbus": true,
      "NY Red Bulls": true,
      "Toronto": true,
      "Philadelphia": true,
      "Nashville": true,
      "D.C. United": true,
      "New York City": true,
      "Houston Dynamo": true,
      "Colorado": true,
      "Austin": true,
      "LAFC": true,
      "Chicago": true,
      "Charlotte": true,
      "Minnesota": true,
      "LA Galaxy": true,
      "FC Dallas": true,
      "San Jose": true,
      "Real Salt Lake": true,
      "Sporting KC": true,
      "Seattle Sounders": true,
      "Vancouver": true,
      "Arsenal": true,
      "Man City": true,
      "Fulham": true,
      "Sheffield United": true,
      "Crystal Palace": true,
      "Nottm Forest": true,
      "Brighton": true,
      "Liverpool": true,
      "Luton Town": true,
      "Tottenham": true,
      "West Ham": true,
      "Newcastle": true,
      "Burnley": true,
      "Chelsea": true,
      "Man United": true,
      "Brentford": true,
      "Wolves": true,
      "Aston Villa": true,
      "Everton": true,
      "Bournemouth": true,
      "Central Córdoba": true,
      "Tigre": true,
      "Barracas Central": true,
      "Colón": true,
      "Lanús": true,
      "Defensa y Justicia": true,
      "San Lorenzo": true,
      "Newell's": true,
      "Estudiantes": true,
      "Godoy Cruz": true,
      "Arsenal Sarandi": true,
      "Banfield": true,
      "Argentinos Juniors": true,
      "Independiente": true,
      "Rosario Central": true,
      "Huracán": true,
      "Instituto ACC": true,
      "Gimnasia": true,
      "Belgrano": true,
      "Boca Juniors": true,
      "Vélez Sársfield": true,
      "Atlético Tucumán": true,
      "River Plate": true,
      "Talleres Córdoba": true,
      "Racing Club": true,
      "Platense": true,
      "Unión": true,
      "Sarmiento": true,
      "Empoli": true,
      "Udinese": true,
      "Genoa": true,
      "Milan": true,
      "Juventus": true,
      "Torino": true,
      "Inter": true,
      "Bologna": true,
      "Lazio": true,
      "Atalanta": true,
      "Frosinone": true,
      "Verona": true,
      "Cagliari": true,
      "Roma": true,
      "Lecce": true,
      "Sassuolo": true,
      "Monza": true,
      "Salernitana": true,
      "Napoli": true,
      "Fiorentina": true
    }
  },
  "414": {
    "gwNum": 414,
    "startDate": "Tue Oct 10 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Oct 13 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false
  },
  "415": {
    "gwNum": 415,
    "startDate": "Fri Oct 13 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Oct 17 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Eldense": true,
      "Elche": true,
      "Eibar": true,
      "Huesca": true,
      "Oviedo": true,
      "Albacete": true,
      "Levante": true,
      "Racing Ferrol": true,
      "Andorra": true,
      "Alcorcón": true,
      "Mirandés": true,
      "Tenerife": true,
      "FC Cartagena": true,
      "Racing": true,
      "Leganes": true,
      "Amorebieta": true,
      "Espanyol": true,
      "Valladolid": true,
      "Sporting Gijón": true,
      "Zaragoza": true,
      "Burgos": true,
      "Villarreal B": true,
      "Huracán": true,
      "Instituto ACC": true,
      "Defensa y Justicia": true,
      "Belgrano": true,
      "Platense": true,
      "Estudiantes": true,
      "Boca Juniors": true,
      "Unión": true,
      "Rosario Central": true,
      "Vélez Sársfield": true,
      "Colón": true,
      "River Plate": true,
      "Banfield": true,
      "Atlético Tucumán": true,
      "Gimnasia": true,
      "Argentinos Juniors": true,
      "Independiente": true,
      "Barracas Central": true,
      "Talleres Córdoba": true,
      "Arsenal Sarandi": true,
      "Godoy Cruz": true,
      "San Lorenzo": true,
      "Sarmiento": true,
      "Racing Club": true,
      "Tigre": true,
      "Newell's": true,
      "Central Córdoba": true,
      "Lanús": true
    }
  },
  "416": {
    "gwNum": 416,
    "startDate": "Tue Oct 17 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Oct 20 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Botafogo": true,
      "Athletico-PR": true,
      "Cuiabá": true,
      "Goiás": true,
      "Bragantino": true,
      "Fluminense": true,
      "São Paulo": true,
      "Grêmio": true,
      "Atlético Mineiro": true,
      "Cruzeiro": true,
      "Flamengo": true,
      "Vasco da Gama": true,
      "Coritiba": true,
      "Palmeiras": true,
      "Bahia": true,
      "Fortaleza": true,
      "Internacional": true,
      "Santos": true,
      "Corinthians": true,
      "América-MG": true
    }
  },
  "417": {
    "gwNum": 417,
    "startDate": "Fri Oct 20 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Oct 24 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "RWDM": true,
      "Westerlo": true,
      "Charleroi": true,
      "Antwerp": true,
      "Genk": true,
      "Mechelen": true,
      "Kortrijk": true,
      "Club Brugge": true,
      "Oud-Heverlee Leuven": true,
      "STVV": true,
      "Union Saint-Gilloise": true,
      "Eupen": true,
      "Standard": true,
      "Anderlecht": true,
      "Cercle Brugge": true,
      "Gent": true,
      "Dortmund": true,
      "Werder": true,
      "Wolfsburg": true,
      "Leverkusen": true,
      "Darmstadt": true,
      "RB Leipzig": true,
      "Union Berlin": true,
      "VfB Stuttgart": true,
      "Hoffenheim": true,
      "Eintracht Frankfurt": true,
      "SC Freiburg": true,
      "VfL Bochum": true,
      "Mainz": true,
      "Bayern": true,
      "Köln": true,
      "Mönchengladbach": true,
      "Heidenheim": true,
      "Augsburg": true,
      "Cruzeiro": true,
      "Bahia": true,
      "Bragantino": true,
      "Atlético Mineiro": true,
      "Athletico-PR": true,
      "América-MG": true,
      "Vasco da Gama": true,
      "Internacional": true,
      "Fortaleza": true,
      "Botafogo": true,
      "Grêmio": true,
      "Flamengo": true,
      "Cuiabá": true,
      "Corinthians": true,
      "Fluminense": true,
      "Goiás": true,
      "Palmeiras": true,
      "São Paulo": true,
      "Santos": true,
      "Coritiba": true,
      "Strømsgodset": true,
      "Odd": true,
      "Bodø/Glimt": true,
      "Sandefjord": true,
      "Brann": true,
      "Molde": true,
      "Lillestrøm": true,
      "Vålerenga": true,
      "Rosenborg": true,
      "Stabæk": true,
      "Viking": true,
      "Tromso": true,
      "HamKam": true,
      "Haugesund": true,
      "Aalesund": true,
      "Sarpsborg": true,
      "Feyenoord": true,
      "Vitesse": true,
      "RKC Waalwijk": true,
      "FC Volendam": true,
      "PSV": true,
      "Fortuna Sittard": true,
      "AZ Alkmaar": true,
      "Heerenveen": true,
      "NEC": true,
      "Almere": true,
      "Utrecht": true,
      "Ajax": true,
      "Excelsior": true,
      "PEC Zwolle": true,
      "Heracles": true,
      "Twente": true,
      "Go Ahead Eagles": true,
      "Sparta Rotterdam": true,
      "Sanfrecce": true,
      "Cerezo Osaka": true,
      "Yokohama FC": true,
      "FC Tokyo": true,
      "Kawasaki": true,
      "Avispa Fukuoka": true,
      "Yokohama FM": true,
      "Consadole Sapporo": true,
      "Gamba Osaka": true,
      "Nagoya": true,
      "Vissel Kobe": true,
      "Kashima Antlers": true,
      "Urawa Reds": true,
      "Kashiwa Reysol": true,
      "Kyoto Sanga": true,
      "Shonan Bellmare": true,
      "Niigata": true,
      "Sagan Tosu": true,
      "Valladolid": true,
      "Andorra": true,
      "Albacete": true,
      "Sporting Gijón": true,
      "Amorebieta": true,
      "Oviedo": true,
      "Racing": true,
      "Burgos": true,
      "Tenerife": true,
      "Levante": true,
      "Racing Ferrol": true,
      "Eldense": true,
      "Espanyol": true,
      "Leganes": true,
      "Alcorcón": true,
      "FC Cartagena": true,
      "Villarreal B": true,
      "Mirandés": true,
      "Huesca": true,
      "Elche": true,
      "Zaragoza": true,
      "Eibar": true,
      "Real Sociedad": true,
      "Mallorca": true,
      "Girona": true,
      "Almería": true,
      "Las Palmas": true,
      "Rayo Vallecano": true,
      "Barcelona": true,
      "Athletic Club": true,
      "Sevilla": true,
      "Real Madrid": true,
      "Celta Vigo": true,
      "Atlético Madrid": true,
      "Getafe": true,
      "Real Betis": true,
      "Valencia": true,
      "Cádiz": true,
      "Villarreal": true,
      "Alavés": true,
      "Osasuna": true,
      "Granada": true,
      "Le Havre": true,
      "Lens": true,
      "Monaco": true,
      "Metz": true,
      "Lorient": true,
      "Rennes": true,
      "PSG": true,
      "Strasbourg": true,
      "LOSC": true,
      "Brest": true,
      "Toulouse": true,
      "Reims": true,
      "Nantes": true,
      "Montpellier": true,
      "Nice": true,
      "Marseille": true,
      "Lyon": true,
      "Clermont Foot": true,
      "New England": true,
      "Philadelphia": true,
      "New York City": true,
      "Chicago": true,
      "Cincinnati": true,
      "Atlanta United": true,
      "Nashville": true,
      "NY Red Bulls": true,
      "Columbus": true,
      "Montréal": true,
      "Charlotte": true,
      "Inter Miami": true,
      "Toronto": true,
      "Orlando City": true,
      "San Jose": true,
      "Austin": true,
      "Portland": true,
      "Houston Dynamo": true,
      "Vancouver": true,
      "LAFC": true,
      "St. Louis": true,
      "Seattle Sounders": true,
      "Colorado": true,
      "Real Salt Lake": true,
      "Sporting KC": true,
      "Minnesota": true,
      "LA Galaxy": true,
      "FC Dallas": true,
      "Liverpool": true,
      "Everton": true,
      "Aston Villa": true,
      "West Ham": true,
      "Tottenham": true,
      "Fulham": true,
      "Brentford": true,
      "Burnley": true,
      "Bournemouth": true,
      "Wolves": true,
      "Nottm Forest": true,
      "Luton Town": true,
      "Sheffield United": true,
      "Man United": true,
      "Man City": true,
      "Brighton": true,
      "Chelsea": true,
      "Arsenal": true,
      "Newcastle": true,
      "Crystal Palace": true,
      "Racing Club": true,
      "Boca Juniors": true,
      "River Plate": true,
      "Independiente": true,
      "Newell's": true,
      "Godoy Cruz": true,
      "Estudiantes": true,
      "Sarmiento": true,
      "Belgrano": true,
      "Central Córdoba": true,
      "Instituto ACC": true,
      "Rosario Central": true,
      "Argentinos Juniors": true,
      "Huracán": true,
      "Arsenal Sarandi": true,
      "Colón": true,
      "Lanús": true,
      "Tigre": true,
      "Vélez Sársfield": true,
      "Banfield": true,
      "Atlético Tucumán": true,
      "Talleres Córdoba": true,
      "Barracas Central": true,
      "Gimnasia": true,
      "Unión": true,
      "Defensa y Justicia": true,
      "San Lorenzo": true,
      "Platense": true,
      "Roma": true,
      "Monza": true,
      "Salernitana": true,
      "Cagliari": true,
      "Udinese": true,
      "Lecce": true,
      "Bologna": true,
      "Frosinone": true,
      "Fiorentina": true,
      "Empoli": true,
      "Atalanta": true,
      "Genoa": true,
      "Torino": true,
      "Inter": true,
      "Sassuolo": true,
      "Lazio": true,
      "Milan": true,
      "Juventus": true,
      "Verona": true,
      "Napoli": true
    }
  },
  "418": {
    "gwNum": 418,
    "startDate": "Tue Oct 24 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Fri Oct 27 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Athletico-PR": true,
      "São Paulo": true,
      "Flamengo": true,
      "Bragantino": true,
      "Fortaleza": true,
      "Cruzeiro": true,
      "Internacional": true,
      "Coritiba": true,
      "Botafogo": true,
      "Cuiabá": true,
      "Corinthians": true,
      "Santos": true,
      "Palmeiras": true,
      "Bahia": true,
      "América-MG": true,
      "Grêmio": true,
      "Atlético Mineiro": true,
      "Fluminense": true,
      "Goiás": true,
      "Vasco da Gama": true
    }
  },
  "419": {
    "gwNum": 419,
    "startDate": "Fri Oct 27 2023 16:00:00 GMT+0200 (Central European Summer Time)",
    "endDate": "Tue Oct 31 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Gent": true,
      "Standard": true,
      "Anderlecht": true,
      "Oud-Heverlee Leuven": true,
      "Westerlo": true,
      "Union Saint-Gilloise": true,
      "Club Brugge": true,
      "Antwerp": true,
      "STVV": true,
      "RWDM": true,
      "Eupen": true,
      "Charleroi": true,
      "Kortrijk": true,
      "Genk": true,
      "Mechelen": true,
      "Cercle Brugge": true,
      "Augsburg": true,
      "Wolfsburg": true,
      "Werder": true,
      "Union Berlin": true,
      "Bayern": true,
      "Darmstadt": true,
      "Eintracht Frankfurt": true,
      "Dortmund": true,
      "RB Leipzig": true,
      "Köln": true,
      "Leverkusen": true,
      "SC Freiburg": true,
      "Mönchengladbach": true,
      "Heidenheim": true,
      "VfB Stuttgart": true,
      "Hoffenheim": true,
      "VfL Bochum": true,
      "Mainz": true,
      "Internacional": true,
      "América-MG": true,
      "Goiás": true,
      "Bragantino": true,
      "Corinthians": true,
      "Athletico-PR": true,
      "Atlético Mineiro": true,
      "Fortaleza": true,
      "Flamengo": true,
      "Santos": true,
      "Coritiba": true,
      "Grêmio": true,
      "Bahia": true,
      "Fluminense": true,
      "Cuiabá": true,
      "Vasco da Gama": true,
      "São Paulo": true,
      "Cruzeiro": true,
      "Botafogo": true,
      "Palmeiras": true,
      "Strømsgodset": true,
      "Viking": true,
      "Odd": true,
      "HamKam": true,
      "Haugesund": true,
      "Aalesund": true,
      "Vålerenga": true,
      "Rosenborg": true,
      "Bodø/Glimt": true,
      "Lillestrøm": true,
      "Molde": true,
      "Tromso": true,
      "Sarpsborg": true,
      "Stabæk": true,
      "Sandefjord": true,
      "Brann": true,
      "Vitesse": true,
      "PEC Zwolle": true,
      "Heerenveen": true,
      "Heracles": true,
      "Almere": true,
      "Go Ahead Eagles": true,
      "Sparta Rotterdam": true,
      "RKC Waalwijk": true,
      "Fortuna Sittard": true,
      "Utrecht": true,
      "Twente": true,
      "Feyenoord": true,
      "PSV": true,
      "Ajax": true,
      "FC Volendam": true,
      "Excelsior": true,
      "AZ Alkmaar": true,
      "NEC": true,
      "Nagoya": true,
      "Sagan Tosu": true,
      "Cerezo Osaka": true,
      "Gamba Osaka": true,
      "Kyoto Sanga": true,
      "Niigata": true,
      "Shonan Bellmare": true,
      "Vissel Kobe": true,
      "Avispa Fukuoka": true,
      "Yokohama FM": true,
      "Kashiwa Reysol": true,
      "Kawasaki": true,
      "FC Tokyo": true,
      "Sanfrecce": true,
      "Consadole Sapporo": true,
      "Yokohama FC": true,
      "Kashima Antlers": true,
      "Urawa Reds": true,
      "Elche": true,
      "Tenerife": true,
      "Huesca": true,
      "Albacete": true,
      "Sporting Gijón": true,
      "Espanyol": true,
      "Leganes": true,
      "Villarreal B": true,
      "Racing": true,
      "Racing Ferrol": true,
      "Oviedo": true,
      "Alcorcón": true,
      "Mirandés": true,
      "FC Cartagena": true,
      "Eibar": true,
      "Valladolid": true,
      "Andorra": true,
      "Levante": true,
      "Eldense": true,
      "Amorebieta": true,
      "Burgos": true,
      "Zaragoza": true,
      "Granada": true,
      "Villarreal": true,
      "Rayo Vallecano": true,
      "Real Sociedad": true,
      "Real Betis": true,
      "Osasuna": true,
      "Cádiz": true,
      "Sevilla": true,
      "Barcelona": true,
      "Real Madrid": true,
      "Atlético Madrid": true,
      "Alavés": true,
      "Mallorca": true,
      "Getafe": true,
      "Almería": true,
      "Las Palmas": true,
      "Girona": true,
      "Celta Vigo": true,
      "Athletic Club": true,
      "Valencia": true,
      "Benfica": true,
      "Casa Pia": true,
      "Arouca": true,
      "Moreirense": true,
      "Boavista": true,
      "Sporting": true,
      "Gil Vicente": true,
      "Braga": true,
      "Vitória SC": true,
      "Chaves": true,
      "Vizela": true,
      "Porto": true,
      "Portimonense": true,
      "Estoril Praia": true,
      "Rio Ave": true,
      "Farense": true,
      "Estrela Amadora": true,
      "Famalicão": true,
      "Brest": true,
      "PSG": true,
      "LOSC": true,
      "Monaco": true,
      "Rennes": true,
      "Strasbourg": true,
      "Marseille": true,
      "Lyon": true,
      "Montpellier": true,
      "Toulouse": true,
      "Lens": true,
      "Nantes": true,
      "Reims": true,
      "Lorient": true,
      "Metz": true,
      "Le Havre": true,
      "Clermont Foot": true,
      "Nice": true,
      "Arsenal": true,
      "Sheffield United": true,
      "Brighton": true,
      "Fulham": true,
      "West Ham": true,
      "Everton": true,
      "Man United": true,
      "Man City": true,
      "Crystal Palace": true,
      "Tottenham": true,
      "Aston Villa": true,
      "Luton Town": true,
      "Chelsea": true,
      "Brentford": true,
      "Bournemouth": true,
      "Burnley": true,
      "Liverpool": true,
      "Nottm Forest": true,
      "Wolves": true,
      "Newcastle": true,
      "Lanús": true,
      "Belgrano": true,
      "Tigre": true,
      "Godoy Cruz": true,
      "Colón": true,
      "Atlético Tucumán": true,
      "Central Córdoba": true,
      "Unión": true,
      "Talleres Córdoba": true,
      "Banfield": true,
      "Defensa y Justicia": true,
      "Racing Club": true,
      "Sarmiento": true,
      "San Lorenzo": true,
      "Huracán": true,
      "Barracas Central": true,
      "Independiente": true,
      "Arsenal Sarandi": true,
      "Gimnasia": true,
      "River Plate": true,
      "Instituto ACC": true,
      "Vélez Sársfield": true,
      "Rosario Central": true,
      "Argentinos Juniors": true,
      "Boca Juniors": true,
      "Estudiantes": true,
      "Platense": true,
      "Newell's": true,
      "Lazio": true,
      "Fiorentina": true,
      "Lecce": true,
      "Torino": true,
      "Inter": true,
      "Roma": true,
      "Juventus": true,
      "Verona": true,
      "Napoli": true,
      "Milan": true,
      "Sassuolo": true,
      "Bologna": true,
      "Cagliari": true,
      "Frosinone": true,
      "Monza": true,
      "Udinese": true,
      "Genoa": true,
      "Salernitana": true,
      "Empoli": true,
      "Atalanta": true
    }
  },
  "420": {
    "gwNum": 420,
    "startDate": "Tue Oct 31 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Nov 03 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Grêmio": true,
      "Bahia": true,
      "América-MG": true,
      "Atlético Mineiro": true,
      "Santos": true,
      "Cuiabá": true,
      "Cruzeiro": true,
      "Internacional": true,
      "Fluminense": true,
      "São Paulo": true,
      "Bragantino": true,
      "Corinthians": true,
      "Fortaleza": true,
      "Flamengo": true,
      "Vasco da Gama": true,
      "Botafogo": true,
      "Coritiba": true,
      "Goiás": true,
      "Palmeiras": true,
      "Athletico-PR": true
    }
  },
  "421": {
    "gwNum": 421,
    "startDate": "Fri Nov 03 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Nov 07 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Antwerp": true,
      "Genk": true,
      "Standard": true,
      "Mechelen": true,
      "STVV": true,
      "Eupen": true,
      "Charleroi": true,
      "Gent": true,
      "Cercle Brugge": true,
      "Anderlecht": true,
      "RWDM": true,
      "Kortrijk": true,
      "Oud-Heverlee Leuven": true,
      "Westerlo": true,
      "Union Saint-Gilloise": true,
      "Club Brugge": true,
      "Union Berlin": true,
      "Eintracht Frankfurt": true,
      "SC Freiburg": true,
      "Mönchengladbach": true,
      "Hoffenheim": true,
      "Leverkusen": true,
      "Heidenheim": true,
      "VfB Stuttgart": true,
      "Darmstadt": true,
      "VfL Bochum": true,
      "Wolfsburg": true,
      "Werder": true,
      "Mainz": true,
      "RB Leipzig": true,
      "Köln": true,
      "Augsburg": true,
      "Dortmund": true,
      "Bayern": true,
      "Bahia": true,
      "Cuiabá": true,
      "Flamengo": true,
      "Palmeiras": true,
      "Cruzeiro": true,
      "Vasco da Gama": true,
      "Internacional": true,
      "Fluminense": true,
      "América-MG": true,
      "Coritiba": true,
      "Goiás": true,
      "Santos": true,
      "Athletico-PR": true,
      "Fortaleza": true,
      "Corinthians": true,
      "Atlético Mineiro": true,
      "Botafogo": true,
      "Grêmio": true,
      "São Paulo": true,
      "Bragantino": true,
      "HamKam": true,
      "Viking": true,
      "Tromso": true,
      "Strømsgodset": true,
      "Aalesund": true,
      "Sandefjord": true,
      "Sarpsborg": true,
      "Vålerenga": true,
      "Stabæk": true,
      "Bodø/Glimt": true,
      "Lillestrøm": true,
      "Haugesund": true,
      "Brann": true,
      "Odd": true,
      "Rosenborg": true,
      "Molde": true,
      "Ajax": true,
      "Heerenveen": true,
      "RKC Waalwijk": true,
      "Feyenoord": true,
      "Excelsior": true,
      "AZ Alkmaar": true,
      "Heracles": true,
      "PSV": true,
      "Go Ahead Eagles": true,
      "Vitesse": true,
      "Utrecht": true,
      "Twente": true,
      "NEC": true,
      "FC Volendam": true,
      "PEC Zwolle": true,
      "Fortuna Sittard": true,
      "Sparta Rotterdam": true,
      "Almere": true,
      "FC Cartagena": true,
      "Leganes": true,
      "Levante": true,
      "Mirandés": true,
      "Eldense": true,
      "Burgos": true,
      "Alcorcón": true,
      "Racing": true,
      "Zaragoza": true,
      "Oviedo": true,
      "Espanyol": true,
      "Eibar": true,
      "Amorebieta": true,
      "Huesca": true,
      "Villarreal B": true,
      "Sporting Gijón": true,
      "Albacete": true,
      "Elche": true,
      "Valladolid": true,
      "Tenerife": true,
      "Racing Ferrol": true,
      "Andorra": true,
      "Real Sociedad": true,
      "Barcelona": true,
      "Real Betis": true,
      "Mallorca": true,
      "Alavés": true,
      "Almería": true,
      "Osasuna": true,
      "Girona": true,
      "Real Madrid": true,
      "Rayo Vallecano": true,
      "Villarreal": true,
      "Athletic Club": true,
      "Getafe": true,
      "Cádiz": true,
      "Las Palmas": true,
      "Atlético Madrid": true,
      "Celta Vigo": true,
      "Sevilla": true,
      "Valencia": true,
      "Granada": true,
      "Moreirense": true,
      "Vitória SC": true,
      "Braga": true,
      "Portimonense": true,
      "Famalicão": true,
      "Gil Vicente": true,
      "Porto": true,
      "Estoril Praia": true,
      "Farense": true,
      "Arouca": true,
      "Casa Pia": true,
      "Vizela": true,
      "Rio Ave": true,
      "Boavista": true,
      "Sporting": true,
      "Estrela Amadora": true,
      "Chaves": true,
      "Benfica": true,
      "Lorient": true,
      "Lens": true,
      "Toulouse": true,
      "Le Havre": true,
      "Nantes": true,
      "Reims": true,
      "Lyon": true,
      "Metz": true,
      "PSG": true,
      "Montpellier": true,
      "Marseille": true,
      "LOSC": true,
      "Monaco": true,
      "Brest": true,
      "Nice": true,
      "Rennes": true,
      "Strasbourg": true,
      "Clermont Foot": true,
      "Nottm Forest": true,
      "Aston Villa": true,
      "Brentford": true,
      "West Ham": true,
      "Man City": true,
      "Bournemouth": true,
      "Sheffield United": true,
      "Wolves": true,
      "Burnley": true,
      "Crystal Palace": true,
      "Everton": true,
      "Brighton": true,
      "Luton Town": true,
      "Liverpool": true,
      "Fulham": true,
      "Man United": true,
      "Tottenham": true,
      "Chelsea": true,
      "Newcastle": true,
      "Arsenal": true,
      "Estudiantes": true,
      "Defensa y Justicia": true,
      "Unión": true,
      "Lanús": true,
      "River Plate": true,
      "Huracán": true,
      "Belgrano": true,
      "Tigre": true,
      "Arsenal Sarandi": true,
      "Gimnasia": true,
      "Atlético Tucumán": true,
      "Independiente": true,
      "Newell's": true,
      "Sarmiento": true,
      "Racing Club": true,
      "Central Córdoba": true,
      "Vélez Sársfield": true,
      "Talleres Córdoba": true,
      "Godoy Cruz": true,
      "Platense": true,
      "Banfield": true,
      "Colón": true,
      "Argentinos Juniors": true,
      "Instituto ACC": true,
      "Barracas Central": true,
      "Rosario Central": true,
      "San Lorenzo": true,
      "Boca Juniors": true,
      "Roma": true,
      "Lecce": true,
      "Verona": true,
      "Monza": true,
      "Salernitana": true,
      "Napoli": true,
      "Frosinone": true,
      "Empoli": true,
      "Cagliari": true,
      "Genoa": true,
      "Bologna": true,
      "Lazio": true,
      "Fiorentina": true,
      "Juventus": true,
      "Milan": true,
      "Udinese": true,
      "Atalanta": true,
      "Inter": true,
      "Torino": true,
      "Sassuolo": true
    }
  },
  "422": {
    "gwNum": 422,
    "startDate": "Tue Nov 07 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Nov 10 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false
  },
  "423": {
    "gwNum": 423,
    "startDate": "Fri Nov 10 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Nov 14 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Gent": true,
      "Anderlecht": true,
      "Westerlo": true,
      "STVV": true,
      "Genk": true,
      "Oud-Heverlee Leuven": true,
      "Mechelen": true,
      "Charleroi": true,
      "Union Saint-Gilloise": true,
      "Kortrijk": true,
      "Eupen": true,
      "RWDM": true,
      "Club Brugge": true,
      "Cercle Brugge": true,
      "Antwerp": true,
      "Standard": true,
      "Mönchengladbach": true,
      "Wolfsburg": true,
      "RB Leipzig": true,
      "SC Freiburg": true,
      "Leverkusen": true,
      "Union Berlin": true,
      "Darmstadt": true,
      "Mainz": true,
      "VfL Bochum": true,
      "Köln": true,
      "Werder": true,
      "Eintracht Frankfurt": true,
      "Augsburg": true,
      "Hoffenheim": true,
      "Bayern": true,
      "Heidenheim": true,
      "VfB Stuttgart": true,
      "Dortmund": true,
      "Santos": true,
      "São Paulo": true,
      "Vasco da Gama": true,
      "América-MG": true,
      "Flamengo": true,
      "Fluminense": true,
      "Coritiba": true,
      "Cruzeiro": true,
      "Atlético Mineiro": true,
      "Goiás": true,
      "Bragantino": true,
      "Botafogo": true,
      "Palmeiras": true,
      "Internacional": true,
      "Grêmio": true,
      "Corinthians": true,
      "Bahia": true,
      "Athletico-PR": true,
      "Cuiabá": true,
      "Fortaleza": true,
      "Haugesund": true,
      "Brann": true,
      "Molde": true,
      "Lillestrøm": true,
      "Bodø/Glimt": true,
      "Aalesund": true,
      "Viking": true,
      "Sarpsborg": true,
      "Vålerenga": true,
      "Stabæk": true,
      "Sandefjord": true,
      "Rosenborg": true,
      "Odd": true,
      "Tromso": true,
      "Strømsgodset": true,
      "HamKam": true,
      "Fortuna Sittard": true,
      "Heracles": true,
      "PSV": true,
      "PEC Zwolle": true,
      "RKC Waalwijk": true,
      "Go Ahead Eagles": true,
      "Vitesse": true,
      "Heerenveen": true,
      "FC Volendam": true,
      "Sparta Rotterdam": true,
      "Almere": true,
      "Ajax": true,
      "Twente": true,
      "NEC": true,
      "Feyenoord": true,
      "AZ Alkmaar": true,
      "Utrecht": true,
      "Excelsior": true,
      "Consadole Sapporo": true,
      "Sanfrecce": true,
      "Kashima Antlers": true,
      "Kashiwa Reysol": true,
      "Shonan Bellmare": true,
      "Nagoya": true,
      "Gamba Osaka": true,
      "Avispa Fukuoka": true,
      "Yokohama FM": true,
      "Cerezo Osaka": true,
      "Niigata": true,
      "FC Tokyo": true,
      "Sagan Tosu": true,
      "Yokohama FC": true,
      "Kawasaki": true,
      "Kyoto Sanga": true,
      "Urawa Reds": true,
      "Vissel Kobe": true,
      "Mirandés": true,
      "Racing Ferrol": true,
      "Oviedo": true,
      "FC Cartagena": true,
      "Elche": true,
      "Zaragoza": true,
      "Burgos": true,
      "Alcorcón": true,
      "Eibar": true,
      "Albacete": true,
      "Huesca": true,
      "Espanyol": true,
      "Leganes": true,
      "Levante": true,
      "Sporting Gijón": true,
      "Amorebieta": true,
      "Racing": true,
      "Valladolid": true,
      "Andorra": true,
      "Eldense": true,
      "Tenerife": true,
      "Villarreal B": true,
      "Almería": true,
      "Real Sociedad": true,
      "Mallorca": true,
      "Cádiz": true,
      "Real Madrid": true,
      "Valencia": true,
      "Osasuna": true,
      "Las Palmas": true,
      "Atlético Madrid": true,
      "Villarreal": true,
      "Barcelona": true,
      "Alavés": true,
      "Athletic Club": true,
      "Celta Vigo": true,
      "Sevilla": true,
      "Real Betis": true,
      "Rayo Vallecano": true,
      "Girona": true,
      "Granada": true,
      "Getafe": true,
      "Estrela Amadora": true,
      "Moreirense": true,
      "Portimonense": true,
      "Chaves": true,
      "Benfica": true,
      "Sporting": true,
      "Boavista": true,
      "Farense": true,
      "Arouca": true,
      "Braga": true,
      "Vizela": true,
      "Famalicão": true,
      "Vitória SC": true,
      "Porto": true,
      "Gil Vicente": true,
      "Rio Ave": true,
      "Estoril Praia": true,
      "Casa Pia": true,
      "Reims": true,
      "PSG": true,
      "Brest": true,
      "Strasbourg": true,
      "Clermont Foot": true,
      "Lorient": true,
      "Montpellier": true,
      "Nice": true,
      "Le Havre": true,
      "Monaco": true,
      "Metz": true,
      "Nantes": true,
      "Rennes": true,
      "Lyon": true,
      "Lens": true,
      "Marseille": true,
      "LOSC": true,
      "Toulouse": true,
      "Wolves": true,
      "Tottenham": true,
      "Man United": true,
      "Luton Town": true,
      "Arsenal": true,
      "Burnley": true,
      "Aston Villa": true,
      "Fulham": true,
      "Brighton": true,
      "Sheffield United": true,
      "Crystal Palace": true,
      "Everton": true,
      "Chelsea": true,
      "Man City": true,
      "West Ham": true,
      "Nottm Forest": true,
      "Liverpool": true,
      "Brentford": true,
      "Bournemouth": true,
      "Newcastle": true,
      "Central Córdoba": true,
      "Estudiantes": true,
      "Rosario Central": true,
      "River Plate": true,
      "Boca Juniors": true,
      "Newell's": true,
      "Huracán": true,
      "Arsenal Sarandi": true,
      "Lanús": true,
      "Racing Club": true,
      "Colón": true,
      "Talleres Córdoba": true,
      "Argentinos Juniors": true,
      "Vélez Sársfield": true,
      "Instituto ACC": true,
      "Barracas Central": true,
      "Belgrano": true,
      "Unión": true,
      "Sarmiento": true,
      "Godoy Cruz": true,
      "Tigre": true,
      "Platense": true,
      "Defensa y Justicia": true,
      "San Lorenzo": true,
      "Gimnasia": true,
      "Atlético Tucumán": true,
      "Independiente": true,
      "Banfield": true,
      "Napoli": true,
      "Empoli": true,
      "Inter": true,
      "Frosinone": true,
      "Fiorentina": true,
      "Bologna": true,
      "Genoa": true,
      "Verona": true,
      "Udinese": true,
      "Atalanta": true,
      "Sassuolo": true,
      "Salernitana": true,
      "Monza": true,
      "Torino": true,
      "Lazio": true,
      "Roma": true,
      "Juventus": true,
      "Cagliari": true,
      "Lecce": true,
      "Milan": true
    }
  },
  "424": {
    "gwNum": 424,
    "startDate": "Tue Nov 14 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Nov 17 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false
  },
  "425": {
    "gwNum": 425,
    "startDate": "Fri Nov 17 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Nov 21 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Villarreal B": true,
      "Andorra": true,
      "Amorebieta": true,
      "Tenerife": true,
      "Oviedo": true,
      "Eibar": true,
      "Levante": true,
      "Racing": true,
      "Espanyol": true,
      "Elche": true,
      "Alcorcón": true,
      "Sporting Gijón": true,
      "FC Cartagena": true,
      "Albacete": true,
      "Racing Ferrol": true,
      "Burgos": true,
      "Valladolid": true,
      "Leganes": true,
      "Zaragoza": true,
      "Huesca": true,
      "Eldense": true,
      "Mirandés": true,
      "Banfield": true,
      "Gimnasia": true,
      "Vélez Sársfield": true,
      "Colón": true,
      "River Plate": true,
      "Instituto ACC": true,
      "Newell's": true,
      "Defensa y Justicia": true,
      "Unión": true,
      "Tigre": true,
      "Atlético Tucumán": true,
      "Huracán": true,
      "Platense": true,
      "Sarmiento": true,
      "Barracas Central": true,
      "Argentinos Juniors": true,
      "Talleres Córdoba": true,
      "Independiente": true,
      "Godoy Cruz": true,
      "Boca Juniors": true,
      "Estudiantes": true,
      "Lanús": true,
      "Arsenal Sarandi": true,
      "Rosario Central": true,
      "San Lorenzo": true,
      "Central Córdoba": true,
      "Racing Club": true,
      "Belgrano": true
    }
  },
  "426": {
    "gwNum": 426,
    "startDate": "Tue Nov 21 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Nov 24 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Goiás": true,
      "Cruzeiro": true,
      "América-MG": true,
      "Flamengo": true,
      "São Paulo": true,
      "Cuiabá": true,
      "Athletico-PR": true,
      "Vasco da Gama": true,
      "Internacional": true,
      "Bragantino": true,
      "Fluminense": true,
      "Coritiba": true,
      "Atlético Mineiro": true,
      "Grêmio": true,
      "Corinthians": true,
      "Bahia": true,
      "Fortaleza": true,
      "Palmeiras": true,
      "Botafogo": true,
      "Santos": true
    }
  },
  "427": {
    "gwNum": 427,
    "startDate": "Fri Nov 24 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Nov 28 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Standard": true,
      "Genk": true,
      "Cercle Brugge": true,
      "Eupen": true,
      "Anderlecht": true,
      "RWDM": true,
      "Kortrijk": true,
      "Mechelen": true,
      "STVV": true,
      "Antwerp": true,
      "Oud-Heverlee Leuven": true,
      "Club Brugge": true,
      "Gent": true,
      "Union Saint-Gilloise": true,
      "Charleroi": true,
      "Westerlo": true,
      "Eintracht Frankfurt": true,
      "VfB Stuttgart": true,
      "Heidenheim": true,
      "VfL Bochum": true,
      "Köln": true,
      "Bayern": true,
      "Werder": true,
      "Leverkusen": true,
      "SC Freiburg": true,
      "Darmstadt": true,
      "Wolfsburg": true,
      "RB Leipzig": true,
      "Union Berlin": true,
      "Augsburg": true,
      "Dortmund": true,
      "Mönchengladbach": true,
      "Hoffenheim": true,
      "Mainz": true,
      "Grêmio": true,
      "Goiás": true,
      "Flamengo": true,
      "Atlético Mineiro": true,
      "Coritiba": true,
      "Botafogo": true,
      "Cruzeiro": true,
      "Athletico-PR": true,
      "Bahia": true,
      "São Paulo": true,
      "Bragantino": true,
      "Fortaleza": true,
      "Santos": true,
      "Fluminense": true,
      "Cuiabá": true,
      "Internacional": true,
      "Vasco da Gama": true,
      "Corinthians": true,
      "Palmeiras": true,
      "América-MG": true,
      "Rosenborg": true,
      "Strømsgodset": true,
      "Brann": true,
      "Bodø/Glimt": true,
      "HamKam": true,
      "Vålerenga": true,
      "Stabæk": true,
      "Sandefjord": true,
      "Aalesund": true,
      "Viking": true,
      "Lillestrøm": true,
      "Odd": true,
      "Sarpsborg": true,
      "Molde": true,
      "Tromso": true,
      "Haugesund": true,
      "Excelsior": true,
      "Feyenoord": true,
      "Heerenveen": true,
      "Fortuna Sittard": true,
      "Twente": true,
      "PSV": true,
      "PEC Zwolle": true,
      "RKC Waalwijk": true,
      "Ajax": true,
      "Vitesse": true,
      "Almere": true,
      "Heracles": true,
      "AZ Alkmaar": true,
      "FC Volendam": true,
      "NEC": true,
      "Go Ahead Eagles": true,
      "Sparta Rotterdam": true,
      "Utrecht": true,
      "Yokohama FC": true,
      "Shonan Bellmare": true,
      "Sanfrecce": true,
      "Gamba Osaka": true,
      "Yokohama FM": true,
      "Niigata": true,
      "Cerezo Osaka": true,
      "Kyoto Sanga": true,
      "Urawa Reds": true,
      "Avispa Fukuoka": true,
      "Vissel Kobe": true,
      "Nagoya": true,
      "Kashiwa Reysol": true,
      "Sagan Tosu": true,
      "FC Tokyo": true,
      "Consadole Sapporo": true,
      "Kawasaki": true,
      "Kashima Antlers": true,
      "Albacete": true,
      "Zaragoza": true,
      "Sporting Gijón": true,
      "Eldense": true,
      "Mirandés": true,
      "Oviedo": true,
      "Burgos": true,
      "Andorra": true,
      "Tenerife": true,
      "FC Cartagena": true,
      "Huesca": true,
      "Valladolid": true,
      "Elche": true,
      "Amorebieta": true,
      "Racing": true,
      "Villarreal B": true,
      "Leganes": true,
      "Racing Ferrol": true,
      "Eibar": true,
      "Levante": true,
      "Espanyol": true,
      "Alcorcón": true,
      "Getafe": true,
      "Almería": true,
      "Atlético Madrid": true,
      "Mallorca": true,
      "Girona": true,
      "Athletic Club": true,
      "Rayo Vallecano": true,
      "Barcelona": true,
      "Alavés": true,
      "Granada": true,
      "Real Sociedad": true,
      "Sevilla": true,
      "Real Betis": true,
      "Las Palmas": true,
      "Villarreal": true,
      "Osasuna": true,
      "Valencia": true,
      "Celta Vigo": true,
      "Cádiz": true,
      "Real Madrid": true,
      "Montpellier": true,
      "Brest": true,
      "Lorient": true,
      "Metz": true,
      "Nantes": true,
      "Le Havre": true,
      "PSG": true,
      "Monaco": true,
      "Nice": true,
      "Toulouse": true,
      "Clermont Foot": true,
      "Lens": true,
      "Rennes": true,
      "Reims": true,
      "Strasbourg": true,
      "Marseille": true,
      "Lyon": true,
      "LOSC": true,
      "Everton": true,
      "Man United": true,
      "Tottenham": true,
      "Aston Villa": true,
      "Fulham": true,
      "Wolves": true,
      "Man City": true,
      "Liverpool": true,
      "Brentford": true,
      "Arsenal": true,
      "Sheffield United": true,
      "Bournemouth": true,
      "Nottm Forest": true,
      "Brighton": true,
      "Burnley": true,
      "West Ham": true,
      "Luton Town": true,
      "Crystal Palace": true,
      "Newcastle": true,
      "Chelsea": true,
      "Salernitana": true,
      "Lazio": true,
      "Juventus": true,
      "Inter": true,
      "Milan": true,
      "Fiorentina": true,
      "Bologna": true,
      "Torino": true,
      "Frosinone": true,
      "Genoa": true,
      "Verona": true,
      "Lecce": true,
      "Atalanta": true,
      "Napoli": true,
      "Empoli": true,
      "Sassuolo": true,
      "Cagliari": true,
      "Monza": true,
      "Roma": true,
      "Udinese": true
    }
  },
  "428": {
    "gwNum": 428,
    "startDate": "Tue Nov 28 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Dec 01 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Atlético Mineiro": true,
      "São Paulo": true,
      "Botafogo": true,
      "Cruzeiro": true,
      "Corinthians": true,
      "Internacional": true,
      "América-MG": true,
      "Bahia": true,
      "Fortaleza": true,
      "Goiás": true,
      "Bragantino": true,
      "Coritiba": true,
      "Grêmio": true,
      "Vasco da Gama": true,
      "Flamengo": true,
      "Cuiabá": true,
      "Athletico-PR": true,
      "Santos": true,
      "Palmeiras": true,
      "Fluminense": true
    }
  },
  "429": {
    "gwNum": 429,
    "startDate": "Fri Dec 01 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Dec 05 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Antwerp": true,
      "Oud-Heverlee Leuven": true,
      "Club Brugge": true,
      "Standard": true,
      "Genk": true,
      "Gent": true,
      "Eupen": true,
      "Kortrijk": true,
      "RWDM": true,
      "Charleroi": true,
      "Union Saint-Gilloise": true,
      "Cercle Brugge": true,
      "Mechelen": true,
      "STVV": true,
      "Westerlo": true,
      "Anderlecht": true,
      "VfL Bochum": true,
      "Wolfsburg": true,
      "RB Leipzig": true,
      "Heidenheim": true,
      "VfB Stuttgart": true,
      "Werder": true,
      "Bayern": true,
      "Union Berlin": true,
      "Mönchengladbach": true,
      "Hoffenheim": true,
      "Mainz": true,
      "SC Freiburg": true,
      "Leverkusen": true,
      "Dortmund": true,
      "Darmstadt": true,
      "Köln": true,
      "Augsburg": true,
      "Eintracht Frankfurt": true,
      "Internacional": true,
      "Botafogo": true,
      "Coritiba": true,
      "Corinthians": true,
      "Fluminense": true,
      "Grêmio": true,
      "Cruzeiro": true,
      "Palmeiras": true,
      "Bahia": true,
      "Atlético Mineiro": true,
      "Cuiabá": true,
      "Athletico-PR": true,
      "Goiás": true,
      "América-MG": true,
      "Santos": true,
      "Fortaleza": true,
      "São Paulo": true,
      "Flamengo": true,
      "Vasco da Gama": true,
      "Bragantino": true,
      "Strømsgodset": true,
      "Brann": true,
      "Molde": true,
      "HamKam": true,
      "Odd": true,
      "Aalesund": true,
      "Haugesund": true,
      "Stabæk": true,
      "Bodø/Glimt": true,
      "Sarpsborg": true,
      "Viking": true,
      "Rosenborg": true,
      "Sandefjord": true,
      "Lillestrøm": true,
      "Vålerenga": true,
      "Tromso": true,
      "Heerenveen": true,
      "Almere": true,
      "FC Volendam": true,
      "PEC Zwolle": true,
      "Fortuna Sittard": true,
      "Vitesse": true,
      "RKC Waalwijk": true,
      "Excelsior": true,
      "Heracles": true,
      "Sparta Rotterdam": true,
      "Feyenoord": true,
      "PSV": true,
      "NEC": true,
      "Ajax": true,
      "Go Ahead Eagles": true,
      "Twente": true,
      "Utrecht": true,
      "AZ Alkmaar": true,
      "Consadole Sapporo": true,
      "Urawa Reds": true,
      "Nagoya": true,
      "Kashiwa Reysol": true,
      "Gamba Osaka": true,
      "Vissel Kobe": true,
      "Kashima Antlers": true,
      "Yokohama FC": true,
      "Kyoto Sanga": true,
      "Yokohama FM": true,
      "Sagan Tosu": true,
      "Kawasaki": true,
      "Niigata": true,
      "Cerezo Osaka": true,
      "Avispa Fukuoka": true,
      "Sanfrecce": true,
      "Shonan Bellmare": true,
      "FC Tokyo": true,
      "Eldense": true,
      "Tenerife": true,
      "FC Cartagena": true,
      "Sporting Gijón": true,
      "Racing Ferrol": true,
      "Albacete": true,
      "Mirandés": true,
      "Racing": true,
      "Villarreal B": true,
      "Eibar": true,
      "Oviedo": true,
      "Espanyol": true,
      "Amorebieta": true,
      "Burgos": true,
      "Andorra": true,
      "Huesca": true,
      "Levante": true,
      "Valladolid": true,
      "Zaragoza": true,
      "Leganes": true,
      "Alcorcón": true,
      "Elche": true,
      "Osasuna": true,
      "Real Sociedad": true,
      "Real Madrid": true,
      "Granada": true,
      "Celta Vigo": true,
      "Cádiz": true,
      "Las Palmas": true,
      "Getafe": true,
      "Athletic Club": true,
      "Rayo Vallecano": true,
      "Barcelona": true,
      "Atlético Madrid": true,
      "Mallorca": true,
      "Alavés": true,
      "Sevilla": true,
      "Villarreal": true,
      "Girona": true,
      "Valencia": true,
      "Almería": true,
      "Real Betis": true,
      "Boavista": true,
      "Arouca": true,
      "Braga": true,
      "Estoril Praia": true,
      "Farense": true,
      "Vitória SC": true,
      "Famalicão": true,
      "Porto": true,
      "Rio Ave": true,
      "Estrela Amadora": true,
      "Chaves": true,
      "Vizela": true,
      "Sporting": true,
      "Gil Vicente": true,
      "Casa Pia": true,
      "Portimonense": true,
      "Moreirense": true,
      "Benfica": true,
      "Toulouse": true,
      "Lorient": true,
      "Lens": true,
      "Lyon": true,
      "Brest": true,
      "Clermont Foot": true,
      "LOSC": true,
      "Metz": true,
      "Nantes": true,
      "Nice": true,
      "Reims": true,
      "Strasbourg": true,
      "Monaco": true,
      "Montpellier": true,
      "Le Havre": true,
      "PSG": true,
      "Marseille": true,
      "Rennes": true,
      "Brentford": true,
      "Luton Town": true,
      "Arsenal": true,
      "Wolves": true,
      "Man City": true,
      "Tottenham": true,
      "Chelsea": true,
      "Brighton": true,
      "Newcastle": true,
      "Man United": true,
      "Burnley": true,
      "Sheffield United": true,
      "Nottm Forest": true,
      "Everton": true,
      "Liverpool": true,
      "Fulham": true,
      "West Ham": true,
      "Crystal Palace": true,
      "Bournemouth": true,
      "Aston Villa": true,
      "Milan": true,
      "Frosinone": true,
      "Monza": true,
      "Juventus": true,
      "Fiorentina": true,
      "Salernitana": true,
      "Sassuolo": true,
      "Roma": true,
      "Genoa": true,
      "Empoli": true,
      "Torino": true,
      "Atalanta": true,
      "Lecce": true,
      "Bologna": true,
      "Napoli": true,
      "Inter": true,
      "Lazio": true,
      "Cagliari": true,
      "Udinese": true,
      "Verona": true
    }
  },
  "430": {
    "gwNum": 430,
    "startDate": "Tue Dec 05 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Dec 08 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Feyenoord": true,
      "FC Volendam": true,
      "Tottenham": true,
      "West Ham": true,
      "Sheffield United": true,
      "Liverpool": true,
      "Wolves": true,
      "Burnley": true,
      "Everton": true,
      "Newcastle": true,
      "Fulham": true,
      "Nottm Forest": true,
      "Luton Town": true,
      "Arsenal": true,
      "Brighton": true,
      "Brentford": true,
      "Aston Villa": true,
      "Man City": true,
      "Crystal Palace": true,
      "Bournemouth": true,
      "Man United": true,
      "Chelsea": true
    }
  },
  "431": {
    "gwNum": 431,
    "startDate": "Fri Dec 08 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Dec 12 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Charleroi": true,
      "Union Saint-Gilloise": true,
      "Anderlecht": true,
      "Standard": true,
      "Gent": true,
      "RWDM": true,
      "Kortrijk": true,
      "Westerlo": true,
      "STVV": true,
      "Oud-Heverlee Leuven": true,
      "Eupen": true,
      "Genk": true,
      "Cercle Brugge": true,
      "Antwerp": true,
      "Mechelen": true,
      "Club Brugge": true,
      "Köln": true,
      "Mainz": true,
      "Hoffenheim": true,
      "VfL Bochum": true,
      "Heidenheim": true,
      "Darmstadt": true,
      "Wolfsburg": true,
      "SC Freiburg": true,
      "Dortmund": true,
      "RB Leipzig": true,
      "VfB Stuttgart": true,
      "Leverkusen": true,
      "Eintracht Frankfurt": true,
      "Bayern": true,
      "Werder": true,
      "Augsburg": true,
      "Union Berlin": true,
      "Mönchengladbach": true,
      "Twente": true,
      "Excelsior": true,
      "PSV": true,
      "Heerenveen": true,
      "PEC Zwolle": true,
      "NEC": true,
      "AZ Alkmaar": true,
      "Almere": true,
      "Go Ahead Eagles": true,
      "Utrecht": true,
      "Ajax": true,
      "Sparta Rotterdam": true,
      "Fortuna Sittard": true,
      "RKC Waalwijk": true,
      "Vitesse": true,
      "Heracles": true,
      "Albacete": true,
      "Villarreal B": true,
      "Racing": true,
      "Oviedo": true,
      "Valladolid": true,
      "Amorebieta": true,
      "Eibar": true,
      "Andorra": true,
      "Sporting Gijón": true,
      "Levante": true,
      "Tenerife": true,
      "Alcorcón": true,
      "Elche": true,
      "FC Cartagena": true,
      "Huesca": true,
      "Racing Ferrol": true,
      "Leganes": true,
      "Eldense": true,
      "Espanyol": true,
      "Zaragoza": true,
      "Burgos": true,
      "Mirandés": true,
      "Atlético Madrid": true,
      "Almería": true,
      "Granada": true,
      "Athletic Club": true,
      "Mallorca": true,
      "Sevilla": true,
      "Cádiz": true,
      "Osasuna": true,
      "Barcelona": true,
      "Girona": true,
      "Real Betis": true,
      "Real Madrid": true,
      "Getafe": true,
      "Valencia": true,
      "Rayo Vallecano": true,
      "Celta Vigo": true,
      "Alavés": true,
      "Las Palmas": true,
      "Villarreal": true,
      "Real Sociedad": true,
      "Portimonense": true,
      "Famalicão": true,
      "Vizela": true,
      "Braga": true,
      "Estoril Praia": true,
      "Chaves": true,
      "Arouca": true,
      "Rio Ave": true,
      "Gil Vicente": true,
      "Moreirense": true,
      "Porto": true,
      "Casa Pia": true,
      "Estrela Amadora": true,
      "Boavista": true,
      "Vitória SC": true,
      "Sporting": true,
      "Benfica": true,
      "Farense": true,
      "Nice": true,
      "Reims": true,
      "Lyon": true,
      "Toulouse": true,
      "Clermont Foot": true,
      "LOSC": true,
      "Strasbourg": true,
      "Le Havre": true,
      "Montpellier": true,
      "Lens": true,
      "Lorient": true,
      "Marseille": true,
      "Metz": true,
      "Brest": true,
      "PSG": true,
      "Nantes": true,
      "Rennes": true,
      "Monaco": true,
      "Wolves": true,
      "Nottm Forest": true,
      "Luton Town": true,
      "Man City": true,
      "Tottenham": true,
      "Newcastle": true,
      "Fulham": true,
      "West Ham": true,
      "Aston Villa": true,
      "Arsenal": true,
      "Brighton": true,
      "Burnley": true,
      "Everton": true,
      "Chelsea": true,
      "Crystal Palace": true,
      "Liverpool": true,
      "Sheffield United": true,
      "Brentford": true,
      "Man United": true,
      "Bournemouth": true,
      "Cagliari": true,
      "Sassuolo": true,
      "Frosinone": true,
      "Torino": true,
      "Roma": true,
      "Fiorentina": true,
      "Juventus": true,
      "Napoli": true,
      "Atalanta": true,
      "Milan": true,
      "Inter": true,
      "Udinese": true,
      "Verona": true,
      "Lazio": true,
      "Salernitana": true,
      "Bologna": true,
      "Monza": true,
      "Genoa": true,
      "Empoli": true,
      "Lecce": true
    }
  },
  "432": {
    "gwNum": 432,
    "startDate": "Tue Dec 12 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Dec 15 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false
  },
  "433": {
    "gwNum": 433,
    "startDate": "Fri Dec 15 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Dec 19 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Westerlo": true,
      "Eupen": true,
      "Union Saint-Gilloise": true,
      "Mechelen": true,
      "Club Brugge": true,
      "Gent": true,
      "Antwerp": true,
      "Anderlecht": true,
      "Oud-Heverlee Leuven": true,
      "Cercle Brugge": true,
      "RWDM": true,
      "STVV": true,
      "Genk": true,
      "Kortrijk": true,
      "Standard": true,
      "Charleroi": true,
      "Mönchengladbach": true,
      "Werder": true,
      "RB Leipzig": true,
      "Hoffenheim": true,
      "Darmstadt": true,
      "Wolfsburg": true,
      "VfL Bochum": true,
      "Union Berlin": true,
      "Augsburg": true,
      "Dortmund": true,
      "Leverkusen": true,
      "Eintracht Frankfurt": true,
      "SC Freiburg": true,
      "Köln": true,
      "Bayern": true,
      "VfB Stuttgart": true,
      "Mainz": true,
      "Heidenheim": true,
      "NEC": true,
      "Fortuna Sittard": true,
      "Utrecht": true,
      "RKC Waalwijk": true,
      "Excelsior": true,
      "Go Ahead Eagles": true,
      "Heerenveen": true,
      "FC Volendam": true,
      "Almere": true,
      "Vitesse": true,
      "Heracles": true,
      "Feyenoord": true,
      "Sparta Rotterdam": true,
      "Twente": true,
      "Ajax": true,
      "PEC Zwolle": true,
      "AZ Alkmaar": true,
      "PSV": true,
      "Andorra": true,
      "Espanyol": true,
      "Villarreal B": true,
      "Valladolid": true,
      "FC Cartagena": true,
      "Burgos": true,
      "Sporting Gijón": true,
      "Leganes": true,
      "Mirandés": true,
      "Albacete": true,
      "Alcorcón": true,
      "Eibar": true,
      "Racing Ferrol": true,
      "Tenerife": true,
      "Amorebieta": true,
      "Zaragoza": true,
      "Eldense": true,
      "Racing": true,
      "Oviedo": true,
      "Elche": true,
      "Levante": true,
      "Huesca": true,
      "Girona": true,
      "Alavés": true,
      "Sevilla": true,
      "Getafe": true,
      "Las Palmas": true,
      "Cádiz": true,
      "Valencia": true,
      "Barcelona": true,
      "Almería": true,
      "Mallorca": true,
      "Real Sociedad": true,
      "Real Betis": true,
      "Celta Vigo": true,
      "Granada": true,
      "Real Madrid": true,
      "Villarreal": true,
      "Athletic Club": true,
      "Atlético Madrid": true,
      "Osasuna": true,
      "Rayo Vallecano": true,
      "Arouca": true,
      "Gil Vicente": true,
      "Famalicão": true,
      "Estoril Praia": true,
      "Sporting": true,
      "Porto": true,
      "Chaves": true,
      "Casa Pia": true,
      "Moreirense": true,
      "Portimonense": true,
      "Rio Ave": true,
      "Vizela": true,
      "Braga": true,
      "Benfica": true,
      "Farense": true,
      "Estrela Amadora": true,
      "Boavista": true,
      "Vitória SC": true,
      "LOSC": true,
      "PSG": true,
      "Nantes": true,
      "Brest": true,
      "Monaco": true,
      "Lyon": true,
      "Lens": true,
      "Reims": true,
      "Marseille": true,
      "Clermont Foot": true,
      "Toulouse": true,
      "Rennes": true,
      "Le Havre": true,
      "Nice": true,
      "Metz": true,
      "Montpellier": true,
      "Lorient": true,
      "Strasbourg": true,
      "Burnley": true,
      "Everton": true,
      "Nottm Forest": true,
      "Tottenham": true,
      "Bournemouth": true,
      "Luton Town": true,
      "Chelsea": true,
      "Sheffield United": true,
      "Man City": true,
      "Crystal Palace": true,
      "West Ham": true,
      "Wolves": true,
      "Brentford": true,
      "Aston Villa": true,
      "Liverpool": true,
      "Man United": true,
      "Newcastle": true,
      "Fulham": true,
      "Arsenal": true,
      "Brighton": true,
      "Atalanta": true,
      "Salernitana": true,
      "Napoli": true,
      "Cagliari": true,
      "Bologna": true,
      "Roma": true,
      "Lazio": true,
      "Inter": true,
      "Udinese": true,
      "Sassuolo": true,
      "Milan": true,
      "Monza": true,
      "Lecce": true,
      "Frosinone": true,
      "Torino": true,
      "Empoli": true,
      "Fiorentina": true,
      "Verona": true,
      "Genoa": true,
      "Juventus": true
    }
  },
  "434": {
    "gwNum": 434,
    "startDate": "Tue Dec 19 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Dec 22 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Eintracht Frankfurt": true,
      "Mönchengladbach": true,
      "Werder": true,
      "RB Leipzig": true,
      "Heidenheim": true,
      "SC Freiburg": true,
      "Dortmund": true,
      "Mainz": true,
      "VfB Stuttgart": true,
      "Augsburg": true,
      "Leverkusen": true,
      "VfL Bochum": true,
      "Union Berlin": true,
      "Köln": true,
      "Hoffenheim": true,
      "Darmstadt": true,
      "Wolfsburg": true,
      "Bayern": true,
      "Zaragoza": true,
      "Levante": true,
      "Espanyol": true,
      "Burgos": true,
      "Villarreal B": true,
      "Oviedo": true,
      "Elche": true,
      "Mirandés": true,
      "Huesca": true,
      "FC Cartagena": true,
      "Racing": true,
      "Andorra": true,
      "Eibar": true,
      "Sporting Gijón": true,
      "Valladolid": true,
      "Racing Ferrol": true,
      "Leganes": true,
      "Tenerife": true,
      "Amorebieta": true,
      "Alcorcón": true,
      "Albacete": true,
      "Eldense": true,
      "Athletic Club": true,
      "Las Palmas": true,
      "Barcelona": true,
      "Almería": true,
      "Alavés": true,
      "Real Madrid": true,
      "Granada": true,
      "Sevilla": true,
      "Mallorca": true,
      "Osasuna": true,
      "Atlético Madrid": true,
      "Getafe": true,
      "Real Betis": true,
      "Girona": true,
      "Rayo Vallecano": true,
      "Valencia": true,
      "Villarreal": true,
      "Celta Vigo": true,
      "Cádiz": true,
      "Real Sociedad": true,
      "Clermont Foot": true,
      "Rennes": true,
      "Montpellier": true,
      "Marseille": true,
      "Brest": true,
      "Lorient": true,
      "PSG": true,
      "Metz": true,
      "Toulouse": true,
      "Monaco": true,
      "Lyon": true,
      "Nantes": true,
      "Reims": true,
      "Le Havre": true,
      "Strasbourg": true,
      "LOSC": true,
      "Nice": true,
      "Lens": true
    }
  },
  "435": {
    "gwNum": 435,
    "startDate": "Fri Dec 22 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Dec 26 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "RWDM": true,
      "Club Brugge": true,
      "Cercle Brugge": true,
      "Kortrijk": true,
      "Mechelen": true,
      "Standard": true,
      "Anderlecht": true,
      "Genk": true,
      "STVV": true,
      "Charleroi": true,
      "Gent": true,
      "Oud-Heverlee Leuven": true,
      "Eupen": true,
      "Union Saint-Gilloise": true,
      "Antwerp": true,
      "Westerlo": true,
      "Wolves": true,
      "Chelsea": true,
      "Liverpool": true,
      "Arsenal": true,
      "Tottenham": true,
      "Everton": true,
      "Nottm Forest": true,
      "Bournemouth": true,
      "Crystal Palace": true,
      "Brighton": true,
      "Aston Villa": true,
      "Sheffield United": true,
      "Fulham": true,
      "Burnley": true,
      "Luton Town": true,
      "Newcastle": true,
      "West Ham": true,
      "Man United": true,
      "Brentford": true,
      "Man City": true,
      "Sassuolo": true,
      "Genoa": true,
      "Monza": true,
      "Fiorentina": true,
      "Frosinone": true,
      "Juventus": true,
      "Roma": true,
      "Napoli": true,
      "Salernitana": true,
      "Milan": true,
      "Bologna": true,
      "Atalanta": true,
      "Verona": true,
      "Cagliari": true,
      "Torino": true,
      "Udinese": true,
      "Inter": true,
      "Lecce": true,
      "Empoli": true,
      "Lazio": true
    }
  },
  "436": {
    "gwNum": 436,
    "startDate": "Tue Dec 26 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Fri Dec 29 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": false,
    "activeTeams": {
      "Kortrijk": true,
      "Gent": true,
      "Oud-Heverlee Leuven": true,
      "Eupen": true,
      "Westerlo": true,
      "RWDM": true,
      "Club Brugge": true,
      "Union Saint-Gilloise": true,
      "Charleroi": true,
      "Mechelen": true,
      "Genk": true,
      "Antwerp": true,
      "Anderlecht": true,
      "Cercle Brugge": true,
      "Standard": true,
      "STVV": true
    }
  },
  "437": {
    "gwNum": 437,
    "startDate": "Fri Dec 29 2023 16:00:00 GMT+0100 (Central European Standard Time)",
    "endDate": "Tue Jan 02 2024 16:00:00 GMT+0100 (Central European Standard Time)",
    "isWeekendGW": true,
    "activeTeams": {
      "Vizela": true,
      "Moreirense": true,
      "Porto": true,
      "Chaves": true,
      "Portimonense": true,
      "Sporting": true,
      "Casa Pia": true,
      "Braga": true,
      "Gil Vicente": true,
      "Boavista": true,
      "Vitória SC": true,
      "Rio Ave": true,
      "Estrela Amadora": true,
      "Arouca": true,
      "Estoril Praia": true,
      "Farense": true,
      "Benfica": true,
      "Famalicão": true,
      "West Ham": true,
      "Brighton": true,
      "Fulham": true,
      "Arsenal": true,
      "Liverpool": true,
      "Newcastle": true,
      "Crystal Palace": true,
      "Brentford": true,
      "Man City": true,
      "Sheffield United": true,
      "Luton Town": true,
      "Chelsea": true,
      "Nottm Forest": true,
      "Man United": true,
      "Aston Villa": true,
      "Burnley": true,
      "Wolves": true,
      "Everton": true,
      "Tottenham": true,
      "Bournemouth": true,
      "Napoli": true,
      "Monza": true,
      "Verona": true,
      "Salernitana": true,
      "Udinese": true,
      "Bologna": true,
      "Milan": true,
      "Sassuolo": true,
      "Cagliari": true,
      "Empoli": true,
      "Atalanta": true,
      "Lecce": true,
      "Fiorentina": true,
      "Torino": true,
      "Genoa": true,
      "Inter": true,
      "Lazio": true,
      "Frosinone": true,
      "Juventus": true,
      "Roma": true
    }
  }
}


