export const teamsList = [
  "AZ Alkmaar",
  "Aalesund",
  "Ajaccio",
  "Ajax",
  "Alavés",
  "Albacete",
  "Alcorcón",
  "Almere",
  "Almería",
  "Amorebieta",
  "América-MG",
  "Anderlecht",
  "Andorra",
  "Angers",
  "Antwerp",
  "Argentinos Juniors",
  "Arouca",
  "Arsenal",
  "Arsenal Sarandi",
  "Aston Villa",
  "Atalanta",
  "Athletic Club",
  "Athletico-PR",
  "Atlanta United",
  "Atlético Madrid",
  "Atlético Mineiro",
  "Atlético Tucumán",
  "Augsburg",
  "Austin",
  "Auxerre",
  "Avispa Fukuoka",
  "Bahia",
  "Banfield",
  "Barcelona",
  "Barracas Central",
  "Bayern",
  "Belgrano",
  "Benfica",
  "Boavista",
  "Boca Juniors",
  "Bodø/Glimt",
  "Bologna",
  "Botafogo",
  "Bournemouth",
  "Braga",
  "Bragantino",
  "Brann",
  "Brentford",
  "Brest",
  "Brighton",
  "Burgos",
  "Burnley",
  "Cagliari",
  "Cambuur",
  "Casa Pia",
  "Celta Vigo",
  "Central Córdoba",
  "Cercle Brugge",
  "Cerezo Osaka",
  "Charleroi",
  "Charlotte",
  "Chaves",
  "Chelsea",
  "Chicago",
  "Cincinnati",
  "Clermont Foot",
  "Club Brugge",
  "Colorado",
  "Columbus",
  "Colón",
  "Consadole Sapporo",
  "Corinthians",
  "Coritiba",
  "Cremonese",
  "Cruzeiro",
  "Crystal Palace",
  "Cuiabá",
  "Cádiz",
  "D.C. United",
  "Daegu",
  "Daejeon Hana",
  "Darmstadt",
  "Defensa y Justicia",
  "Dortmund",
  "Eibar",
  "Eintracht Frankfurt",
  "Elche",
  "Eldense",
  "Empoli",
  "Espanyol",
  "Estoril Praia",
  "Estrela Amadora",
  "Estudiantes",
  "Eupen",
  "Everton",
  "Excelsior",
  "FC Cartagena",
  "FC Dallas",
  "FC Emmen",
  "FC Seoul",
  "FC Tokyo",
  "FC Volendam",
  "Famalicão",
  "Farense",
  "Feyenoord",
  "Fiorentina",
  "Flamengo",
  "Fluminense",
  "Fortaleza",
  "Fortuna Sittard",
  "Frosinone",
  "Fulham",
  "Gamba Osaka",
  "Gangwon",
  "Genk",
  "Genoa",
  "Gent",
  "Getafe",
  "Gil Vicente",
  "Gimnasia",
  "Girona",
  "Go Ahead Eagles",
  "Godoy Cruz",
  "Goiás",
  "Granada",
  "Groningen",
  "Grêmio",
  "Gwangju",
  "HamKam",
  "Haugesund",
  "Heerenveen",
  "Heidenheim",
  "Heracles",
  "Hoffenheim",
  "Houston Dynamo",
  "Huesca",
  "Huracán",
  "Incheon",
  "Independiente",
  "Instituto ACC",
  "Inter",
  "Inter Miami",
  "Internacional",
  "Jeju",
  "Jeonbuk",
  "Juventus",
  "Kashima Antlers",
  "Kashiwa Reysol",
  "Kawasaki",
  "Kortrijk",
  "Kyoto Sanga",
  "Köln",
  "LA Galaxy",
  "LAFC",
  "LOSC",
  "Lanús",
  "Las Palmas",
  "Lazio",
  "Le Havre",
  "Lecce",
  "Leeds United",
  "Leganes",
  "Leicester City",
  "Lens",
  "Levante",
  "Leverkusen",
  "Lillestrøm",
  "Liverpool",
  "Lorient",
  "Luton Town",
  "Lyon",
  "Mainz",
  "Mallorca",
  "Man City",
  "Man United",
  "Marseille",
  "Marítimo",
  "Mechelen",
  "Metz",
  "Milan",
  "Minnesota",
  "Mirandés",
  "Molde",
  "Monaco",
  "Montpellier",
  "Montréal",
  "Monza",
  "Moreirense",
  "Mönchengladbach",
  "NEC",
  "NY Red Bulls",
  "Nagoya",
  "Nantes",
  "Napoli",
  "Nashville",
  "New England",
  "New York City",
  "Newcastle",
  "Newell's",
  "Nice",
  "Niigata",
  "Nottm Forest",
  "Odd",
  "Orlando City",
  "Osasuna",
  "Oud-Heverlee Leuven",
  "Oviedo",
  "PEC Zwolle",
  "PSG",
  "PSV",
  "Pacos Ferreira",
  "Palmeiras",
  "Philadelphia",
  "Platense",
  "Pohang Steelers",
  "Portimonense",
  "Portland",
  "Porto",
  "RB Leipzig",
  "RKC Waalwijk",
  "RWDM",
  "Racing",
  "Racing Club",
  "Racing Ferrol",
  "Rayo Vallecano",
  "Real Betis",
  "Real Madrid",
  "Real Salt Lake",
  "Real Sociedad",
  "Reims",
  "Rennes",
  "Rio Ave",
  "River Plate",
  "Roma",
  "Rosario Central",
  "Rosenborg",
  "SC Freiburg",
  "STVV",
  "Sagan Tosu",
  "Salernitana",
  "Sampdoria",
  "San Jose",
  "San Lorenzo",
  "Sandefjord",
  "Sanfrecce",
  "Santa Clara",
  "Santos",
  "Sarmiento",
  "Sarpsborg",
  "Sassuolo",
  "Seattle Sounders",
  "Sevilla",
  "Sheffield United",
  "Shonan Bellmare",
  "Southampton",
  "Sparta Rotterdam",
  "Spezia",
  "Sporting",
  "Sporting Gijón",
  "Sporting KC",
  "St. Louis",
  "Stabæk",
  "Standard",
  "Strasbourg",
  "Strømsgodset",
  "Suwon Bluewings",
  "Suwon FC",
  "São Paulo",
  "Talleres Córdoba",
  "Tenerife",
  "Tigre",
  "Torino",
  "Toronto",
  "Tottenham",
  "Toulouse",
  "Tromso",
  "Troyes",
  "Twente",
  "Udinese",
  "Ulsan",
  "Union Berlin",
  "Union Saint-Gilloise",
  "Unión",
  "Urawa Reds",
  "Utrecht",
  "Valencia",
  "Valladolid",
  "Vancouver",
  "Vasco da Gama",
  "Verona",
  "VfB Stuttgart",
  "VfL Bochum",
  "Viking",
  "Villarreal",
  "Villarreal B",
  "Vissel Kobe",
  "Vitesse",
  "Vitória SC",
  "Vizela",
  "Vålerenga",
  "Vélez Sársfield",
  "Werder",
  "West Ham",
  "Westerlo",
  "Wolfsburg",
  "Wolves",
  "Yokohama FC",
  "Yokohama FM",
  "Zaragoza"
]

