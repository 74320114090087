import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleIcon from "@mui/icons-material/People";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TopBar from "./Components/TopBar/TopBar";
import Calendar from "./Components/Calendar/Calendar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#F5F3C1",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <TopBar />
      <Container sx={{ py: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h3" component="h2" sx={{ mb: 4 }}>
            Visualize your Sorare teams calendar
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <AccessTimeIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              Quickly see which player cards have games in the upcoming weeks.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <PeopleIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              Optimize your lineups by seeing which players have the most games.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MonetizationOnIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              Make more money by maximizing the number of lineups you can
              register.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography variant="h6" sx={{fontWeight: 'bold'}} >
            Select your teams to find out their matching calendar:
          </Typography>
          <Calendar />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
