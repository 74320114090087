import * as React from "react";
import { Box, Container, Typography } from "@mui/material";

const smIcon = (
  <img
    alt="smlogo"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTUlEQVR4nO1WX0/TUBTvB4FnXtnD3vbgKynSYgORhE9h0CkSo/gB5AE+gEZEFm07ZaIRFrNRu/ABVNabPahPtiWML3DM2ZCwtkjPuN3dkvtLTrJ0yfn9uefcVlEkJCTIsKuOalUPftv7B7/MvfqU6D5kIKFddaBT+85P0X3IOCc9K9F9EqFZ2rhq6m9UUz+dtnS4WFHi6P9py76iD3Krpm7N2DMTClX8tKWFSaSqqcWI8RlVPKmPqR+jptQGMPnLiAsbN2LE+IxqgN5HK1EMxMbmX02u5mPEuSd5sgFyH1NvpzbwP+Lcah5e7O6ckz7/UIHcU7qBfvooPAzgMRvPFjvkSGqsLUJhvb8RMoh9uBjARUNyTBALf/e7xAViHy4GRJYiDVgjcgIsbMAwliINhPIEQI5QGgxiVI78r/DYWYHPrbejt8QofrlW7FyNt8rz8LFVGh0DKP5h/V7P/X67sgDf/tSH30AzcOGR86BHvFY2oNx8Kf4ErkowKfnZsgHvvS3xO1BhWzC/swC7re1MkmdZGkDxmGR3IediJrril68tnmVl4H7tbo84vFU+nd0q3bEpXmtsWNYGjnwHirWliIk5qLDX3JJnWe/Ad9+BpS93esTetGYTkn/Vt3iW9S2UdBK8kmeDMHCZCV7i2aBeZBdN8BTPBvkm/uE7nW+dd94mN/FsGD4lpIG0EJ004zBCp6LFskh5gXtCMOBaogWzaAWN7dQGWv7hhBc0jodHvBs0g8aYQoEXHo6z0C2x0G2LE++2MXmyeAkJCWVk8BdCIRUrBKwL1AAAAABJRU5ErkJggg=="
  ></img>
);

export default function TopBar() {
  return (
    <Box sx={{ backgroundColor: "primary.main", color: "white" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 0",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {smIcon}
            <Typography variant="h5" component="h1" paddingLeft={3}>
              Sorare Matching
            </Typography>
          </Box>
          <Typography variant="body1">About</Typography>
        </Box>
      </Container>
    </Box>
  );
}
